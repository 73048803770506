export default [{
	"id": "110000",
	"name": "北京市",
	"children": [{
		"id": "110100",
		"name": "北京市",
		"parent": "110000",
		"children": [{
			"id": "110101",
			"name": "东城区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110102",
			"name": "西城区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110103",
			"name": "崇文区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110104",
			"name": "宣武区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110105",
			"name": "朝阳区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110106",
			"name": "丰台区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110107",
			"name": "石景山区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110108",
			"name": "海淀区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110109",
			"name": "门头沟区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110111",
			"name": "房山区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110112",
			"name": "通州区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110113",
			"name": "顺义区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110114",
			"name": "昌平区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110115",
			"name": "大兴区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110116",
			"name": "怀柔区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110117",
			"name": "平谷区",
			"parent": "110100",
			"children": null
		}, {
			"id": "110228",
			"name": "密云县",
			"parent": "110100",
			"children": null
		}, {
			"id": "110229",
			"name": "延庆县",
			"parent": "110100",
			"children": null
		}, {
			"id": "110230",
			"name": "其它区",
			"parent": "110100",
			"children": null
		}]
	}]
}, {
	"id": "120000",
	"name": "天津市",
	"children": [{
		"id": "120100",
		"name": "天津市",
		"parent": "120000",
		"children": [{
			"id": "120101",
			"name": "和平区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120102",
			"name": "河东区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120103",
			"name": "河西区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120104",
			"name": "南开区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120105",
			"name": "河北区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120106",
			"name": "红桥区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120107",
			"name": "塘沽区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120108",
			"name": "汉沽区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120109",
			"name": "大港区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120110",
			"name": "东丽区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120111",
			"name": "西青区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120112",
			"name": "津南区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120113",
			"name": "北辰区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120114",
			"name": "武清区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120115",
			"name": "宝坻区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120116",
			"name": "滨海新区",
			"parent": "120100",
			"children": null
		}, {
			"id": "120221",
			"name": "宁河县",
			"parent": "120100",
			"children": null
		}, {
			"id": "120223",
			"name": "静海县",
			"parent": "120100",
			"children": null
		}, {
			"id": "120225",
			"name": "蓟县",
			"parent": "120100",
			"children": null
		}, {
			"id": "120226",
			"name": "其它区",
			"parent": "120100",
			"children": null
		}]
	}]
}, {
	"id": "130000",
	"name": "河北省",
	"children": [{
		"id": "130100",
		"name": "石家庄市",
		"parent": "130000",
		"children": [{
			"id": "130102",
			"name": "长安区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130103",
			"name": "桥东区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130104",
			"name": "桥西区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130105",
			"name": "新华区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130107",
			"name": "井陉矿区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130108",
			"name": "裕华区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130121",
			"name": "井陉县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130123",
			"name": "正定县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130124",
			"name": "栾城区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130125",
			"name": "行唐县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130126",
			"name": "灵寿县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130127",
			"name": "高邑县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130128",
			"name": "深泽县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130129",
			"name": "赞皇县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130130",
			"name": "无极县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130131",
			"name": "平山县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130132",
			"name": "元氏县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130133",
			"name": "赵县",
			"parent": "130100",
			"children": null
		}, {
			"id": "130181",
			"name": "辛集市",
			"parent": "130100",
			"children": null
		}, {
			"id": "130182",
			"name": "藁城区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130183",
			"name": "晋州市",
			"parent": "130100",
			"children": null
		}, {
			"id": "130184",
			"name": "新乐市",
			"parent": "130100",
			"children": null
		}, {
			"id": "130185",
			"name": "鹿泉区",
			"parent": "130100",
			"children": null
		}, {
			"id": "130186",
			"name": "其它区",
			"parent": "130100",
			"children": null
		}]
	}, {
		"id": "130200",
		"name": "唐山市",
		"parent": "130000",
		"children": [{
			"id": "130202",
			"name": "路南区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130203",
			"name": "路北区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130204",
			"name": "古冶区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130205",
			"name": "开平区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130207",
			"name": "丰南区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130208",
			"name": "丰润区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130223",
			"name": "滦县",
			"parent": "130200",
			"children": null
		}, {
			"id": "130224",
			"name": "滦南县",
			"parent": "130200",
			"children": null
		}, {
			"id": "130225",
			"name": "乐亭县",
			"parent": "130200",
			"children": null
		}, {
			"id": "130227",
			"name": "迁西县",
			"parent": "130200",
			"children": null
		}, {
			"id": "130229",
			"name": "玉田县",
			"parent": "130200",
			"children": null
		}, {
			"id": "130230",
			"name": "曹妃甸区",
			"parent": "130200",
			"children": null
		}, {
			"id": "130281",
			"name": "遵化市",
			"parent": "130200",
			"children": null
		}, {
			"id": "130283",
			"name": "迁安市",
			"parent": "130200",
			"children": null
		}, {
			"id": "130284",
			"name": "其它区",
			"parent": "130200",
			"children": null
		}]
	}, {
		"id": "130300",
		"name": "秦皇岛市",
		"parent": "130000",
		"children": [{
			"id": "130302",
			"name": "海港区",
			"parent": "130300",
			"children": null
		}, {
			"id": "130303",
			"name": "山海关区",
			"parent": "130300",
			"children": null
		}, {
			"id": "130304",
			"name": "北戴河区",
			"parent": "130300",
			"children": null
		}, {
			"id": "130321",
			"name": "青龙满族自治县",
			"parent": "130300",
			"children": null
		}, {
			"id": "130322",
			"name": "昌黎县",
			"parent": "130300",
			"children": null
		}, {
			"id": "130323",
			"name": "抚宁县",
			"parent": "130300",
			"children": null
		}, {
			"id": "130324",
			"name": "卢龙县",
			"parent": "130300",
			"children": null
		}, {
			"id": "130398",
			"name": "其它区",
			"parent": "130300",
			"children": null
		}, {
			"id": "130399",
			"name": "经济技术开发区",
			"parent": "130300",
			"children": null
		}]
	}, {
		"id": "130400",
		"name": "邯郸市",
		"parent": "130000",
		"children": [{
			"id": "130402",
			"name": "邯山区",
			"parent": "130400",
			"children": null
		}, {
			"id": "130403",
			"name": "丛台区",
			"parent": "130400",
			"children": null
		}, {
			"id": "130404",
			"name": "复兴区",
			"parent": "130400",
			"children": null
		}, {
			"id": "130406",
			"name": "峰峰矿区",
			"parent": "130400",
			"children": null
		}, {
			"id": "130421",
			"name": "邯郸县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130423",
			"name": "临漳县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130424",
			"name": "成安县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130425",
			"name": "大名县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130426",
			"name": "涉县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130427",
			"name": "磁县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130428",
			"name": "肥乡县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130429",
			"name": "永年县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130430",
			"name": "邱县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130431",
			"name": "鸡泽县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130432",
			"name": "广平县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130433",
			"name": "馆陶县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130434",
			"name": "魏县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130435",
			"name": "曲周县",
			"parent": "130400",
			"children": null
		}, {
			"id": "130481",
			"name": "武安市",
			"parent": "130400",
			"children": null
		}, {
			"id": "130482",
			"name": "其它区",
			"parent": "130400",
			"children": null
		}]
	}, {
		"id": "130500",
		"name": "邢台市",
		"parent": "130000",
		"children": [{
			"id": "130502",
			"name": "桥东区",
			"parent": "130500",
			"children": null
		}, {
			"id": "130503",
			"name": "桥西区",
			"parent": "130500",
			"children": null
		}, {
			"id": "130521",
			"name": "邢台县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130522",
			"name": "临城县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130523",
			"name": "内丘县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130524",
			"name": "柏乡县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130525",
			"name": "隆尧县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130526",
			"name": "任县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130527",
			"name": "南和县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130528",
			"name": "宁晋县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130529",
			"name": "巨鹿县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130530",
			"name": "新河县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130531",
			"name": "广宗县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130532",
			"name": "平乡县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130533",
			"name": "威县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130534",
			"name": "清河县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130535",
			"name": "临西县",
			"parent": "130500",
			"children": null
		}, {
			"id": "130581",
			"name": "南宫市",
			"parent": "130500",
			"children": null
		}, {
			"id": "130582",
			"name": "沙河市",
			"parent": "130500",
			"children": null
		}, {
			"id": "130583",
			"name": "其它区",
			"parent": "130500",
			"children": null
		}]
	}, {
		"id": "130600",
		"name": "保定市",
		"parent": "130000",
		"children": [{
			"id": "130602",
			"name": "新市区",
			"parent": "130600",
			"children": null
		}, {
			"id": "130603",
			"name": "北市区",
			"parent": "130600",
			"children": null
		}, {
			"id": "130604",
			"name": "南市区",
			"parent": "130600",
			"children": null
		}, {
			"id": "130621",
			"name": "满城县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130622",
			"name": "清苑县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130623",
			"name": "涞水县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130624",
			"name": "阜平县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130625",
			"name": "徐水县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130626",
			"name": "定兴县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130627",
			"name": "唐县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130628",
			"name": "高阳县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130629",
			"name": "容城县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130630",
			"name": "涞源县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130631",
			"name": "望都县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130632",
			"name": "安新县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130633",
			"name": "易县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130634",
			"name": "曲阳县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130635",
			"name": "蠡县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130636",
			"name": "顺平县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130637",
			"name": "博野县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130638",
			"name": "雄县",
			"parent": "130600",
			"children": null
		}, {
			"id": "130681",
			"name": "涿州市",
			"parent": "130600",
			"children": null
		}, {
			"id": "130682",
			"name": "定州市",
			"parent": "130600",
			"children": null
		}, {
			"id": "130683",
			"name": "安国市",
			"parent": "130600",
			"children": null
		}, {
			"id": "130684",
			"name": "高碑店市",
			"parent": "130600",
			"children": null
		}, {
			"id": "130698",
			"name": "高开区",
			"parent": "130600",
			"children": null
		}, {
			"id": "130699",
			"name": "其它区",
			"parent": "130600",
			"children": null
		}]
	}, {
		"id": "130700",
		"name": "张家口市",
		"parent": "130000",
		"children": [{
			"id": "130702",
			"name": "桥东区",
			"parent": "130700",
			"children": null
		}, {
			"id": "130703",
			"name": "桥西区",
			"parent": "130700",
			"children": null
		}, {
			"id": "130705",
			"name": "宣化区",
			"parent": "130700",
			"children": null
		}, {
			"id": "130706",
			"name": "下花园区",
			"parent": "130700",
			"children": null
		}, {
			"id": "130721",
			"name": "宣化县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130722",
			"name": "张北县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130723",
			"name": "康保县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130724",
			"name": "沽源县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130725",
			"name": "尚义县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130726",
			"name": "蔚县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130727",
			"name": "阳原县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130728",
			"name": "怀安县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130729",
			"name": "万全县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130730",
			"name": "怀来县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130731",
			"name": "涿鹿县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130732",
			"name": "赤城县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130733",
			"name": "崇礼县",
			"parent": "130700",
			"children": null
		}, {
			"id": "130734",
			"name": "其它区",
			"parent": "130700",
			"children": null
		}]
	}, {
		"id": "130800",
		"name": "承德市",
		"parent": "130000",
		"children": [{
			"id": "130802",
			"name": "双桥区",
			"parent": "130800",
			"children": null
		}, {
			"id": "130803",
			"name": "双滦区",
			"parent": "130800",
			"children": null
		}, {
			"id": "130804",
			"name": "鹰手营子矿区",
			"parent": "130800",
			"children": null
		}, {
			"id": "130821",
			"name": "承德县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130822",
			"name": "兴隆县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130823",
			"name": "平泉县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130824",
			"name": "滦平县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130825",
			"name": "隆化县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130826",
			"name": "丰宁满族自治县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130827",
			"name": "宽城满族自治县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130828",
			"name": "围场满族蒙古族自治县",
			"parent": "130800",
			"children": null
		}, {
			"id": "130829",
			"name": "其它区",
			"parent": "130800",
			"children": null
		}]
	}, {
		"id": "130900",
		"name": "沧州市",
		"parent": "130000",
		"children": [{
			"id": "130902",
			"name": "新华区",
			"parent": "130900",
			"children": null
		}, {
			"id": "130903",
			"name": "运河区",
			"parent": "130900",
			"children": null
		}, {
			"id": "130921",
			"name": "沧县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130922",
			"name": "青县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130923",
			"name": "东光县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130924",
			"name": "海兴县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130925",
			"name": "盐山县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130926",
			"name": "肃宁县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130927",
			"name": "南皮县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130928",
			"name": "吴桥县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130929",
			"name": "献县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130930",
			"name": "孟村回族自治县",
			"parent": "130900",
			"children": null
		}, {
			"id": "130981",
			"name": "泊头市",
			"parent": "130900",
			"children": null
		}, {
			"id": "130982",
			"name": "任丘市",
			"parent": "130900",
			"children": null
		}, {
			"id": "130983",
			"name": "黄骅市",
			"parent": "130900",
			"children": null
		}, {
			"id": "130984",
			"name": "河间市",
			"parent": "130900",
			"children": null
		}, {
			"id": "130985",
			"name": "其它区",
			"parent": "130900",
			"children": null
		}]
	}, {
		"id": "131000",
		"name": "廊坊市",
		"parent": "130000",
		"children": [{
			"id": "131002",
			"name": "安次区",
			"parent": "131000",
			"children": null
		}, {
			"id": "131003",
			"name": "广阳区",
			"parent": "131000",
			"children": null
		}, {
			"id": "131022",
			"name": "固安县",
			"parent": "131000",
			"children": null
		}, {
			"id": "131023",
			"name": "永清县",
			"parent": "131000",
			"children": null
		}, {
			"id": "131024",
			"name": "香河县",
			"parent": "131000",
			"children": null
		}, {
			"id": "131025",
			"name": "大城县",
			"parent": "131000",
			"children": null
		}, {
			"id": "131026",
			"name": "文安县",
			"parent": "131000",
			"children": null
		}, {
			"id": "131028",
			"name": "大厂回族自治县",
			"parent": "131000",
			"children": null
		}, {
			"id": "131051",
			"name": "开发区",
			"parent": "131000",
			"children": null
		}, {
			"id": "131052",
			"name": "燕郊经济技术开发区",
			"parent": "131000",
			"children": null
		}, {
			"id": "131081",
			"name": "霸州市",
			"parent": "131000",
			"children": null
		}, {
			"id": "131082",
			"name": "三河市",
			"parent": "131000",
			"children": null
		}, {
			"id": "131083",
			"name": "其它区",
			"parent": "131000",
			"children": null
		}]
	}, {
		"id": "131100",
		"name": "衡水市",
		"parent": "130000",
		"children": [{
			"id": "131102",
			"name": "桃城区",
			"parent": "131100",
			"children": null
		}, {
			"id": "131121",
			"name": "枣强县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131122",
			"name": "武邑县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131123",
			"name": "武强县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131124",
			"name": "饶阳县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131125",
			"name": "安平县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131126",
			"name": "故城县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131127",
			"name": "景县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131128",
			"name": "阜城县",
			"parent": "131100",
			"children": null
		}, {
			"id": "131181",
			"name": "冀州市",
			"parent": "131100",
			"children": null
		}, {
			"id": "131182",
			"name": "深州市",
			"parent": "131100",
			"children": null
		}, {
			"id": "131183",
			"name": "其它区",
			"parent": "131100",
			"children": null
		}]
	}]
}, {
	"id": "140000",
	"name": "山西省",
	"children": [{
		"id": "140100",
		"name": "太原市",
		"parent": "140000",
		"children": [{
			"id": "140105",
			"name": "小店区",
			"parent": "140100",
			"children": null
		}, {
			"id": "140106",
			"name": "迎泽区",
			"parent": "140100",
			"children": null
		}, {
			"id": "140107",
			"name": "杏花岭区",
			"parent": "140100",
			"children": null
		}, {
			"id": "140108",
			"name": "尖草坪区",
			"parent": "140100",
			"children": null
		}, {
			"id": "140109",
			"name": "万柏林区",
			"parent": "140100",
			"children": null
		}, {
			"id": "140110",
			"name": "晋源区",
			"parent": "140100",
			"children": null
		}, {
			"id": "140121",
			"name": "清徐县",
			"parent": "140100",
			"children": null
		}, {
			"id": "140122",
			"name": "阳曲县",
			"parent": "140100",
			"children": null
		}, {
			"id": "140123",
			"name": "娄烦县",
			"parent": "140100",
			"children": null
		}, {
			"id": "140181",
			"name": "古交市",
			"parent": "140100",
			"children": null
		}, {
			"id": "140182",
			"name": "其它区",
			"parent": "140100",
			"children": null
		}]
	}, {
		"id": "140200",
		"name": "大同市",
		"parent": "140000",
		"children": [{
			"id": "140202",
			"name": "城区",
			"parent": "140200",
			"children": null
		}, {
			"id": "140203",
			"name": "矿区",
			"parent": "140200",
			"children": null
		}, {
			"id": "140211",
			"name": "南郊区",
			"parent": "140200",
			"children": null
		}, {
			"id": "140212",
			"name": "新荣区",
			"parent": "140200",
			"children": null
		}, {
			"id": "140221",
			"name": "阳高县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140222",
			"name": "天镇县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140223",
			"name": "广灵县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140224",
			"name": "灵丘县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140225",
			"name": "浑源县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140226",
			"name": "左云县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140227",
			"name": "大同县",
			"parent": "140200",
			"children": null
		}, {
			"id": "140228",
			"name": "其它区",
			"parent": "140200",
			"children": null
		}]
	}, {
		"id": "140300",
		"name": "阳泉市",
		"parent": "140000",
		"children": [{
			"id": "140302",
			"name": "城区",
			"parent": "140300",
			"children": null
		}, {
			"id": "140303",
			"name": "矿区",
			"parent": "140300",
			"children": null
		}, {
			"id": "140311",
			"name": "郊区",
			"parent": "140300",
			"children": null
		}, {
			"id": "140321",
			"name": "平定县",
			"parent": "140300",
			"children": null
		}, {
			"id": "140322",
			"name": "盂县",
			"parent": "140300",
			"children": null
		}, {
			"id": "140323",
			"name": "其它区",
			"parent": "140300",
			"children": null
		}]
	}, {
		"id": "140400",
		"name": "长治市",
		"parent": "140000",
		"children": [{
			"id": "140421",
			"name": "长治县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140423",
			"name": "襄垣县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140424",
			"name": "屯留县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140425",
			"name": "平顺县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140426",
			"name": "黎城县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140427",
			"name": "壶关县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140428",
			"name": "长子县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140429",
			"name": "武乡县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140430",
			"name": "沁县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140431",
			"name": "沁源县",
			"parent": "140400",
			"children": null
		}, {
			"id": "140481",
			"name": "潞城市",
			"parent": "140400",
			"children": null
		}, {
			"id": "140482",
			"name": "城区",
			"parent": "140400",
			"children": null
		}, {
			"id": "140483",
			"name": "郊区",
			"parent": "140400",
			"children": null
		}, {
			"id": "140484",
			"name": "高新区",
			"parent": "140400",
			"children": null
		}, {
			"id": "140485",
			"name": "其它区",
			"parent": "140400",
			"children": null
		}]
	}, {
		"id": "140500",
		"name": "晋城市",
		"parent": "140000",
		"children": [{
			"id": "140502",
			"name": "城区",
			"parent": "140500",
			"children": null
		}, {
			"id": "140521",
			"name": "沁水县",
			"parent": "140500",
			"children": null
		}, {
			"id": "140522",
			"name": "阳城县",
			"parent": "140500",
			"children": null
		}, {
			"id": "140524",
			"name": "陵川县",
			"parent": "140500",
			"children": null
		}, {
			"id": "140525",
			"name": "泽州县",
			"parent": "140500",
			"children": null
		}, {
			"id": "140581",
			"name": "高平市",
			"parent": "140500",
			"children": null
		}, {
			"id": "140582",
			"name": "其它区",
			"parent": "140500",
			"children": null
		}]
	}, {
		"id": "140600",
		"name": "朔州市",
		"parent": "140000",
		"children": [{
			"id": "140602",
			"name": "朔城区",
			"parent": "140600",
			"children": null
		}, {
			"id": "140603",
			"name": "平鲁区",
			"parent": "140600",
			"children": null
		}, {
			"id": "140621",
			"name": "山阴县",
			"parent": "140600",
			"children": null
		}, {
			"id": "140622",
			"name": "应县",
			"parent": "140600",
			"children": null
		}, {
			"id": "140623",
			"name": "右玉县",
			"parent": "140600",
			"children": null
		}, {
			"id": "140624",
			"name": "怀仁县",
			"parent": "140600",
			"children": null
		}, {
			"id": "140625",
			"name": "其它区",
			"parent": "140600",
			"children": null
		}]
	}, {
		"id": "140700",
		"name": "晋中市",
		"parent": "140000",
		"children": [{
			"id": "140702",
			"name": "榆次区",
			"parent": "140700",
			"children": null
		}, {
			"id": "140721",
			"name": "榆社县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140722",
			"name": "左权县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140723",
			"name": "和顺县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140724",
			"name": "昔阳县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140725",
			"name": "寿阳县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140726",
			"name": "太谷县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140727",
			"name": "祁县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140728",
			"name": "平遥县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140729",
			"name": "灵石县",
			"parent": "140700",
			"children": null
		}, {
			"id": "140781",
			"name": "介休市",
			"parent": "140700",
			"children": null
		}, {
			"id": "140782",
			"name": "其它区",
			"parent": "140700",
			"children": null
		}]
	}, {
		"id": "140800",
		"name": "运城市",
		"parent": "140000",
		"children": [{
			"id": "140802",
			"name": "盐湖区",
			"parent": "140800",
			"children": null
		}, {
			"id": "140821",
			"name": "临猗县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140822",
			"name": "万荣县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140823",
			"name": "闻喜县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140824",
			"name": "稷山县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140825",
			"name": "新绛县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140826",
			"name": "绛县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140827",
			"name": "垣曲县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140828",
			"name": "夏县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140829",
			"name": "平陆县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140830",
			"name": "芮城县",
			"parent": "140800",
			"children": null
		}, {
			"id": "140881",
			"name": "永济市",
			"parent": "140800",
			"children": null
		}, {
			"id": "140882",
			"name": "河津市",
			"parent": "140800",
			"children": null
		}, {
			"id": "140883",
			"name": "其它区",
			"parent": "140800",
			"children": null
		}]
	}, {
		"id": "140900",
		"name": "忻州市",
		"parent": "140000",
		"children": [{
			"id": "140902",
			"name": "忻府区",
			"parent": "140900",
			"children": null
		}, {
			"id": "140921",
			"name": "定襄县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140922",
			"name": "五台县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140923",
			"name": "代县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140924",
			"name": "繁峙县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140925",
			"name": "宁武县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140926",
			"name": "静乐县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140927",
			"name": "神池县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140928",
			"name": "五寨县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140929",
			"name": "岢岚县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140930",
			"name": "河曲县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140931",
			"name": "保德县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140932",
			"name": "偏关县",
			"parent": "140900",
			"children": null
		}, {
			"id": "140981",
			"name": "原平市",
			"parent": "140900",
			"children": null
		}, {
			"id": "140982",
			"name": "其它区",
			"parent": "140900",
			"children": null
		}]
	}, {
		"id": "141000",
		"name": "临汾市",
		"parent": "140000",
		"children": [{
			"id": "141002",
			"name": "尧都区",
			"parent": "141000",
			"children": null
		}, {
			"id": "141021",
			"name": "曲沃县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141022",
			"name": "翼城县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141023",
			"name": "襄汾县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141024",
			"name": "洪洞县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141025",
			"name": "古县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141026",
			"name": "安泽县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141027",
			"name": "浮山县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141028",
			"name": "吉县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141029",
			"name": "乡宁县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141030",
			"name": "大宁县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141031",
			"name": "隰县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141032",
			"name": "永和县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141033",
			"name": "蒲县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141034",
			"name": "汾西县",
			"parent": "141000",
			"children": null
		}, {
			"id": "141081",
			"name": "侯马市",
			"parent": "141000",
			"children": null
		}, {
			"id": "141082",
			"name": "霍州市",
			"parent": "141000",
			"children": null
		}, {
			"id": "141083",
			"name": "其它区",
			"parent": "141000",
			"children": null
		}]
	}, {
		"id": "141100",
		"name": "吕梁市",
		"parent": "140000",
		"children": [{
			"id": "141102",
			"name": "离石区",
			"parent": "141100",
			"children": null
		}, {
			"id": "141121",
			"name": "文水县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141122",
			"name": "交城县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141123",
			"name": "兴县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141124",
			"name": "临县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141125",
			"name": "柳林县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141126",
			"name": "石楼县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141127",
			"name": "岚县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141128",
			"name": "方山县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141129",
			"name": "中阳县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141130",
			"name": "交口县",
			"parent": "141100",
			"children": null
		}, {
			"id": "141181",
			"name": "孝义市",
			"parent": "141100",
			"children": null
		}, {
			"id": "141182",
			"name": "汾阳市",
			"parent": "141100",
			"children": null
		}, {
			"id": "141183",
			"name": "其它区",
			"parent": "141100",
			"children": null
		}]
	}]
}, {
	"id": "150000",
	"name": "内蒙古自治区",
	"children": [{
		"id": "150100",
		"name": "呼和浩特市",
		"parent": "150000",
		"children": [{
			"id": "150102",
			"name": "新城区",
			"parent": "150100",
			"children": null
		}, {
			"id": "150103",
			"name": "回民区",
			"parent": "150100",
			"children": null
		}, {
			"id": "150104",
			"name": "玉泉区",
			"parent": "150100",
			"children": null
		}, {
			"id": "150105",
			"name": "赛罕区",
			"parent": "150100",
			"children": null
		}, {
			"id": "150121",
			"name": "土默特左旗",
			"parent": "150100",
			"children": null
		}, {
			"id": "150122",
			"name": "托克托县",
			"parent": "150100",
			"children": null
		}, {
			"id": "150123",
			"name": "和林格尔县",
			"parent": "150100",
			"children": null
		}, {
			"id": "150124",
			"name": "清水河县",
			"parent": "150100",
			"children": null
		}, {
			"id": "150125",
			"name": "武川县",
			"parent": "150100",
			"children": null
		}, {
			"id": "150126",
			"name": "其它区",
			"parent": "150100",
			"children": null
		}]
	}, {
		"id": "150200",
		"name": "包头市",
		"parent": "150000",
		"children": [{
			"id": "150202",
			"name": "东河区",
			"parent": "150200",
			"children": null
		}, {
			"id": "150203",
			"name": "昆都仑区",
			"parent": "150200",
			"children": null
		}, {
			"id": "150204",
			"name": "青山区",
			"parent": "150200",
			"children": null
		}, {
			"id": "150205",
			"name": "石拐区",
			"parent": "150200",
			"children": null
		}, {
			"id": "150206",
			"name": "白云鄂博矿区",
			"parent": "150200",
			"children": null
		}, {
			"id": "150207",
			"name": "九原区",
			"parent": "150200",
			"children": null
		}, {
			"id": "150221",
			"name": "土默特右旗",
			"parent": "150200",
			"children": null
		}, {
			"id": "150222",
			"name": "固阳县",
			"parent": "150200",
			"children": null
		}, {
			"id": "150223",
			"name": "达尔罕茂明安联合旗",
			"parent": "150200",
			"children": null
		}, {
			"id": "150224",
			"name": "其它区",
			"parent": "150200",
			"children": null
		}]
	}, {
		"id": "150300",
		"name": "乌海市",
		"parent": "150000",
		"children": [{
			"id": "150302",
			"name": "海勃湾区",
			"parent": "150300",
			"children": null
		}, {
			"id": "150303",
			"name": "海南区",
			"parent": "150300",
			"children": null
		}, {
			"id": "150304",
			"name": "乌达区",
			"parent": "150300",
			"children": null
		}, {
			"id": "150305",
			"name": "其它区",
			"parent": "150300",
			"children": null
		}]
	}, {
		"id": "150400",
		"name": "赤峰市",
		"parent": "150000",
		"children": [{
			"id": "150402",
			"name": "红山区",
			"parent": "150400",
			"children": null
		}, {
			"id": "150403",
			"name": "元宝山区",
			"parent": "150400",
			"children": null
		}, {
			"id": "150404",
			"name": "松山区",
			"parent": "150400",
			"children": null
		}, {
			"id": "150421",
			"name": "阿鲁科尔沁旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150422",
			"name": "巴林左旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150423",
			"name": "巴林右旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150424",
			"name": "林西县",
			"parent": "150400",
			"children": null
		}, {
			"id": "150425",
			"name": "克什克腾旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150426",
			"name": "翁牛特旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150428",
			"name": "喀喇沁旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150429",
			"name": "宁城县",
			"parent": "150400",
			"children": null
		}, {
			"id": "150430",
			"name": "敖汉旗",
			"parent": "150400",
			"children": null
		}, {
			"id": "150431",
			"name": "其它区",
			"parent": "150400",
			"children": null
		}]
	}, {
		"id": "150500",
		"name": "通辽市",
		"parent": "150000",
		"children": [{
			"id": "150502",
			"name": "科尔沁区",
			"parent": "150500",
			"children": null
		}, {
			"id": "150521",
			"name": "科尔沁左翼中旗",
			"parent": "150500",
			"children": null
		}, {
			"id": "150522",
			"name": "科尔沁左翼后旗",
			"parent": "150500",
			"children": null
		}, {
			"id": "150523",
			"name": "开鲁县",
			"parent": "150500",
			"children": null
		}, {
			"id": "150524",
			"name": "库伦旗",
			"parent": "150500",
			"children": null
		}, {
			"id": "150525",
			"name": "奈曼旗",
			"parent": "150500",
			"children": null
		}, {
			"id": "150526",
			"name": "扎鲁特旗",
			"parent": "150500",
			"children": null
		}, {
			"id": "150581",
			"name": "霍林郭勒市",
			"parent": "150500",
			"children": null
		}, {
			"id": "150582",
			"name": "其它区",
			"parent": "150500",
			"children": null
		}]
	}, {
		"id": "150600",
		"name": "鄂尔多斯市",
		"parent": "150000",
		"children": [{
			"id": "150602",
			"name": "东胜区",
			"parent": "150600",
			"children": null
		}, {
			"id": "150621",
			"name": "达拉特旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150622",
			"name": "准格尔旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150623",
			"name": "鄂托克前旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150624",
			"name": "鄂托克旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150625",
			"name": "杭锦旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150626",
			"name": "乌审旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150627",
			"name": "伊金霍洛旗",
			"parent": "150600",
			"children": null
		}, {
			"id": "150628",
			"name": "其它区",
			"parent": "150600",
			"children": null
		}]
	}, {
		"id": "150700",
		"name": "呼伦贝尔市",
		"parent": "150000",
		"children": [{
			"id": "150702",
			"name": "海拉尔区",
			"parent": "150700",
			"children": null
		}, {
			"id": "150703",
			"name": "扎赉诺尔区",
			"parent": "150700",
			"children": null
		}, {
			"id": "150721",
			"name": "阿荣旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150722",
			"name": "莫力达瓦达斡尔族自治旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150723",
			"name": "鄂伦春自治旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150724",
			"name": "鄂温克族自治旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150725",
			"name": "陈巴尔虎旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150726",
			"name": "新巴尔虎左旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150727",
			"name": "新巴尔虎右旗",
			"parent": "150700",
			"children": null
		}, {
			"id": "150781",
			"name": "满洲里市",
			"parent": "150700",
			"children": null
		}, {
			"id": "150782",
			"name": "牙克石市",
			"parent": "150700",
			"children": null
		}, {
			"id": "150783",
			"name": "扎兰屯市",
			"parent": "150700",
			"children": null
		}, {
			"id": "150784",
			"name": "额尔古纳市",
			"parent": "150700",
			"children": null
		}, {
			"id": "150785",
			"name": "根河市",
			"parent": "150700",
			"children": null
		}, {
			"id": "150786",
			"name": "其它区",
			"parent": "150700",
			"children": null
		}]
	}, {
		"id": "150800",
		"name": "巴彦淖尔市",
		"parent": "150000",
		"children": [{
			"id": "150802",
			"name": "临河区",
			"parent": "150800",
			"children": null
		}, {
			"id": "150821",
			"name": "五原县",
			"parent": "150800",
			"children": null
		}, {
			"id": "150822",
			"name": "磴口县",
			"parent": "150800",
			"children": null
		}, {
			"id": "150823",
			"name": "乌拉特前旗",
			"parent": "150800",
			"children": null
		}, {
			"id": "150824",
			"name": "乌拉特中旗",
			"parent": "150800",
			"children": null
		}, {
			"id": "150825",
			"name": "乌拉特后旗",
			"parent": "150800",
			"children": null
		}, {
			"id": "150826",
			"name": "杭锦后旗",
			"parent": "150800",
			"children": null
		}, {
			"id": "150827",
			"name": "其它区",
			"parent": "150800",
			"children": null
		}]
	}, {
		"id": "150900",
		"name": "乌兰察布市",
		"parent": "150000",
		"children": [{
			"id": "150902",
			"name": "集宁区",
			"parent": "150900",
			"children": null
		}, {
			"id": "150921",
			"name": "卓资县",
			"parent": "150900",
			"children": null
		}, {
			"id": "150922",
			"name": "化德县",
			"parent": "150900",
			"children": null
		}, {
			"id": "150923",
			"name": "商都县",
			"parent": "150900",
			"children": null
		}, {
			"id": "150924",
			"name": "兴和县",
			"parent": "150900",
			"children": null
		}, {
			"id": "150925",
			"name": "凉城县",
			"parent": "150900",
			"children": null
		}, {
			"id": "150926",
			"name": "察哈尔右翼前旗",
			"parent": "150900",
			"children": null
		}, {
			"id": "150927",
			"name": "察哈尔右翼中旗",
			"parent": "150900",
			"children": null
		}, {
			"id": "150928",
			"name": "察哈尔右翼后旗",
			"parent": "150900",
			"children": null
		}, {
			"id": "150929",
			"name": "四子王旗",
			"parent": "150900",
			"children": null
		}, {
			"id": "150981",
			"name": "丰镇市",
			"parent": "150900",
			"children": null
		}, {
			"id": "150982",
			"name": "其它区",
			"parent": "150900",
			"children": null
		}]
	}, {
		"id": "152200",
		"name": "兴安盟",
		"parent": "150000",
		"children": [{
			"id": "152201",
			"name": "乌兰浩特市",
			"parent": "152200",
			"children": null
		}, {
			"id": "152202",
			"name": "阿尔山市",
			"parent": "152200",
			"children": null
		}, {
			"id": "152221",
			"name": "科尔沁右翼前旗",
			"parent": "152200",
			"children": null
		}, {
			"id": "152222",
			"name": "科尔沁右翼中旗",
			"parent": "152200",
			"children": null
		}, {
			"id": "152223",
			"name": "扎赉特旗",
			"parent": "152200",
			"children": null
		}, {
			"id": "152224",
			"name": "突泉县",
			"parent": "152200",
			"children": null
		}, {
			"id": "152225",
			"name": "其它区",
			"parent": "152200",
			"children": null
		}]
	}, {
		"id": "152500",
		"name": "锡林郭勒盟",
		"parent": "150000",
		"children": [{
			"id": "152501",
			"name": "二连浩特市",
			"parent": "152500",
			"children": null
		}, {
			"id": "152502",
			"name": "锡林浩特市",
			"parent": "152500",
			"children": null
		}, {
			"id": "152522",
			"name": "阿巴嘎旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152523",
			"name": "苏尼特左旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152524",
			"name": "苏尼特右旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152525",
			"name": "东乌珠穆沁旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152526",
			"name": "西乌珠穆沁旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152527",
			"name": "太仆寺旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152528",
			"name": "镶黄旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152529",
			"name": "正镶白旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152530",
			"name": "正蓝旗",
			"parent": "152500",
			"children": null
		}, {
			"id": "152531",
			"name": "多伦县",
			"parent": "152500",
			"children": null
		}, {
			"id": "152532",
			"name": "其它区",
			"parent": "152500",
			"children": null
		}]
	}, {
		"id": "152900",
		"name": "阿拉善盟",
		"parent": "150000",
		"children": [{
			"id": "152921",
			"name": "阿拉善左旗",
			"parent": "152900",
			"children": null
		}, {
			"id": "152922",
			"name": "阿拉善右旗",
			"parent": "152900",
			"children": null
		}, {
			"id": "152923",
			"name": "额济纳旗",
			"parent": "152900",
			"children": null
		}, {
			"id": "152924",
			"name": "其它区",
			"parent": "152900",
			"children": null
		}]
	}]
}, {
	"id": "210000",
	"name": "辽宁省",
	"children": [{
		"id": "210100",
		"name": "沈阳市",
		"parent": "210000",
		"children": [{
			"id": "210102",
			"name": "和平区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210103",
			"name": "沈河区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210104",
			"name": "大东区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210105",
			"name": "皇姑区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210106",
			"name": "铁西区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210111",
			"name": "苏家屯区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210112",
			"name": "浑南区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210113",
			"name": "新城子区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210114",
			"name": "于洪区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210122",
			"name": "辽中县",
			"parent": "210100",
			"children": null
		}, {
			"id": "210123",
			"name": "康平县",
			"parent": "210100",
			"children": null
		}, {
			"id": "210124",
			"name": "法库县",
			"parent": "210100",
			"children": null
		}, {
			"id": "210181",
			"name": "新民市",
			"parent": "210100",
			"children": null
		}, {
			"id": "210182",
			"name": "浑南新区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210183",
			"name": "张士开发区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210184",
			"name": "沈北新区",
			"parent": "210100",
			"children": null
		}, {
			"id": "210185",
			"name": "其它区",
			"parent": "210100",
			"children": null
		}]
	}, {
		"id": "210200",
		"name": "大连市",
		"parent": "210000",
		"children": [{
			"id": "210202",
			"name": "中山区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210203",
			"name": "西岗区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210204",
			"name": "沙河口区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210211",
			"name": "甘井子区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210212",
			"name": "旅顺口区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210213",
			"name": "金州区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210224",
			"name": "长海县",
			"parent": "210200",
			"children": null
		}, {
			"id": "210251",
			"name": "开发区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210281",
			"name": "瓦房店市",
			"parent": "210200",
			"children": null
		}, {
			"id": "210282",
			"name": "普兰店市",
			"parent": "210200",
			"children": null
		}, {
			"id": "210283",
			"name": "庄河市",
			"parent": "210200",
			"children": null
		}, {
			"id": "210297",
			"name": "岭前区",
			"parent": "210200",
			"children": null
		}, {
			"id": "210298",
			"name": "其它区",
			"parent": "210200",
			"children": null
		}]
	}, {
		"id": "210300",
		"name": "鞍山市",
		"parent": "210000",
		"children": [{
			"id": "210302",
			"name": "铁东区",
			"parent": "210300",
			"children": null
		}, {
			"id": "210303",
			"name": "铁西区",
			"parent": "210300",
			"children": null
		}, {
			"id": "210304",
			"name": "立山区",
			"parent": "210300",
			"children": null
		}, {
			"id": "210311",
			"name": "千山区",
			"parent": "210300",
			"children": null
		}, {
			"id": "210321",
			"name": "台安县",
			"parent": "210300",
			"children": null
		}, {
			"id": "210323",
			"name": "岫岩满族自治县",
			"parent": "210300",
			"children": null
		}, {
			"id": "210351",
			"name": "高新区",
			"parent": "210300",
			"children": null
		}, {
			"id": "210381",
			"name": "海城市",
			"parent": "210300",
			"children": null
		}, {
			"id": "210382",
			"name": "其它区",
			"parent": "210300",
			"children": null
		}]
	}, {
		"id": "210400",
		"name": "抚顺市",
		"parent": "210000",
		"children": [{
			"id": "210402",
			"name": "新抚区",
			"parent": "210400",
			"children": null
		}, {
			"id": "210403",
			"name": "东洲区",
			"parent": "210400",
			"children": null
		}, {
			"id": "210404",
			"name": "望花区",
			"parent": "210400",
			"children": null
		}, {
			"id": "210411",
			"name": "顺城区",
			"parent": "210400",
			"children": null
		}, {
			"id": "210421",
			"name": "抚顺县",
			"parent": "210400",
			"children": null
		}, {
			"id": "210422",
			"name": "新宾满族自治县",
			"parent": "210400",
			"children": null
		}, {
			"id": "210423",
			"name": "清原满族自治县",
			"parent": "210400",
			"children": null
		}, {
			"id": "210424",
			"name": "其它区",
			"parent": "210400",
			"children": null
		}]
	}, {
		"id": "210500",
		"name": "本溪市",
		"parent": "210000",
		"children": [{
			"id": "210502",
			"name": "平山区",
			"parent": "210500",
			"children": null
		}, {
			"id": "210503",
			"name": "溪湖区",
			"parent": "210500",
			"children": null
		}, {
			"id": "210504",
			"name": "明山区",
			"parent": "210500",
			"children": null
		}, {
			"id": "210505",
			"name": "南芬区",
			"parent": "210500",
			"children": null
		}, {
			"id": "210521",
			"name": "本溪满族自治县",
			"parent": "210500",
			"children": null
		}, {
			"id": "210522",
			"name": "桓仁满族自治县",
			"parent": "210500",
			"children": null
		}, {
			"id": "210523",
			"name": "其它区",
			"parent": "210500",
			"children": null
		}]
	}, {
		"id": "210600",
		"name": "丹东市",
		"parent": "210000",
		"children": [{
			"id": "210602",
			"name": "元宝区",
			"parent": "210600",
			"children": null
		}, {
			"id": "210603",
			"name": "振兴区",
			"parent": "210600",
			"children": null
		}, {
			"id": "210604",
			"name": "振安区",
			"parent": "210600",
			"children": null
		}, {
			"id": "210624",
			"name": "宽甸满族自治县",
			"parent": "210600",
			"children": null
		}, {
			"id": "210681",
			"name": "东港市",
			"parent": "210600",
			"children": null
		}, {
			"id": "210682",
			"name": "凤城市",
			"parent": "210600",
			"children": null
		}, {
			"id": "210683",
			"name": "其它区",
			"parent": "210600",
			"children": null
		}]
	}, {
		"id": "210700",
		"name": "锦州市",
		"parent": "210000",
		"children": [{
			"id": "210702",
			"name": "古塔区",
			"parent": "210700",
			"children": null
		}, {
			"id": "210703",
			"name": "凌河区",
			"parent": "210700",
			"children": null
		}, {
			"id": "210711",
			"name": "太和区",
			"parent": "210700",
			"children": null
		}, {
			"id": "210726",
			"name": "黑山县",
			"parent": "210700",
			"children": null
		}, {
			"id": "210727",
			"name": "义县",
			"parent": "210700",
			"children": null
		}, {
			"id": "210781",
			"name": "凌海市",
			"parent": "210700",
			"children": null
		}, {
			"id": "210782",
			"name": "北镇市",
			"parent": "210700",
			"children": null
		}, {
			"id": "210783",
			"name": "其它区",
			"parent": "210700",
			"children": null
		}]
	}, {
		"id": "210800",
		"name": "营口市",
		"parent": "210000",
		"children": [{
			"id": "210802",
			"name": "站前区",
			"parent": "210800",
			"children": null
		}, {
			"id": "210803",
			"name": "西市区",
			"parent": "210800",
			"children": null
		}, {
			"id": "210804",
			"name": "鲅鱼圈区",
			"parent": "210800",
			"children": null
		}, {
			"id": "210811",
			"name": "老边区",
			"parent": "210800",
			"children": null
		}, {
			"id": "210881",
			"name": "盖州市",
			"parent": "210800",
			"children": null
		}, {
			"id": "210882",
			"name": "大石桥市",
			"parent": "210800",
			"children": null
		}, {
			"id": "210883",
			"name": "其它区",
			"parent": "210800",
			"children": null
		}]
	}, {
		"id": "210900",
		"name": "阜新市",
		"parent": "210000",
		"children": [{
			"id": "210902",
			"name": "海州区",
			"parent": "210900",
			"children": null
		}, {
			"id": "210903",
			"name": "新邱区",
			"parent": "210900",
			"children": null
		}, {
			"id": "210904",
			"name": "太平区",
			"parent": "210900",
			"children": null
		}, {
			"id": "210905",
			"name": "清河门区",
			"parent": "210900",
			"children": null
		}, {
			"id": "210911",
			"name": "细河区",
			"parent": "210900",
			"children": null
		}, {
			"id": "210921",
			"name": "阜新蒙古族自治县",
			"parent": "210900",
			"children": null
		}, {
			"id": "210922",
			"name": "彰武县",
			"parent": "210900",
			"children": null
		}, {
			"id": "210923",
			"name": "其它区",
			"parent": "210900",
			"children": null
		}]
	}, {
		"id": "211000",
		"name": "辽阳市",
		"parent": "210000",
		"children": [{
			"id": "211002",
			"name": "白塔区",
			"parent": "211000",
			"children": null
		}, {
			"id": "211003",
			"name": "文圣区",
			"parent": "211000",
			"children": null
		}, {
			"id": "211004",
			"name": "宏伟区",
			"parent": "211000",
			"children": null
		}, {
			"id": "211005",
			"name": "弓长岭区",
			"parent": "211000",
			"children": null
		}, {
			"id": "211011",
			"name": "太子河区",
			"parent": "211000",
			"children": null
		}, {
			"id": "211021",
			"name": "辽阳县",
			"parent": "211000",
			"children": null
		}, {
			"id": "211081",
			"name": "灯塔市",
			"parent": "211000",
			"children": null
		}, {
			"id": "211082",
			"name": "其它区",
			"parent": "211000",
			"children": null
		}]
	}, {
		"id": "211100",
		"name": "盘锦市",
		"parent": "210000",
		"children": [{
			"id": "211102",
			"name": "双台子区",
			"parent": "211100",
			"children": null
		}, {
			"id": "211103",
			"name": "兴隆台区",
			"parent": "211100",
			"children": null
		}, {
			"id": "211121",
			"name": "大洼县",
			"parent": "211100",
			"children": null
		}, {
			"id": "211122",
			"name": "盘山县",
			"parent": "211100",
			"children": null
		}, {
			"id": "211123",
			"name": "其它区",
			"parent": "211100",
			"children": null
		}]
	}, {
		"id": "211200",
		"name": "铁岭市",
		"parent": "210000",
		"children": [{
			"id": "211202",
			"name": "银州区",
			"parent": "211200",
			"children": null
		}, {
			"id": "211204",
			"name": "清河区",
			"parent": "211200",
			"children": null
		}, {
			"id": "211221",
			"name": "铁岭县",
			"parent": "211200",
			"children": null
		}, {
			"id": "211223",
			"name": "西丰县",
			"parent": "211200",
			"children": null
		}, {
			"id": "211224",
			"name": "昌图县",
			"parent": "211200",
			"children": null
		}, {
			"id": "211281",
			"name": "调兵山市",
			"parent": "211200",
			"children": null
		}, {
			"id": "211282",
			"name": "开原市",
			"parent": "211200",
			"children": null
		}, {
			"id": "211283",
			"name": "其它区",
			"parent": "211200",
			"children": null
		}]
	}, {
		"id": "211300",
		"name": "朝阳市",
		"parent": "210000",
		"children": [{
			"id": "211302",
			"name": "双塔区",
			"parent": "211300",
			"children": null
		}, {
			"id": "211303",
			"name": "龙城区",
			"parent": "211300",
			"children": null
		}, {
			"id": "211321",
			"name": "朝阳县",
			"parent": "211300",
			"children": null
		}, {
			"id": "211322",
			"name": "建平县",
			"parent": "211300",
			"children": null
		}, {
			"id": "211324",
			"name": "喀喇沁左翼蒙古族自治县",
			"parent": "211300",
			"children": null
		}, {
			"id": "211381",
			"name": "北票市",
			"parent": "211300",
			"children": null
		}, {
			"id": "211382",
			"name": "凌源市",
			"parent": "211300",
			"children": null
		}, {
			"id": "211383",
			"name": "其它区",
			"parent": "211300",
			"children": null
		}]
	}, {
		"id": "211400",
		"name": "葫芦岛市",
		"parent": "210000",
		"children": [{
			"id": "211402",
			"name": "连山区",
			"parent": "211400",
			"children": null
		}, {
			"id": "211403",
			"name": "龙港区",
			"parent": "211400",
			"children": null
		}, {
			"id": "211404",
			"name": "南票区",
			"parent": "211400",
			"children": null
		}, {
			"id": "211421",
			"name": "绥中县",
			"parent": "211400",
			"children": null
		}, {
			"id": "211422",
			"name": "建昌县",
			"parent": "211400",
			"children": null
		}, {
			"id": "211481",
			"name": "兴城市",
			"parent": "211400",
			"children": null
		}, {
			"id": "211482",
			"name": "其它区",
			"parent": "211400",
			"children": null
		}]
	}]
}, {
	"id": "220000",
	"name": "吉林省",
	"children": [{
		"id": "220100",
		"name": "长春市",
		"parent": "220000",
		"children": [{
			"id": "220102",
			"name": "南关区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220103",
			"name": "宽城区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220104",
			"name": "朝阳区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220105",
			"name": "二道区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220106",
			"name": "绿园区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220112",
			"name": "双阳区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220122",
			"name": "农安县",
			"parent": "220100",
			"children": null
		}, {
			"id": "220181",
			"name": "九台区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220182",
			"name": "榆树市",
			"parent": "220100",
			"children": null
		}, {
			"id": "220183",
			"name": "德惠市",
			"parent": "220100",
			"children": null
		}, {
			"id": "220184",
			"name": "高新技术产业开发区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220185",
			"name": "汽车产业开发区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220186",
			"name": "经济技术开发区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220187",
			"name": "净月旅游开发区",
			"parent": "220100",
			"children": null
		}, {
			"id": "220188",
			"name": "其它区",
			"parent": "220100",
			"children": null
		}]
	}, {
		"id": "220200",
		"name": "吉林市",
		"parent": "220000",
		"children": [{
			"id": "220202",
			"name": "昌邑区",
			"parent": "220200",
			"children": null
		}, {
			"id": "220203",
			"name": "龙潭区",
			"parent": "220200",
			"children": null
		}, {
			"id": "220204",
			"name": "船营区",
			"parent": "220200",
			"children": null
		}, {
			"id": "220211",
			"name": "丰满区",
			"parent": "220200",
			"children": null
		}, {
			"id": "220221",
			"name": "永吉县",
			"parent": "220200",
			"children": null
		}, {
			"id": "220281",
			"name": "蛟河市",
			"parent": "220200",
			"children": null
		}, {
			"id": "220282",
			"name": "桦甸市",
			"parent": "220200",
			"children": null
		}, {
			"id": "220283",
			"name": "舒兰市",
			"parent": "220200",
			"children": null
		}, {
			"id": "220284",
			"name": "磐石市",
			"parent": "220200",
			"children": null
		}, {
			"id": "220285",
			"name": "其它区",
			"parent": "220200",
			"children": null
		}]
	}, {
		"id": "220300",
		"name": "四平市",
		"parent": "220000",
		"children": [{
			"id": "220302",
			"name": "铁西区",
			"parent": "220300",
			"children": null
		}, {
			"id": "220303",
			"name": "铁东区",
			"parent": "220300",
			"children": null
		}, {
			"id": "220322",
			"name": "梨树县",
			"parent": "220300",
			"children": null
		}, {
			"id": "220323",
			"name": "伊通满族自治县",
			"parent": "220300",
			"children": null
		}, {
			"id": "220381",
			"name": "公主岭市",
			"parent": "220300",
			"children": null
		}, {
			"id": "220382",
			"name": "双辽市",
			"parent": "220300",
			"children": null
		}, {
			"id": "220383",
			"name": "其它区",
			"parent": "220300",
			"children": null
		}]
	}, {
		"id": "220400",
		"name": "辽源市",
		"parent": "220000",
		"children": [{
			"id": "220402",
			"name": "龙山区",
			"parent": "220400",
			"children": null
		}, {
			"id": "220403",
			"name": "西安区",
			"parent": "220400",
			"children": null
		}, {
			"id": "220421",
			"name": "东丰县",
			"parent": "220400",
			"children": null
		}, {
			"id": "220422",
			"name": "东辽县",
			"parent": "220400",
			"children": null
		}, {
			"id": "220423",
			"name": "其它区",
			"parent": "220400",
			"children": null
		}]
	}, {
		"id": "220500",
		"name": "通化市",
		"parent": "220000",
		"children": [{
			"id": "220502",
			"name": "东昌区",
			"parent": "220500",
			"children": null
		}, {
			"id": "220503",
			"name": "二道江区",
			"parent": "220500",
			"children": null
		}, {
			"id": "220521",
			"name": "通化县",
			"parent": "220500",
			"children": null
		}, {
			"id": "220523",
			"name": "辉南县",
			"parent": "220500",
			"children": null
		}, {
			"id": "220524",
			"name": "柳河县",
			"parent": "220500",
			"children": null
		}, {
			"id": "220581",
			"name": "梅河口市",
			"parent": "220500",
			"children": null
		}, {
			"id": "220582",
			"name": "集安市",
			"parent": "220500",
			"children": null
		}, {
			"id": "220583",
			"name": "其它区",
			"parent": "220500",
			"children": null
		}]
	}, {
		"id": "220600",
		"name": "白山市",
		"parent": "220000",
		"children": [{
			"id": "220602",
			"name": "浑江区",
			"parent": "220600",
			"children": null
		}, {
			"id": "220621",
			"name": "抚松县",
			"parent": "220600",
			"children": null
		}, {
			"id": "220622",
			"name": "靖宇县",
			"parent": "220600",
			"children": null
		}, {
			"id": "220623",
			"name": "长白朝鲜族自治县",
			"parent": "220600",
			"children": null
		}, {
			"id": "220625",
			"name": "江源区",
			"parent": "220600",
			"children": null
		}, {
			"id": "220681",
			"name": "临江市",
			"parent": "220600",
			"children": null
		}, {
			"id": "220682",
			"name": "其它区",
			"parent": "220600",
			"children": null
		}]
	}, {
		"id": "220700",
		"name": "松原市",
		"parent": "220000",
		"children": [{
			"id": "220702",
			"name": "宁江区",
			"parent": "220700",
			"children": null
		}, {
			"id": "220721",
			"name": "前郭尔罗斯蒙古族自治县",
			"parent": "220700",
			"children": null
		}, {
			"id": "220722",
			"name": "长岭县",
			"parent": "220700",
			"children": null
		}, {
			"id": "220723",
			"name": "乾安县",
			"parent": "220700",
			"children": null
		}, {
			"id": "220724",
			"name": "扶余市",
			"parent": "220700",
			"children": null
		}, {
			"id": "220725",
			"name": "其它区",
			"parent": "220700",
			"children": null
		}]
	}, {
		"id": "220800",
		"name": "白城市",
		"parent": "220000",
		"children": [{
			"id": "220802",
			"name": "洮北区",
			"parent": "220800",
			"children": null
		}, {
			"id": "220821",
			"name": "镇赉县",
			"parent": "220800",
			"children": null
		}, {
			"id": "220822",
			"name": "通榆县",
			"parent": "220800",
			"children": null
		}, {
			"id": "220881",
			"name": "洮南市",
			"parent": "220800",
			"children": null
		}, {
			"id": "220882",
			"name": "大安市",
			"parent": "220800",
			"children": null
		}, {
			"id": "220883",
			"name": "其它区",
			"parent": "220800",
			"children": null
		}]
	}, {
		"id": "222400",
		"name": "延边朝鲜族自治州",
		"parent": "220000",
		"children": [{
			"id": "222401",
			"name": "延吉市",
			"parent": "222400",
			"children": null
		}, {
			"id": "222402",
			"name": "图们市",
			"parent": "222400",
			"children": null
		}, {
			"id": "222403",
			"name": "敦化市",
			"parent": "222400",
			"children": null
		}, {
			"id": "222404",
			"name": "珲春市",
			"parent": "222400",
			"children": null
		}, {
			"id": "222405",
			"name": "龙井市",
			"parent": "222400",
			"children": null
		}, {
			"id": "222406",
			"name": "和龙市",
			"parent": "222400",
			"children": null
		}, {
			"id": "222424",
			"name": "汪清县",
			"parent": "222400",
			"children": null
		}, {
			"id": "222426",
			"name": "安图县",
			"parent": "222400",
			"children": null
		}, {
			"id": "222427",
			"name": "其它区",
			"parent": "222400",
			"children": null
		}]
	}]
}, {
	"id": "230000",
	"name": "黑龙江省",
	"children": [{
		"id": "230100",
		"name": "哈尔滨市",
		"parent": "230000",
		"children": [{
			"id": "230102",
			"name": "道里区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230103",
			"name": "南岗区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230104",
			"name": "道外区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230106",
			"name": "香坊区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230107",
			"name": "动力区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230108",
			"name": "平房区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230109",
			"name": "松北区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230111",
			"name": "呼兰区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230123",
			"name": "依兰县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230124",
			"name": "方正县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230125",
			"name": "宾县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230126",
			"name": "巴彦县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230127",
			"name": "木兰县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230128",
			"name": "通河县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230129",
			"name": "延寿县",
			"parent": "230100",
			"children": null
		}, {
			"id": "230181",
			"name": "阿城区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230182",
			"name": "双城区",
			"parent": "230100",
			"children": null
		}, {
			"id": "230183",
			"name": "尚志市",
			"parent": "230100",
			"children": null
		}, {
			"id": "230184",
			"name": "五常市",
			"parent": "230100",
			"children": null
		}, {
			"id": "230185",
			"name": "阿城市",
			"parent": "230100",
			"children": null
		}, {
			"id": "230186",
			"name": "其它区",
			"parent": "230100",
			"children": null
		}]
	}, {
		"id": "230200",
		"name": "齐齐哈尔市",
		"parent": "230000",
		"children": [{
			"id": "230202",
			"name": "龙沙区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230203",
			"name": "建华区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230204",
			"name": "铁锋区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230205",
			"name": "昂昂溪区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230206",
			"name": "富拉尔基区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230207",
			"name": "碾子山区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230208",
			"name": "梅里斯达斡尔族区",
			"parent": "230200",
			"children": null
		}, {
			"id": "230221",
			"name": "龙江县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230223",
			"name": "依安县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230224",
			"name": "泰来县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230225",
			"name": "甘南县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230227",
			"name": "富裕县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230229",
			"name": "克山县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230230",
			"name": "克东县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230231",
			"name": "拜泉县",
			"parent": "230200",
			"children": null
		}, {
			"id": "230281",
			"name": "讷河市",
			"parent": "230200",
			"children": null
		}, {
			"id": "230282",
			"name": "其它区",
			"parent": "230200",
			"children": null
		}]
	}, {
		"id": "230300",
		"name": "鸡西市",
		"parent": "230000",
		"children": [{
			"id": "230302",
			"name": "鸡冠区",
			"parent": "230300",
			"children": null
		}, {
			"id": "230303",
			"name": "恒山区",
			"parent": "230300",
			"children": null
		}, {
			"id": "230304",
			"name": "滴道区",
			"parent": "230300",
			"children": null
		}, {
			"id": "230305",
			"name": "梨树区",
			"parent": "230300",
			"children": null
		}, {
			"id": "230306",
			"name": "城子河区",
			"parent": "230300",
			"children": null
		}, {
			"id": "230307",
			"name": "麻山区",
			"parent": "230300",
			"children": null
		}, {
			"id": "230321",
			"name": "鸡东县",
			"parent": "230300",
			"children": null
		}, {
			"id": "230381",
			"name": "虎林市",
			"parent": "230300",
			"children": null
		}, {
			"id": "230382",
			"name": "密山市",
			"parent": "230300",
			"children": null
		}, {
			"id": "230383",
			"name": "其它区",
			"parent": "230300",
			"children": null
		}]
	}, {
		"id": "230400",
		"name": "鹤岗市",
		"parent": "230000",
		"children": [{
			"id": "230402",
			"name": "向阳区",
			"parent": "230400",
			"children": null
		}, {
			"id": "230403",
			"name": "工农区",
			"parent": "230400",
			"children": null
		}, {
			"id": "230404",
			"name": "南山区",
			"parent": "230400",
			"children": null
		}, {
			"id": "230405",
			"name": "兴安区",
			"parent": "230400",
			"children": null
		}, {
			"id": "230406",
			"name": "东山区",
			"parent": "230400",
			"children": null
		}, {
			"id": "230407",
			"name": "兴山区",
			"parent": "230400",
			"children": null
		}, {
			"id": "230421",
			"name": "萝北县",
			"parent": "230400",
			"children": null
		}, {
			"id": "230422",
			"name": "绥滨县",
			"parent": "230400",
			"children": null
		}, {
			"id": "230423",
			"name": "其它区",
			"parent": "230400",
			"children": null
		}]
	}, {
		"id": "230500",
		"name": "双鸭山市",
		"parent": "230000",
		"children": [{
			"id": "230502",
			"name": "尖山区",
			"parent": "230500",
			"children": null
		}, {
			"id": "230503",
			"name": "岭东区",
			"parent": "230500",
			"children": null
		}, {
			"id": "230505",
			"name": "四方台区",
			"parent": "230500",
			"children": null
		}, {
			"id": "230506",
			"name": "宝山区",
			"parent": "230500",
			"children": null
		}, {
			"id": "230521",
			"name": "集贤县",
			"parent": "230500",
			"children": null
		}, {
			"id": "230522",
			"name": "友谊县",
			"parent": "230500",
			"children": null
		}, {
			"id": "230523",
			"name": "宝清县",
			"parent": "230500",
			"children": null
		}, {
			"id": "230524",
			"name": "饶河县",
			"parent": "230500",
			"children": null
		}, {
			"id": "230525",
			"name": "其它区",
			"parent": "230500",
			"children": null
		}]
	}, {
		"id": "230600",
		"name": "大庆市",
		"parent": "230000",
		"children": [{
			"id": "230602",
			"name": "萨尔图区",
			"parent": "230600",
			"children": null
		}, {
			"id": "230603",
			"name": "龙凤区",
			"parent": "230600",
			"children": null
		}, {
			"id": "230604",
			"name": "让胡路区",
			"parent": "230600",
			"children": null
		}, {
			"id": "230605",
			"name": "红岗区",
			"parent": "230600",
			"children": null
		}, {
			"id": "230606",
			"name": "大同区",
			"parent": "230600",
			"children": null
		}, {
			"id": "230621",
			"name": "肇州县",
			"parent": "230600",
			"children": null
		}, {
			"id": "230622",
			"name": "肇源县",
			"parent": "230600",
			"children": null
		}, {
			"id": "230623",
			"name": "林甸县",
			"parent": "230600",
			"children": null
		}, {
			"id": "230624",
			"name": "杜尔伯特蒙古族自治县",
			"parent": "230600",
			"children": null
		}, {
			"id": "230625",
			"name": "其它区",
			"parent": "230600",
			"children": null
		}]
	}, {
		"id": "230700",
		"name": "伊春市",
		"parent": "230000",
		"children": [{
			"id": "230702",
			"name": "伊春区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230703",
			"name": "南岔区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230704",
			"name": "友好区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230705",
			"name": "西林区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230706",
			"name": "翠峦区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230707",
			"name": "新青区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230708",
			"name": "美溪区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230709",
			"name": "金山屯区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230710",
			"name": "五营区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230711",
			"name": "乌马河区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230712",
			"name": "汤旺河区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230713",
			"name": "带岭区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230714",
			"name": "乌伊岭区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230715",
			"name": "红星区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230716",
			"name": "上甘岭区",
			"parent": "230700",
			"children": null
		}, {
			"id": "230722",
			"name": "嘉荫县",
			"parent": "230700",
			"children": null
		}, {
			"id": "230781",
			"name": "铁力市",
			"parent": "230700",
			"children": null
		}, {
			"id": "230782",
			"name": "其它区",
			"parent": "230700",
			"children": null
		}]
	}, {
		"id": "230800",
		"name": "佳木斯市",
		"parent": "230000",
		"children": [{
			"id": "230802",
			"name": "永红区",
			"parent": "230800",
			"children": null
		}, {
			"id": "230803",
			"name": "向阳区",
			"parent": "230800",
			"children": null
		}, {
			"id": "230804",
			"name": "前进区",
			"parent": "230800",
			"children": null
		}, {
			"id": "230805",
			"name": "东风区",
			"parent": "230800",
			"children": null
		}, {
			"id": "230811",
			"name": "郊区",
			"parent": "230800",
			"children": null
		}, {
			"id": "230822",
			"name": "桦南县",
			"parent": "230800",
			"children": null
		}, {
			"id": "230826",
			"name": "桦川县",
			"parent": "230800",
			"children": null
		}, {
			"id": "230828",
			"name": "汤原县",
			"parent": "230800",
			"children": null
		}, {
			"id": "230833",
			"name": "抚远县",
			"parent": "230800",
			"children": null
		}, {
			"id": "230881",
			"name": "同江市",
			"parent": "230800",
			"children": null
		}, {
			"id": "230882",
			"name": "富锦市",
			"parent": "230800",
			"children": null
		}, {
			"id": "230883",
			"name": "其它区",
			"parent": "230800",
			"children": null
		}]
	}, {
		"id": "230900",
		"name": "七台河市",
		"parent": "230000",
		"children": [{
			"id": "230902",
			"name": "新兴区",
			"parent": "230900",
			"children": null
		}, {
			"id": "230903",
			"name": "桃山区",
			"parent": "230900",
			"children": null
		}, {
			"id": "230904",
			"name": "茄子河区",
			"parent": "230900",
			"children": null
		}, {
			"id": "230921",
			"name": "勃利县",
			"parent": "230900",
			"children": null
		}, {
			"id": "230922",
			"name": "其它区",
			"parent": "230900",
			"children": null
		}]
	}, {
		"id": "231000",
		"name": "牡丹江市",
		"parent": "230000",
		"children": [{
			"id": "231002",
			"name": "东安区",
			"parent": "231000",
			"children": null
		}, {
			"id": "231003",
			"name": "阳明区",
			"parent": "231000",
			"children": null
		}, {
			"id": "231004",
			"name": "爱民区",
			"parent": "231000",
			"children": null
		}, {
			"id": "231005",
			"name": "西安区",
			"parent": "231000",
			"children": null
		}, {
			"id": "231024",
			"name": "东宁县",
			"parent": "231000",
			"children": null
		}, {
			"id": "231025",
			"name": "林口县",
			"parent": "231000",
			"children": null
		}, {
			"id": "231081",
			"name": "绥芬河市",
			"parent": "231000",
			"children": null
		}, {
			"id": "231083",
			"name": "海林市",
			"parent": "231000",
			"children": null
		}, {
			"id": "231084",
			"name": "宁安市",
			"parent": "231000",
			"children": null
		}, {
			"id": "231085",
			"name": "穆棱市",
			"parent": "231000",
			"children": null
		}, {
			"id": "231086",
			"name": "其它区",
			"parent": "231000",
			"children": null
		}]
	}, {
		"id": "231100",
		"name": "黑河市",
		"parent": "230000",
		"children": [{
			"id": "231102",
			"name": "爱辉区",
			"parent": "231100",
			"children": null
		}, {
			"id": "231121",
			"name": "嫩江县",
			"parent": "231100",
			"children": null
		}, {
			"id": "231123",
			"name": "逊克县",
			"parent": "231100",
			"children": null
		}, {
			"id": "231124",
			"name": "孙吴县",
			"parent": "231100",
			"children": null
		}, {
			"id": "231181",
			"name": "北安市",
			"parent": "231100",
			"children": null
		}, {
			"id": "231182",
			"name": "五大连池市",
			"parent": "231100",
			"children": null
		}, {
			"id": "231183",
			"name": "其它区",
			"parent": "231100",
			"children": null
		}]
	}, {
		"id": "231200",
		"name": "绥化市",
		"parent": "230000",
		"children": [{
			"id": "231202",
			"name": "北林区",
			"parent": "231200",
			"children": null
		}, {
			"id": "231221",
			"name": "望奎县",
			"parent": "231200",
			"children": null
		}, {
			"id": "231222",
			"name": "兰西县",
			"parent": "231200",
			"children": null
		}, {
			"id": "231223",
			"name": "青冈县",
			"parent": "231200",
			"children": null
		}, {
			"id": "231224",
			"name": "庆安县",
			"parent": "231200",
			"children": null
		}, {
			"id": "231225",
			"name": "明水县",
			"parent": "231200",
			"children": null
		}, {
			"id": "231226",
			"name": "绥棱县",
			"parent": "231200",
			"children": null
		}, {
			"id": "231281",
			"name": "安达市",
			"parent": "231200",
			"children": null
		}, {
			"id": "231282",
			"name": "肇东市",
			"parent": "231200",
			"children": null
		}, {
			"id": "231283",
			"name": "海伦市",
			"parent": "231200",
			"children": null
		}, {
			"id": "231284",
			"name": "其它区",
			"parent": "231200",
			"children": null
		}]
	}, {
		"id": "232700",
		"name": "大兴安岭地区",
		"parent": "230000",
		"children": [{
			"id": "232702",
			"name": "松岭区",
			"parent": "232700",
			"children": null
		}, {
			"id": "232703",
			"name": "新林区",
			"parent": "232700",
			"children": null
		}, {
			"id": "232704",
			"name": "呼中区",
			"parent": "232700",
			"children": null
		}, {
			"id": "232721",
			"name": "呼玛县",
			"parent": "232700",
			"children": null
		}, {
			"id": "232722",
			"name": "塔河县",
			"parent": "232700",
			"children": null
		}, {
			"id": "232723",
			"name": "漠河县",
			"parent": "232700",
			"children": null
		}, {
			"id": "232724",
			"name": "加格达奇区",
			"parent": "232700",
			"children": null
		}, {
			"id": "232725",
			"name": "其它区",
			"parent": "232700",
			"children": null
		}]
	}]
}, {
	"id": "310000",
	"name": "上海市",
	"children": [{
		"id": "310100",
		"name": "上海市",
		"parent": "310000",
		"children": [{
			"id": "310101",
			"name": "黄浦区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310103",
			"name": "卢湾区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310104",
			"name": "徐汇区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310105",
			"name": "长宁区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310106",
			"name": "静安区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310107",
			"name": "普陀区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310108",
			"name": "闸北区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310109",
			"name": "虹口区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310110",
			"name": "杨浦区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310112",
			"name": "闵行区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310113",
			"name": "宝山区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310114",
			"name": "嘉定区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310115",
			"name": "浦东新区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310116",
			"name": "金山区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310117",
			"name": "松江区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310118",
			"name": "青浦区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310119",
			"name": "南汇区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310120",
			"name": "奉贤区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310152",
			"name": "川沙区",
			"parent": "310100",
			"children": null
		}, {
			"id": "310230",
			"name": "崇明县",
			"parent": "310100",
			"children": null
		}, {
			"id": "310231",
			"name": "其它区",
			"parent": "310100",
			"children": null
		}]
	}]
}, {
	"id": "320000",
	"name": "江苏省",
	"children": [{
		"id": "320100",
		"name": "南京市",
		"parent": "320000",
		"children": [{
			"id": "320102",
			"name": "玄武区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320103",
			"name": "白下区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320104",
			"name": "秦淮区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320105",
			"name": "建邺区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320106",
			"name": "鼓楼区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320107",
			"name": "下关区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320111",
			"name": "浦口区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320113",
			"name": "栖霞区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320114",
			"name": "雨花台区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320115",
			"name": "江宁区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320116",
			"name": "六合区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320124",
			"name": "溧水区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320125",
			"name": "高淳区",
			"parent": "320100",
			"children": null
		}, {
			"id": "320126",
			"name": "其它区",
			"parent": "320100",
			"children": null
		}]
	}, {
		"id": "320200",
		"name": "无锡市",
		"parent": "320000",
		"children": [{
			"id": "320202",
			"name": "崇安区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320203",
			"name": "南长区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320204",
			"name": "北塘区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320205",
			"name": "锡山区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320206",
			"name": "惠山区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320211",
			"name": "滨湖区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320213",
			"name": "梁溪区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320214",
			"name": "新吴区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320281",
			"name": "江阴市",
			"parent": "320200",
			"children": null
		}, {
			"id": "320282",
			"name": "宜兴市",
			"parent": "320200",
			"children": null
		}, {
			"id": "320296",
			"name": "新区",
			"parent": "320200",
			"children": null
		}, {
			"id": "320297",
			"name": "其它区",
			"parent": "320200",
			"children": null
		}]
	}, {
		"id": "320300",
		"name": "徐州市",
		"parent": "320000",
		"children": [{
			"id": "320302",
			"name": "鼓楼区",
			"parent": "320300",
			"children": null
		}, {
			"id": "320303",
			"name": "云龙区",
			"parent": "320300",
			"children": null
		}, {
			"id": "320304",
			"name": "九里区",
			"parent": "320300",
			"children": null
		}, {
			"id": "320305",
			"name": "贾汪区",
			"parent": "320300",
			"children": null
		}, {
			"id": "320311",
			"name": "泉山区",
			"parent": "320300",
			"children": null
		}, {
			"id": "320321",
			"name": "丰县",
			"parent": "320300",
			"children": null
		}, {
			"id": "320322",
			"name": "沛县",
			"parent": "320300",
			"children": null
		}, {
			"id": "320323",
			"name": "铜山区",
			"parent": "320300",
			"children": null
		}, {
			"id": "320324",
			"name": "睢宁县",
			"parent": "320300",
			"children": null
		}, {
			"id": "320381",
			"name": "新沂市",
			"parent": "320300",
			"children": null
		}, {
			"id": "320382",
			"name": "邳州市",
			"parent": "320300",
			"children": null
		}, {
			"id": "320383",
			"name": "其它区",
			"parent": "320300",
			"children": null
		}]
	}, {
		"id": "320400",
		"name": "常州市",
		"parent": "320000",
		"children": [{
			"id": "320402",
			"name": "天宁区",
			"parent": "320400",
			"children": null
		}, {
			"id": "320404",
			"name": "钟楼区",
			"parent": "320400",
			"children": null
		}, {
			"id": "320405",
			"name": "戚墅堰区",
			"parent": "320400",
			"children": null
		}, {
			"id": "320411",
			"name": "新北区",
			"parent": "320400",
			"children": null
		}, {
			"id": "320412",
			"name": "武进区",
			"parent": "320400",
			"children": null
		}, {
			"id": "320481",
			"name": "溧阳市",
			"parent": "320400",
			"children": null
		}, {
			"id": "320482",
			"name": "金坛市",
			"parent": "320400",
			"children": null
		}, {
			"id": "320483",
			"name": "其它区",
			"parent": "320400",
			"children": null
		}]
	}, {
		"id": "320500",
		"name": "苏州市",
		"parent": "320000",
		"children": [{
			"id": "320502",
			"name": "沧浪区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320503",
			"name": "平江区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320504",
			"name": "金阊区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320505",
			"name": "虎丘区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320506",
			"name": "吴中区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320507",
			"name": "相城区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320508",
			"name": "姑苏区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320581",
			"name": "常熟市",
			"parent": "320500",
			"children": null
		}, {
			"id": "320582",
			"name": "张家港市",
			"parent": "320500",
			"children": null
		}, {
			"id": "320583",
			"name": "昆山市",
			"parent": "320500",
			"children": null
		}, {
			"id": "320584",
			"name": "吴江区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320585",
			"name": "太仓市",
			"parent": "320500",
			"children": null
		}, {
			"id": "320594",
			"name": "新区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320595",
			"name": "园区",
			"parent": "320500",
			"children": null
		}, {
			"id": "320596",
			"name": "其它区",
			"parent": "320500",
			"children": null
		}]
	}, {
		"id": "320600",
		"name": "南通市",
		"parent": "320000",
		"children": [{
			"id": "320602",
			"name": "崇川区",
			"parent": "320600",
			"children": null
		}, {
			"id": "320611",
			"name": "港闸区",
			"parent": "320600",
			"children": null
		}, {
			"id": "320612",
			"name": "通州区",
			"parent": "320600",
			"children": null
		}, {
			"id": "320621",
			"name": "海安县",
			"parent": "320600",
			"children": null
		}, {
			"id": "320623",
			"name": "如东县",
			"parent": "320600",
			"children": null
		}, {
			"id": "320681",
			"name": "启东市",
			"parent": "320600",
			"children": null
		}, {
			"id": "320682",
			"name": "如皋市",
			"parent": "320600",
			"children": null
		}, {
			"id": "320683",
			"name": "通州市",
			"parent": "320600",
			"children": null
		}, {
			"id": "320684",
			"name": "海门市",
			"parent": "320600",
			"children": null
		}, {
			"id": "320693",
			"name": "开发区",
			"parent": "320600",
			"children": null
		}, {
			"id": "320694",
			"name": "其它区",
			"parent": "320600",
			"children": null
		}]
	}, {
		"id": "320700",
		"name": "连云港市",
		"parent": "320000",
		"children": [{
			"id": "320703",
			"name": "连云区",
			"parent": "320700",
			"children": null
		}, {
			"id": "320705",
			"name": "新浦区",
			"parent": "320700",
			"children": null
		}, {
			"id": "320706",
			"name": "海州区",
			"parent": "320700",
			"children": null
		}, {
			"id": "320721",
			"name": "赣榆区",
			"parent": "320700",
			"children": null
		}, {
			"id": "320722",
			"name": "东海县",
			"parent": "320700",
			"children": null
		}, {
			"id": "320723",
			"name": "灌云县",
			"parent": "320700",
			"children": null
		}, {
			"id": "320724",
			"name": "灌南县",
			"parent": "320700",
			"children": null
		}, {
			"id": "320725",
			"name": "其它区",
			"parent": "320700",
			"children": null
		}]
	}, {
		"id": "320800",
		"name": "淮安市",
		"parent": "320000",
		"children": [{
			"id": "320802",
			"name": "清河区",
			"parent": "320800",
			"children": null
		}, {
			"id": "320803",
			"name": "淮安区",
			"parent": "320800",
			"children": null
		}, {
			"id": "320804",
			"name": "淮阴区",
			"parent": "320800",
			"children": null
		}, {
			"id": "320811",
			"name": "清浦区",
			"parent": "320800",
			"children": null
		}, {
			"id": "320826",
			"name": "涟水县",
			"parent": "320800",
			"children": null
		}, {
			"id": "320829",
			"name": "洪泽县",
			"parent": "320800",
			"children": null
		}, {
			"id": "320830",
			"name": "盱眙县",
			"parent": "320800",
			"children": null
		}, {
			"id": "320831",
			"name": "金湖县",
			"parent": "320800",
			"children": null
		}, {
			"id": "320832",
			"name": "其它区",
			"parent": "320800",
			"children": null
		}]
	}, {
		"id": "320900",
		"name": "盐城市",
		"parent": "320000",
		"children": [{
			"id": "320902",
			"name": "亭湖区",
			"parent": "320900",
			"children": null
		}, {
			"id": "320903",
			"name": "盐都区",
			"parent": "320900",
			"children": null
		}, {
			"id": "320921",
			"name": "响水县",
			"parent": "320900",
			"children": null
		}, {
			"id": "320922",
			"name": "滨海县",
			"parent": "320900",
			"children": null
		}, {
			"id": "320923",
			"name": "阜宁县",
			"parent": "320900",
			"children": null
		}, {
			"id": "320924",
			"name": "射阳县",
			"parent": "320900",
			"children": null
		}, {
			"id": "320925",
			"name": "建湖县",
			"parent": "320900",
			"children": null
		}, {
			"id": "320981",
			"name": "东台市",
			"parent": "320900",
			"children": null
		}, {
			"id": "320982",
			"name": "大丰市",
			"parent": "320900",
			"children": null
		}, {
			"id": "320983",
			"name": "其它区",
			"parent": "320900",
			"children": null
		}]
	}, {
		"id": "321000",
		"name": "扬州市",
		"parent": "320000",
		"children": [{
			"id": "321002",
			"name": "广陵区",
			"parent": "321000",
			"children": null
		}, {
			"id": "321003",
			"name": "邗江区",
			"parent": "321000",
			"children": null
		}, {
			"id": "321011",
			"name": "维扬区",
			"parent": "321000",
			"children": null
		}, {
			"id": "321023",
			"name": "宝应县",
			"parent": "321000",
			"children": null
		}, {
			"id": "321081",
			"name": "仪征市",
			"parent": "321000",
			"children": null
		}, {
			"id": "321084",
			"name": "高邮市",
			"parent": "321000",
			"children": null
		}, {
			"id": "321088",
			"name": "江都区",
			"parent": "321000",
			"children": null
		}, {
			"id": "321092",
			"name": "经济开发区",
			"parent": "321000",
			"children": null
		}, {
			"id": "321093",
			"name": "其它区",
			"parent": "321000",
			"children": null
		}]
	}, {
		"id": "321100",
		"name": "镇江市",
		"parent": "320000",
		"children": [{
			"id": "321102",
			"name": "京口区",
			"parent": "321100",
			"children": null
		}, {
			"id": "321111",
			"name": "润州区",
			"parent": "321100",
			"children": null
		}, {
			"id": "321112",
			"name": "丹徒区",
			"parent": "321100",
			"children": null
		}, {
			"id": "321181",
			"name": "丹阳市",
			"parent": "321100",
			"children": null
		}, {
			"id": "321182",
			"name": "扬中市",
			"parent": "321100",
			"children": null
		}, {
			"id": "321183",
			"name": "句容市",
			"parent": "321100",
			"children": null
		}, {
			"id": "321184",
			"name": "其它区",
			"parent": "321100",
			"children": null
		}]
	}, {
		"id": "321200",
		"name": "泰州市",
		"parent": "320000",
		"children": [{
			"id": "321202",
			"name": "海陵区",
			"parent": "321200",
			"children": null
		}, {
			"id": "321203",
			"name": "高港区",
			"parent": "321200",
			"children": null
		}, {
			"id": "321281",
			"name": "兴化市",
			"parent": "321200",
			"children": null
		}, {
			"id": "321282",
			"name": "靖江市",
			"parent": "321200",
			"children": null
		}, {
			"id": "321283",
			"name": "泰兴市",
			"parent": "321200",
			"children": null
		}, {
			"id": "321284",
			"name": "姜堰区",
			"parent": "321200",
			"children": null
		}, {
			"id": "321285",
			"name": "其它区",
			"parent": "321200",
			"children": null
		}]
	}, {
		"id": "321300",
		"name": "宿迁市",
		"parent": "320000",
		"children": [{
			"id": "321302",
			"name": "宿城区",
			"parent": "321300",
			"children": null
		}, {
			"id": "321311",
			"name": "宿豫区",
			"parent": "321300",
			"children": null
		}, {
			"id": "321322",
			"name": "沭阳县",
			"parent": "321300",
			"children": null
		}, {
			"id": "321323",
			"name": "泗阳县",
			"parent": "321300",
			"children": null
		}, {
			"id": "321324",
			"name": "泗洪县",
			"parent": "321300",
			"children": null
		}, {
			"id": "321325",
			"name": "其它区",
			"parent": "321300",
			"children": null
		}]
	}]
}, {
	"id": "330000",
	"name": "浙江省",
	"children": [{
		"id": "330100",
		"name": "杭州市",
		"parent": "330000",
		"children": [{
			"id": "330102",
			"name": "上城区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330103",
			"name": "下城区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330104",
			"name": "江干区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330105",
			"name": "拱墅区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330106",
			"name": "西湖区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330108",
			"name": "滨江区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330109",
			"name": "萧山区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330110",
			"name": "余杭区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330122",
			"name": "桐庐县",
			"parent": "330100",
			"children": null
		}, {
			"id": "330127",
			"name": "淳安县",
			"parent": "330100",
			"children": null
		}, {
			"id": "330182",
			"name": "建德市",
			"parent": "330100",
			"children": null
		}, {
			"id": "330183",
			"name": "富阳区",
			"parent": "330100",
			"children": null
		}, {
			"id": "330185",
			"name": "临安市",
			"parent": "330100",
			"children": null
		}, {
			"id": "330186",
			"name": "其它区",
			"parent": "330100",
			"children": null
		}]
	}, {
		"id": "330200",
		"name": "宁波市",
		"parent": "330000",
		"children": [{
			"id": "330203",
			"name": "海曙区",
			"parent": "330200",
			"children": null
		}, {
			"id": "330204",
			"name": "江东区",
			"parent": "330200",
			"children": null
		}, {
			"id": "330205",
			"name": "江北区",
			"parent": "330200",
			"children": null
		}, {
			"id": "330206",
			"name": "北仑区",
			"parent": "330200",
			"children": null
		}, {
			"id": "330211",
			"name": "镇海区",
			"parent": "330200",
			"children": null
		}, {
			"id": "330212",
			"name": "鄞州区",
			"parent": "330200",
			"children": null
		}, {
			"id": "330225",
			"name": "象山县",
			"parent": "330200",
			"children": null
		}, {
			"id": "330226",
			"name": "宁海县",
			"parent": "330200",
			"children": null
		}, {
			"id": "330281",
			"name": "余姚市",
			"parent": "330200",
			"children": null
		}, {
			"id": "330282",
			"name": "慈溪市",
			"parent": "330200",
			"children": null
		}, {
			"id": "330283",
			"name": "奉化市",
			"parent": "330200",
			"children": null
		}, {
			"id": "330284",
			"name": "其它区",
			"parent": "330200",
			"children": null
		}]
	}, {
		"id": "330300",
		"name": "温州市",
		"parent": "330000",
		"children": [{
			"id": "330302",
			"name": "鹿城区",
			"parent": "330300",
			"children": null
		}, {
			"id": "330303",
			"name": "龙湾区",
			"parent": "330300",
			"children": null
		}, {
			"id": "330304",
			"name": "瓯海区",
			"parent": "330300",
			"children": null
		}, {
			"id": "330322",
			"name": "洞头县",
			"parent": "330300",
			"children": null
		}, {
			"id": "330324",
			"name": "永嘉县",
			"parent": "330300",
			"children": null
		}, {
			"id": "330326",
			"name": "平阳县",
			"parent": "330300",
			"children": null
		}, {
			"id": "330327",
			"name": "苍南县",
			"parent": "330300",
			"children": null
		}, {
			"id": "330328",
			"name": "文成县",
			"parent": "330300",
			"children": null
		}, {
			"id": "330329",
			"name": "泰顺县",
			"parent": "330300",
			"children": null
		}, {
			"id": "330381",
			"name": "瑞安市",
			"parent": "330300",
			"children": null
		}, {
			"id": "330382",
			"name": "乐清市",
			"parent": "330300",
			"children": null
		}, {
			"id": "330383",
			"name": "其它区",
			"parent": "330300",
			"children": null
		}]
	}, {
		"id": "330400",
		"name": "嘉兴市",
		"parent": "330000",
		"children": [{
			"id": "330402",
			"name": "南湖区",
			"parent": "330400",
			"children": null
		}, {
			"id": "330411",
			"name": "秀洲区",
			"parent": "330400",
			"children": null
		}, {
			"id": "330421",
			"name": "嘉善县",
			"parent": "330400",
			"children": null
		}, {
			"id": "330424",
			"name": "海盐县",
			"parent": "330400",
			"children": null
		}, {
			"id": "330481",
			"name": "海宁市",
			"parent": "330400",
			"children": null
		}, {
			"id": "330482",
			"name": "平湖市",
			"parent": "330400",
			"children": null
		}, {
			"id": "330483",
			"name": "桐乡市",
			"parent": "330400",
			"children": null
		}, {
			"id": "330484",
			"name": "其它区",
			"parent": "330400",
			"children": null
		}]
	}, {
		"id": "330500",
		"name": "湖州市",
		"parent": "330000",
		"children": [{
			"id": "330502",
			"name": "吴兴区",
			"parent": "330500",
			"children": null
		}, {
			"id": "330503",
			"name": "南浔区",
			"parent": "330500",
			"children": null
		}, {
			"id": "330521",
			"name": "德清县",
			"parent": "330500",
			"children": null
		}, {
			"id": "330522",
			"name": "长兴县",
			"parent": "330500",
			"children": null
		}, {
			"id": "330523",
			"name": "安吉县",
			"parent": "330500",
			"children": null
		}, {
			"id": "330524",
			"name": "其它区",
			"parent": "330500",
			"children": null
		}]
	}, {
		"id": "330600",
		"name": "绍兴市",
		"parent": "330000",
		"children": [{
			"id": "330602",
			"name": "越城区",
			"parent": "330600",
			"children": null
		}, {
			"id": "330621",
			"name": "柯桥区",
			"parent": "330600",
			"children": null
		}, {
			"id": "330624",
			"name": "新昌县",
			"parent": "330600",
			"children": null
		}, {
			"id": "330681",
			"name": "诸暨市",
			"parent": "330600",
			"children": null
		}, {
			"id": "330682",
			"name": "上虞区",
			"parent": "330600",
			"children": null
		}, {
			"id": "330683",
			"name": "嵊州市",
			"parent": "330600",
			"children": null
		}, {
			"id": "330684",
			"name": "其它区",
			"parent": "330600",
			"children": null
		}]
	}, {
		"id": "330700",
		"name": "金华市",
		"parent": "330000",
		"children": [{
			"id": "330702",
			"name": "婺城区",
			"parent": "330700",
			"children": null
		}, {
			"id": "330703",
			"name": "金东区",
			"parent": "330700",
			"children": null
		}, {
			"id": "330723",
			"name": "武义县",
			"parent": "330700",
			"children": null
		}, {
			"id": "330726",
			"name": "浦江县",
			"parent": "330700",
			"children": null
		}, {
			"id": "330727",
			"name": "磐安县",
			"parent": "330700",
			"children": null
		}, {
			"id": "330781",
			"name": "兰溪市",
			"parent": "330700",
			"children": null
		}, {
			"id": "330782",
			"name": "义乌市",
			"parent": "330700",
			"children": null
		}, {
			"id": "330783",
			"name": "东阳市",
			"parent": "330700",
			"children": null
		}, {
			"id": "330784",
			"name": "永康市",
			"parent": "330700",
			"children": null
		}, {
			"id": "330785",
			"name": "其它区",
			"parent": "330700",
			"children": null
		}]
	}, {
		"id": "330800",
		"name": "衢州市",
		"parent": "330000",
		"children": [{
			"id": "330802",
			"name": "柯城区",
			"parent": "330800",
			"children": null
		}, {
			"id": "330803",
			"name": "衢江区",
			"parent": "330800",
			"children": null
		}, {
			"id": "330822",
			"name": "常山县",
			"parent": "330800",
			"children": null
		}, {
			"id": "330824",
			"name": "开化县",
			"parent": "330800",
			"children": null
		}, {
			"id": "330825",
			"name": "龙游县",
			"parent": "330800",
			"children": null
		}, {
			"id": "330881",
			"name": "江山市",
			"parent": "330800",
			"children": null
		}, {
			"id": "330882",
			"name": "其它区",
			"parent": "330800",
			"children": null
		}]
	}, {
		"id": "330900",
		"name": "舟山市",
		"parent": "330000",
		"children": [{
			"id": "330902",
			"name": "定海区",
			"parent": "330900",
			"children": null
		}, {
			"id": "330903",
			"name": "普陀区",
			"parent": "330900",
			"children": null
		}, {
			"id": "330921",
			"name": "岱山县",
			"parent": "330900",
			"children": null
		}, {
			"id": "330922",
			"name": "嵊泗县",
			"parent": "330900",
			"children": null
		}, {
			"id": "330923",
			"name": "其它区",
			"parent": "330900",
			"children": null
		}]
	}, {
		"id": "331000",
		"name": "台州市",
		"parent": "330000",
		"children": [{
			"id": "331002",
			"name": "椒江区",
			"parent": "331000",
			"children": null
		}, {
			"id": "331003",
			"name": "黄岩区",
			"parent": "331000",
			"children": null
		}, {
			"id": "331004",
			"name": "路桥区",
			"parent": "331000",
			"children": null
		}, {
			"id": "331021",
			"name": "玉环县",
			"parent": "331000",
			"children": null
		}, {
			"id": "331022",
			"name": "三门县",
			"parent": "331000",
			"children": null
		}, {
			"id": "331023",
			"name": "天台县",
			"parent": "331000",
			"children": null
		}, {
			"id": "331024",
			"name": "仙居县",
			"parent": "331000",
			"children": null
		}, {
			"id": "331081",
			"name": "温岭市",
			"parent": "331000",
			"children": null
		}, {
			"id": "331082",
			"name": "临海市",
			"parent": "331000",
			"children": null
		}, {
			"id": "331083",
			"name": "其它区",
			"parent": "331000",
			"children": null
		}]
	}, {
		"id": "331100",
		"name": "丽水市",
		"parent": "330000",
		"children": [{
			"id": "331102",
			"name": "莲都区",
			"parent": "331100",
			"children": null
		}, {
			"id": "331121",
			"name": "青田县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331122",
			"name": "缙云县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331123",
			"name": "遂昌县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331124",
			"name": "松阳县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331125",
			"name": "云和县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331126",
			"name": "庆元县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331127",
			"name": "景宁畲族自治县",
			"parent": "331100",
			"children": null
		}, {
			"id": "331181",
			"name": "龙泉市",
			"parent": "331100",
			"children": null
		}, {
			"id": "331182",
			"name": "其它区",
			"parent": "331100",
			"children": null
		}]
	}]
}, {
	"id": "340000",
	"name": "安徽省",
	"children": [{
		"id": "340100",
		"name": "合肥市",
		"parent": "340000",
		"children": [{
			"id": "340102",
			"name": "瑶海区",
			"parent": "340100",
			"children": null
		}, {
			"id": "340103",
			"name": "庐阳区",
			"parent": "340100",
			"children": null
		}, {
			"id": "340104",
			"name": "蜀山区",
			"parent": "340100",
			"children": null
		}, {
			"id": "340111",
			"name": "包河区",
			"parent": "340100",
			"children": null
		}, {
			"id": "340121",
			"name": "长丰县",
			"parent": "340100",
			"children": null
		}, {
			"id": "340122",
			"name": "肥东县",
			"parent": "340100",
			"children": null
		}, {
			"id": "340123",
			"name": "肥西县",
			"parent": "340100",
			"children": null
		}, {
			"id": "340151",
			"name": "高新区",
			"parent": "340100",
			"children": null
		}, {
			"id": "340191",
			"name": "中区",
			"parent": "340100",
			"children": null
		}, {
			"id": "340192",
			"name": "其它区",
			"parent": "340100",
			"children": null
		}, {
			"id": "341400",
			"name": "巢湖市",
			"parent": "340100",
			"children": null
		}, {
			"id": "341402",
			"name": "居巢区",
			"parent": "340100",
			"children": null
		}, {
			"id": "341421",
			"name": "庐江县",
			"parent": "340100",
			"children": null
		}]
	}, {
		"id": "340200",
		"name": "芜湖市",
		"parent": "340000",
		"children": [{
			"id": "340202",
			"name": "镜湖区",
			"parent": "340200",
			"children": null
		}, {
			"id": "340203",
			"name": "弋江区",
			"parent": "340200",
			"children": null
		}, {
			"id": "340207",
			"name": "鸠江区",
			"parent": "340200",
			"children": null
		}, {
			"id": "340208",
			"name": "三山区",
			"parent": "340200",
			"children": null
		}, {
			"id": "340221",
			"name": "芜湖县",
			"parent": "340200",
			"children": null
		}, {
			"id": "340222",
			"name": "繁昌县",
			"parent": "340200",
			"children": null
		}, {
			"id": "340223",
			"name": "南陵县",
			"parent": "340200",
			"children": null
		}, {
			"id": "340224",
			"name": "其它区",
			"parent": "340200",
			"children": null
		}, {
			"id": "341422",
			"name": "无为县",
			"parent": "340200",
			"children": null
		}]
	}, {
		"id": "340300",
		"name": "蚌埠市",
		"parent": "340000",
		"children": [{
			"id": "340302",
			"name": "龙子湖区",
			"parent": "340300",
			"children": null
		}, {
			"id": "340303",
			"name": "蚌山区",
			"parent": "340300",
			"children": null
		}, {
			"id": "340304",
			"name": "禹会区",
			"parent": "340300",
			"children": null
		}, {
			"id": "340311",
			"name": "淮上区",
			"parent": "340300",
			"children": null
		}, {
			"id": "340321",
			"name": "怀远县",
			"parent": "340300",
			"children": null
		}, {
			"id": "340322",
			"name": "五河县",
			"parent": "340300",
			"children": null
		}, {
			"id": "340323",
			"name": "固镇县",
			"parent": "340300",
			"children": null
		}, {
			"id": "340324",
			"name": "其它区",
			"parent": "340300",
			"children": null
		}]
	}, {
		"id": "340400",
		"name": "淮南市",
		"parent": "340000",
		"children": [{
			"id": "340402",
			"name": "大通区",
			"parent": "340400",
			"children": null
		}, {
			"id": "340403",
			"name": "田家庵区",
			"parent": "340400",
			"children": null
		}, {
			"id": "340404",
			"name": "谢家集区",
			"parent": "340400",
			"children": null
		}, {
			"id": "340405",
			"name": "八公山区",
			"parent": "340400",
			"children": null
		}, {
			"id": "340406",
			"name": "潘集区",
			"parent": "340400",
			"children": null
		}, {
			"id": "340421",
			"name": "凤台县",
			"parent": "340400",
			"children": null
		}, {
			"id": "340422",
			"name": "其它区",
			"parent": "340400",
			"children": null
		}, {
			"id": "340499",
			"name": "寿县",
			"parent": "340400",
			"children": null
		}]
	}, {
		"id": "340500",
		"name": "马鞍山市",
		"parent": "340000",
		"children": [{
			"id": "340502",
			"name": "金家庄区",
			"parent": "340500",
			"children": null
		}, {
			"id": "340503",
			"name": "花山区",
			"parent": "340500",
			"children": null
		}, {
			"id": "340504",
			"name": "雨山区",
			"parent": "340500",
			"children": null
		}, {
			"id": "340506",
			"name": "博望区",
			"parent": "340500",
			"children": null
		}, {
			"id": "340521",
			"name": "当涂县",
			"parent": "340500",
			"children": null
		}, {
			"id": "340522",
			"name": "其它区",
			"parent": "340500",
			"children": null
		}, {
			"id": "341423",
			"name": "含山县",
			"parent": "340500",
			"children": null
		}, {
			"id": "341424",
			"name": "和县",
			"parent": "340500",
			"children": null
		}]
	}, {
		"id": "340600",
		"name": "淮北市",
		"parent": "340000",
		"children": [{
			"id": "340602",
			"name": "杜集区",
			"parent": "340600",
			"children": null
		}, {
			"id": "340603",
			"name": "相山区",
			"parent": "340600",
			"children": null
		}, {
			"id": "340604",
			"name": "烈山区",
			"parent": "340600",
			"children": null
		}, {
			"id": "340621",
			"name": "濉溪县",
			"parent": "340600",
			"children": null
		}, {
			"id": "340622",
			"name": "其它区",
			"parent": "340600",
			"children": null
		}]
	}, {
		"id": "340700",
		"name": "铜陵市",
		"parent": "340000",
		"children": [{
			"id": "340702",
			"name": "铜官山区",
			"parent": "340700",
			"children": null
		}, {
			"id": "340703",
			"name": "狮子山区",
			"parent": "340700",
			"children": null
		}, {
			"id": "340705",
			"name": "铜官区",
			"parent": "340700",
			"children": null
		}, {
			"id": "340711",
			"name": "郊区",
			"parent": "340700",
			"children": null
		}, {
			"id": "340721",
			"name": "铜陵县",
			"parent": "340700",
			"children": null
		}, {
			"id": "340722",
			"name": "其它区",
			"parent": "340700",
			"children": null
		}, {
			"id": "340799",
			"name": "枞阳县",
			"parent": "340700",
			"children": null
		}]
	}, {
		"id": "340800",
		"name": "安庆市",
		"parent": "340000",
		"children": [{
			"id": "340802",
			"name": "迎江区",
			"parent": "340800",
			"children": null
		}, {
			"id": "340803",
			"name": "大观区",
			"parent": "340800",
			"children": null
		}, {
			"id": "340811",
			"name": "宜秀区",
			"parent": "340800",
			"children": null
		}, {
			"id": "340822",
			"name": "怀宁县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340823",
			"name": "枞阳县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340824",
			"name": "潜山县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340825",
			"name": "太湖县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340826",
			"name": "宿松县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340827",
			"name": "望江县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340828",
			"name": "岳西县",
			"parent": "340800",
			"children": null
		}, {
			"id": "340881",
			"name": "桐城市",
			"parent": "340800",
			"children": null
		}, {
			"id": "340882",
			"name": "其它区",
			"parent": "340800",
			"children": null
		}]
	}, {
		"id": "341000",
		"name": "黄山市",
		"parent": "340000",
		"children": [{
			"id": "341002",
			"name": "屯溪区",
			"parent": "341000",
			"children": null
		}, {
			"id": "341003",
			"name": "黄山区",
			"parent": "341000",
			"children": null
		}, {
			"id": "341004",
			"name": "徽州区",
			"parent": "341000",
			"children": null
		}, {
			"id": "341021",
			"name": "歙县",
			"parent": "341000",
			"children": null
		}, {
			"id": "341022",
			"name": "休宁县",
			"parent": "341000",
			"children": null
		}, {
			"id": "341023",
			"name": "黟县",
			"parent": "341000",
			"children": null
		}, {
			"id": "341024",
			"name": "祁门县",
			"parent": "341000",
			"children": null
		}, {
			"id": "341025",
			"name": "其它区",
			"parent": "341000",
			"children": null
		}]
	}, {
		"id": "341100",
		"name": "滁州市",
		"parent": "340000",
		"children": [{
			"id": "341102",
			"name": "琅琊区",
			"parent": "341100",
			"children": null
		}, {
			"id": "341103",
			"name": "南谯区",
			"parent": "341100",
			"children": null
		}, {
			"id": "341122",
			"name": "来安县",
			"parent": "341100",
			"children": null
		}, {
			"id": "341124",
			"name": "全椒县",
			"parent": "341100",
			"children": null
		}, {
			"id": "341125",
			"name": "定远县",
			"parent": "341100",
			"children": null
		}, {
			"id": "341126",
			"name": "凤阳县",
			"parent": "341100",
			"children": null
		}, {
			"id": "341181",
			"name": "天长市",
			"parent": "341100",
			"children": null
		}, {
			"id": "341182",
			"name": "明光市",
			"parent": "341100",
			"children": null
		}, {
			"id": "341183",
			"name": "其它区",
			"parent": "341100",
			"children": null
		}]
	}, {
		"id": "341200",
		"name": "阜阳市",
		"parent": "340000",
		"children": [{
			"id": "341202",
			"name": "颍州区",
			"parent": "341200",
			"children": null
		}, {
			"id": "341203",
			"name": "颍东区",
			"parent": "341200",
			"children": null
		}, {
			"id": "341204",
			"name": "颍泉区",
			"parent": "341200",
			"children": null
		}, {
			"id": "341221",
			"name": "临泉县",
			"parent": "341200",
			"children": null
		}, {
			"id": "341222",
			"name": "太和县",
			"parent": "341200",
			"children": null
		}, {
			"id": "341225",
			"name": "阜南县",
			"parent": "341200",
			"children": null
		}, {
			"id": "341226",
			"name": "颍上县",
			"parent": "341200",
			"children": null
		}, {
			"id": "341282",
			"name": "界首市",
			"parent": "341200",
			"children": null
		}, {
			"id": "341283",
			"name": "其它区",
			"parent": "341200",
			"children": null
		}]
	}, {
		"id": "341300",
		"name": "宿州市",
		"parent": "340000",
		"children": [{
			"id": "341302",
			"name": "埇桥区",
			"parent": "341300",
			"children": null
		}, {
			"id": "341321",
			"name": "砀山县",
			"parent": "341300",
			"children": null
		}, {
			"id": "341322",
			"name": "萧县",
			"parent": "341300",
			"children": null
		}, {
			"id": "341323",
			"name": "灵璧县",
			"parent": "341300",
			"children": null
		}, {
			"id": "341324",
			"name": "泗县",
			"parent": "341300",
			"children": null
		}, {
			"id": "341325",
			"name": "其它区",
			"parent": "341300",
			"children": null
		}]
	}, {
		"id": "341500",
		"name": "六安市",
		"parent": "340000",
		"children": [{
			"id": "341502",
			"name": "金安区",
			"parent": "341500",
			"children": null
		}, {
			"id": "341503",
			"name": "裕安区",
			"parent": "341500",
			"children": null
		}, {
			"id": "341504",
			"name": "叶集区",
			"parent": "341500",
			"children": null
		}, {
			"id": "341521",
			"name": "寿县",
			"parent": "341500",
			"children": null
		}, {
			"id": "341522",
			"name": "霍邱县",
			"parent": "341500",
			"children": null
		}, {
			"id": "341523",
			"name": "舒城县",
			"parent": "341500",
			"children": null
		}, {
			"id": "341524",
			"name": "金寨县",
			"parent": "341500",
			"children": null
		}, {
			"id": "341525",
			"name": "霍山县",
			"parent": "341500",
			"children": null
		}, {
			"id": "341526",
			"name": "其它区",
			"parent": "341500",
			"children": null
		}]
	}, {
		"id": "341600",
		"name": "亳州市",
		"parent": "340000",
		"children": [{
			"id": "341602",
			"name": "谯城区",
			"parent": "341600",
			"children": null
		}, {
			"id": "341621",
			"name": "涡阳县",
			"parent": "341600",
			"children": null
		}, {
			"id": "341622",
			"name": "蒙城县",
			"parent": "341600",
			"children": null
		}, {
			"id": "341623",
			"name": "利辛县",
			"parent": "341600",
			"children": null
		}, {
			"id": "341624",
			"name": "其它区",
			"parent": "341600",
			"children": null
		}]
	}, {
		"id": "341700",
		"name": "池州市",
		"parent": "340000",
		"children": [{
			"id": "341702",
			"name": "贵池区",
			"parent": "341700",
			"children": null
		}, {
			"id": "341721",
			"name": "东至县",
			"parent": "341700",
			"children": null
		}, {
			"id": "341722",
			"name": "石台县",
			"parent": "341700",
			"children": null
		}, {
			"id": "341723",
			"name": "青阳县",
			"parent": "341700",
			"children": null
		}, {
			"id": "341724",
			"name": "其它区",
			"parent": "341700",
			"children": null
		}]
	}, {
		"id": "341800",
		"name": "宣城市",
		"parent": "340000",
		"children": [{
			"id": "341802",
			"name": "宣州区",
			"parent": "341800",
			"children": null
		}, {
			"id": "341821",
			"name": "郎溪县",
			"parent": "341800",
			"children": null
		}, {
			"id": "341822",
			"name": "广德县",
			"parent": "341800",
			"children": null
		}, {
			"id": "341823",
			"name": "泾县",
			"parent": "341800",
			"children": null
		}, {
			"id": "341824",
			"name": "绩溪县",
			"parent": "341800",
			"children": null
		}, {
			"id": "341825",
			"name": "旌德县",
			"parent": "341800",
			"children": null
		}, {
			"id": "341881",
			"name": "宁国市",
			"parent": "341800",
			"children": null
		}, {
			"id": "341882",
			"name": "其它区",
			"parent": "341800",
			"children": null
		}]
	}]
}, {
	"id": "350000",
	"name": "福建省",
	"children": [{
		"id": "350100",
		"name": "福州市",
		"parent": "350000",
		"children": [{
			"id": "350102",
			"name": "鼓楼区",
			"parent": "350100",
			"children": null
		}, {
			"id": "350103",
			"name": "台江区",
			"parent": "350100",
			"children": null
		}, {
			"id": "350104",
			"name": "仓山区",
			"parent": "350100",
			"children": null
		}, {
			"id": "350105",
			"name": "马尾区",
			"parent": "350100",
			"children": null
		}, {
			"id": "350111",
			"name": "晋安区",
			"parent": "350100",
			"children": null
		}, {
			"id": "350121",
			"name": "闽侯县",
			"parent": "350100",
			"children": null
		}, {
			"id": "350122",
			"name": "连江县",
			"parent": "350100",
			"children": null
		}, {
			"id": "350123",
			"name": "罗源县",
			"parent": "350100",
			"children": null
		}, {
			"id": "350124",
			"name": "闽清县",
			"parent": "350100",
			"children": null
		}, {
			"id": "350125",
			"name": "永泰县",
			"parent": "350100",
			"children": null
		}, {
			"id": "350128",
			"name": "平潭县",
			"parent": "350100",
			"children": null
		}, {
			"id": "350181",
			"name": "福清市",
			"parent": "350100",
			"children": null
		}, {
			"id": "350182",
			"name": "长乐市",
			"parent": "350100",
			"children": null
		}, {
			"id": "350183",
			"name": "其它区",
			"parent": "350100",
			"children": null
		}]
	}, {
		"id": "350200",
		"name": "厦门市",
		"parent": "350000",
		"children": [{
			"id": "350203",
			"name": "思明区",
			"parent": "350200",
			"children": null
		}, {
			"id": "350205",
			"name": "海沧区",
			"parent": "350200",
			"children": null
		}, {
			"id": "350206",
			"name": "湖里区",
			"parent": "350200",
			"children": null
		}, {
			"id": "350211",
			"name": "集美区",
			"parent": "350200",
			"children": null
		}, {
			"id": "350212",
			"name": "同安区",
			"parent": "350200",
			"children": null
		}, {
			"id": "350213",
			"name": "翔安区",
			"parent": "350200",
			"children": null
		}, {
			"id": "350214",
			"name": "其它区",
			"parent": "350200",
			"children": null
		}]
	}, {
		"id": "350300",
		"name": "莆田市",
		"parent": "350000",
		"children": [{
			"id": "350302",
			"name": "城厢区",
			"parent": "350300",
			"children": null
		}, {
			"id": "350303",
			"name": "涵江区",
			"parent": "350300",
			"children": null
		}, {
			"id": "350304",
			"name": "荔城区",
			"parent": "350300",
			"children": null
		}, {
			"id": "350305",
			"name": "秀屿区",
			"parent": "350300",
			"children": null
		}, {
			"id": "350322",
			"name": "仙游县",
			"parent": "350300",
			"children": null
		}, {
			"id": "350323",
			"name": "其它区",
			"parent": "350300",
			"children": null
		}]
	}, {
		"id": "350400",
		"name": "三明市",
		"parent": "350000",
		"children": [{
			"id": "350402",
			"name": "梅列区",
			"parent": "350400",
			"children": null
		}, {
			"id": "350403",
			"name": "三元区",
			"parent": "350400",
			"children": null
		}, {
			"id": "350421",
			"name": "明溪县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350423",
			"name": "清流县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350424",
			"name": "宁化县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350425",
			"name": "大田县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350426",
			"name": "尤溪县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350427",
			"name": "沙县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350428",
			"name": "将乐县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350429",
			"name": "泰宁县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350430",
			"name": "建宁县",
			"parent": "350400",
			"children": null
		}, {
			"id": "350481",
			"name": "永安市",
			"parent": "350400",
			"children": null
		}, {
			"id": "350482",
			"name": "其它区",
			"parent": "350400",
			"children": null
		}]
	}, {
		"id": "350500",
		"name": "泉州市",
		"parent": "350000",
		"children": [{
			"id": "350502",
			"name": "鲤城区",
			"parent": "350500",
			"children": null
		}, {
			"id": "350503",
			"name": "丰泽区",
			"parent": "350500",
			"children": null
		}, {
			"id": "350504",
			"name": "洛江区",
			"parent": "350500",
			"children": null
		}, {
			"id": "350505",
			"name": "泉港区",
			"parent": "350500",
			"children": null
		}, {
			"id": "350521",
			"name": "惠安县",
			"parent": "350500",
			"children": null
		}, {
			"id": "350524",
			"name": "安溪县",
			"parent": "350500",
			"children": null
		}, {
			"id": "350525",
			"name": "永春县",
			"parent": "350500",
			"children": null
		}, {
			"id": "350526",
			"name": "德化县",
			"parent": "350500",
			"children": null
		}, {
			"id": "350527",
			"name": "金门县",
			"parent": "350500",
			"children": null
		}, {
			"id": "350581",
			"name": "石狮市",
			"parent": "350500",
			"children": null
		}, {
			"id": "350582",
			"name": "晋江市",
			"parent": "350500",
			"children": null
		}, {
			"id": "350583",
			"name": "南安市",
			"parent": "350500",
			"children": null
		}, {
			"id": "350584",
			"name": "其它区",
			"parent": "350500",
			"children": null
		}]
	}, {
		"id": "350600",
		"name": "漳州市",
		"parent": "350000",
		"children": [{
			"id": "350602",
			"name": "芗城区",
			"parent": "350600",
			"children": null
		}, {
			"id": "350603",
			"name": "龙文区",
			"parent": "350600",
			"children": null
		}, {
			"id": "350622",
			"name": "云霄县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350623",
			"name": "漳浦县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350624",
			"name": "诏安县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350625",
			"name": "长泰县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350626",
			"name": "东山县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350627",
			"name": "南靖县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350628",
			"name": "平和县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350629",
			"name": "华安县",
			"parent": "350600",
			"children": null
		}, {
			"id": "350681",
			"name": "龙海市",
			"parent": "350600",
			"children": null
		}, {
			"id": "350682",
			"name": "其它区",
			"parent": "350600",
			"children": null
		}]
	}, {
		"id": "350700",
		"name": "南平市",
		"parent": "350000",
		"children": [{
			"id": "350702",
			"name": "延平区",
			"parent": "350700",
			"children": null
		}, {
			"id": "350721",
			"name": "顺昌县",
			"parent": "350700",
			"children": null
		}, {
			"id": "350722",
			"name": "浦城县",
			"parent": "350700",
			"children": null
		}, {
			"id": "350723",
			"name": "光泽县",
			"parent": "350700",
			"children": null
		}, {
			"id": "350724",
			"name": "松溪县",
			"parent": "350700",
			"children": null
		}, {
			"id": "350725",
			"name": "政和县",
			"parent": "350700",
			"children": null
		}, {
			"id": "350781",
			"name": "邵武市",
			"parent": "350700",
			"children": null
		}, {
			"id": "350782",
			"name": "武夷山市",
			"parent": "350700",
			"children": null
		}, {
			"id": "350783",
			"name": "建瓯市",
			"parent": "350700",
			"children": null
		}, {
			"id": "350784",
			"name": "建阳区",
			"parent": "350700",
			"children": null
		}, {
			"id": "350785",
			"name": "其它区",
			"parent": "350700",
			"children": null
		}]
	}, {
		"id": "350800",
		"name": "龙岩市",
		"parent": "350000",
		"children": [{
			"id": "350802",
			"name": "新罗区",
			"parent": "350800",
			"children": null
		}, {
			"id": "350821",
			"name": "长汀县",
			"parent": "350800",
			"children": null
		}, {
			"id": "350822",
			"name": "永定区",
			"parent": "350800",
			"children": null
		}, {
			"id": "350823",
			"name": "上杭县",
			"parent": "350800",
			"children": null
		}, {
			"id": "350824",
			"name": "武平县",
			"parent": "350800",
			"children": null
		}, {
			"id": "350825",
			"name": "连城县",
			"parent": "350800",
			"children": null
		}, {
			"id": "350881",
			"name": "漳平市",
			"parent": "350800",
			"children": null
		}, {
			"id": "350882",
			"name": "其它区",
			"parent": "350800",
			"children": null
		}]
	}, {
		"id": "350900",
		"name": "宁德市",
		"parent": "350000",
		"children": [{
			"id": "350902",
			"name": "蕉城区",
			"parent": "350900",
			"children": null
		}, {
			"id": "350921",
			"name": "霞浦县",
			"parent": "350900",
			"children": null
		}, {
			"id": "350922",
			"name": "古田县",
			"parent": "350900",
			"children": null
		}, {
			"id": "350923",
			"name": "屏南县",
			"parent": "350900",
			"children": null
		}, {
			"id": "350924",
			"name": "寿宁县",
			"parent": "350900",
			"children": null
		}, {
			"id": "350925",
			"name": "周宁县",
			"parent": "350900",
			"children": null
		}, {
			"id": "350926",
			"name": "柘荣县",
			"parent": "350900",
			"children": null
		}, {
			"id": "350981",
			"name": "福安市",
			"parent": "350900",
			"children": null
		}, {
			"id": "350982",
			"name": "福鼎市",
			"parent": "350900",
			"children": null
		}, {
			"id": "350983",
			"name": "其它区",
			"parent": "350900",
			"children": null
		}]
	}]
}, {
	"id": "360000",
	"name": "江西省",
	"children": [{
		"id": "360100",
		"name": "南昌市",
		"parent": "360000",
		"children": [{
			"id": "360102",
			"name": "东湖区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360103",
			"name": "西湖区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360104",
			"name": "青云谱区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360105",
			"name": "湾里区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360111",
			"name": "青山湖区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360121",
			"name": "南昌县",
			"parent": "360100",
			"children": null
		}, {
			"id": "360122",
			"name": "新建县",
			"parent": "360100",
			"children": null
		}, {
			"id": "360123",
			"name": "安义县",
			"parent": "360100",
			"children": null
		}, {
			"id": "360124",
			"name": "进贤县",
			"parent": "360100",
			"children": null
		}, {
			"id": "360125",
			"name": "红谷滩新区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360126",
			"name": "经济技术开发区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360127",
			"name": "昌北区",
			"parent": "360100",
			"children": null
		}, {
			"id": "360128",
			"name": "其它区",
			"parent": "360100",
			"children": null
		}]
	}, {
		"id": "360200",
		"name": "景德镇市",
		"parent": "360000",
		"children": [{
			"id": "360202",
			"name": "昌江区",
			"parent": "360200",
			"children": null
		}, {
			"id": "360203",
			"name": "珠山区",
			"parent": "360200",
			"children": null
		}, {
			"id": "360222",
			"name": "浮梁县",
			"parent": "360200",
			"children": null
		}, {
			"id": "360281",
			"name": "乐平市",
			"parent": "360200",
			"children": null
		}, {
			"id": "360282",
			"name": "其它区",
			"parent": "360200",
			"children": null
		}]
	}, {
		"id": "360300",
		"name": "萍乡市",
		"parent": "360000",
		"children": [{
			"id": "360302",
			"name": "安源区",
			"parent": "360300",
			"children": null
		}, {
			"id": "360313",
			"name": "湘东区",
			"parent": "360300",
			"children": null
		}, {
			"id": "360321",
			"name": "莲花县",
			"parent": "360300",
			"children": null
		}, {
			"id": "360322",
			"name": "上栗县",
			"parent": "360300",
			"children": null
		}, {
			"id": "360323",
			"name": "芦溪县",
			"parent": "360300",
			"children": null
		}, {
			"id": "360324",
			"name": "其它区",
			"parent": "360300",
			"children": null
		}]
	}, {
		"id": "360400",
		"name": "九江市",
		"parent": "360000",
		"children": [{
			"id": "360402",
			"name": "庐山区",
			"parent": "360400",
			"children": null
		}, {
			"id": "360403",
			"name": "浔阳区",
			"parent": "360400",
			"children": null
		}, {
			"id": "360421",
			"name": "九江县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360423",
			"name": "武宁县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360424",
			"name": "修水县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360425",
			"name": "永修县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360426",
			"name": "德安县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360427",
			"name": "星子县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360428",
			"name": "都昌县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360429",
			"name": "湖口县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360430",
			"name": "彭泽县",
			"parent": "360400",
			"children": null
		}, {
			"id": "360481",
			"name": "瑞昌市",
			"parent": "360400",
			"children": null
		}, {
			"id": "360482",
			"name": "其它区",
			"parent": "360400",
			"children": null
		}, {
			"id": "360483",
			"name": "共青城市",
			"parent": "360400",
			"children": null
		}]
	}, {
		"id": "360500",
		"name": "新余市",
		"parent": "360000",
		"children": [{
			"id": "360502",
			"name": "渝水区",
			"parent": "360500",
			"children": null
		}, {
			"id": "360521",
			"name": "分宜县",
			"parent": "360500",
			"children": null
		}, {
			"id": "360522",
			"name": "其它区",
			"parent": "360500",
			"children": null
		}]
	}, {
		"id": "360600",
		"name": "鹰潭市",
		"parent": "360000",
		"children": [{
			"id": "360602",
			"name": "月湖区",
			"parent": "360600",
			"children": null
		}, {
			"id": "360622",
			"name": "余江县",
			"parent": "360600",
			"children": null
		}, {
			"id": "360681",
			"name": "贵溪市",
			"parent": "360600",
			"children": null
		}, {
			"id": "360682",
			"name": "其它区",
			"parent": "360600",
			"children": null
		}]
	}, {
		"id": "360700",
		"name": "赣州市",
		"parent": "360000",
		"children": [{
			"id": "360702",
			"name": "章贡区",
			"parent": "360700",
			"children": null
		}, {
			"id": "360721",
			"name": "赣县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360722",
			"name": "信丰县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360723",
			"name": "大余县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360724",
			"name": "上犹县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360725",
			"name": "崇义县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360726",
			"name": "安远县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360727",
			"name": "龙南县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360728",
			"name": "定南县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360729",
			"name": "全南县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360730",
			"name": "宁都县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360731",
			"name": "于都县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360732",
			"name": "兴国县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360733",
			"name": "会昌县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360734",
			"name": "寻乌县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360735",
			"name": "石城县",
			"parent": "360700",
			"children": null
		}, {
			"id": "360751",
			"name": "黄金区",
			"parent": "360700",
			"children": null
		}, {
			"id": "360781",
			"name": "瑞金市",
			"parent": "360700",
			"children": null
		}, {
			"id": "360782",
			"name": "南康区",
			"parent": "360700",
			"children": null
		}, {
			"id": "360783",
			"name": "其它区",
			"parent": "360700",
			"children": null
		}]
	}, {
		"id": "360800",
		"name": "吉安市",
		"parent": "360000",
		"children": [{
			"id": "360802",
			"name": "吉州区",
			"parent": "360800",
			"children": null
		}, {
			"id": "360803",
			"name": "青原区",
			"parent": "360800",
			"children": null
		}, {
			"id": "360821",
			"name": "吉安县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360822",
			"name": "吉水县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360823",
			"name": "峡江县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360824",
			"name": "新干县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360825",
			"name": "永丰县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360826",
			"name": "泰和县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360827",
			"name": "遂川县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360828",
			"name": "万安县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360829",
			"name": "安福县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360830",
			"name": "永新县",
			"parent": "360800",
			"children": null
		}, {
			"id": "360881",
			"name": "井冈山市",
			"parent": "360800",
			"children": null
		}, {
			"id": "360882",
			"name": "其它区",
			"parent": "360800",
			"children": null
		}]
	}, {
		"id": "360900",
		"name": "宜春市",
		"parent": "360000",
		"children": [{
			"id": "360902",
			"name": "袁州区",
			"parent": "360900",
			"children": null
		}, {
			"id": "360921",
			"name": "奉新县",
			"parent": "360900",
			"children": null
		}, {
			"id": "360922",
			"name": "万载县",
			"parent": "360900",
			"children": null
		}, {
			"id": "360923",
			"name": "上高县",
			"parent": "360900",
			"children": null
		}, {
			"id": "360924",
			"name": "宜丰县",
			"parent": "360900",
			"children": null
		}, {
			"id": "360925",
			"name": "靖安县",
			"parent": "360900",
			"children": null
		}, {
			"id": "360926",
			"name": "铜鼓县",
			"parent": "360900",
			"children": null
		}, {
			"id": "360981",
			"name": "丰城市",
			"parent": "360900",
			"children": null
		}, {
			"id": "360982",
			"name": "樟树市",
			"parent": "360900",
			"children": null
		}, {
			"id": "360983",
			"name": "高安市",
			"parent": "360900",
			"children": null
		}, {
			"id": "360984",
			"name": "其它区",
			"parent": "360900",
			"children": null
		}]
	}, {
		"id": "361000",
		"name": "抚州市",
		"parent": "360000",
		"children": [{
			"id": "361002",
			"name": "临川区",
			"parent": "361000",
			"children": null
		}, {
			"id": "361021",
			"name": "南城县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361022",
			"name": "黎川县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361023",
			"name": "南丰县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361024",
			"name": "崇仁县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361025",
			"name": "乐安县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361026",
			"name": "宜黄县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361027",
			"name": "金溪县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361028",
			"name": "资溪县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361029",
			"name": "东乡县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361030",
			"name": "广昌县",
			"parent": "361000",
			"children": null
		}, {
			"id": "361031",
			"name": "其它区",
			"parent": "361000",
			"children": null
		}]
	}, {
		"id": "361100",
		"name": "上饶市",
		"parent": "360000",
		"children": [{
			"id": "361102",
			"name": "信州区",
			"parent": "361100",
			"children": null
		}, {
			"id": "361121",
			"name": "上饶县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361122",
			"name": "广丰区",
			"parent": "361100",
			"children": null
		}, {
			"id": "361123",
			"name": "玉山县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361124",
			"name": "铅山县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361125",
			"name": "横峰县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361126",
			"name": "弋阳县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361127",
			"name": "余干县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361128",
			"name": "鄱阳县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361129",
			"name": "万年县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361130",
			"name": "婺源县",
			"parent": "361100",
			"children": null
		}, {
			"id": "361181",
			"name": "德兴市",
			"parent": "361100",
			"children": null
		}, {
			"id": "361182",
			"name": "其它区",
			"parent": "361100",
			"children": null
		}]
	}]
}, {
	"id": "370000",
	"name": "山东省",
	"children": [{
		"id": "370100",
		"name": "济南市",
		"parent": "370000",
		"children": [{
			"id": "370102",
			"name": "历下区",
			"parent": "370100",
			"children": null
		}, {
			"id": "370103",
			"name": "市中区",
			"parent": "370100",
			"children": null
		}, {
			"id": "370104",
			"name": "槐荫区",
			"parent": "370100",
			"children": null
		}, {
			"id": "370105",
			"name": "天桥区",
			"parent": "370100",
			"children": null
		}, {
			"id": "370112",
			"name": "历城区",
			"parent": "370100",
			"children": null
		}, {
			"id": "370113",
			"name": "长清区",
			"parent": "370100",
			"children": null
		}, {
			"id": "370124",
			"name": "平阴县",
			"parent": "370100",
			"children": null
		}, {
			"id": "370125",
			"name": "济阳县",
			"parent": "370100",
			"children": null
		}, {
			"id": "370126",
			"name": "商河县",
			"parent": "370100",
			"children": null
		}, {
			"id": "370181",
			"name": "章丘市",
			"parent": "370100",
			"children": null
		}, {
			"id": "370182",
			"name": "其它区",
			"parent": "370100",
			"children": null
		}]
	}, {
		"id": "370200",
		"name": "青岛市",
		"parent": "370000",
		"children": [{
			"id": "370202",
			"name": "市南区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370203",
			"name": "市北区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370205",
			"name": "四方区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370211",
			"name": "黄岛区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370212",
			"name": "崂山区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370213",
			"name": "李沧区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370214",
			"name": "城阳区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370251",
			"name": "开发区",
			"parent": "370200",
			"children": null
		}, {
			"id": "370281",
			"name": "胶州市",
			"parent": "370200",
			"children": null
		}, {
			"id": "370282",
			"name": "即墨市",
			"parent": "370200",
			"children": null
		}, {
			"id": "370283",
			"name": "平度市",
			"parent": "370200",
			"children": null
		}, {
			"id": "370284",
			"name": "胶南市",
			"parent": "370200",
			"children": null
		}, {
			"id": "370285",
			"name": "莱西市",
			"parent": "370200",
			"children": null
		}, {
			"id": "370286",
			"name": "其它区",
			"parent": "370200",
			"children": null
		}]
	}, {
		"id": "370300",
		"name": "淄博市",
		"parent": "370000",
		"children": [{
			"id": "370302",
			"name": "淄川区",
			"parent": "370300",
			"children": null
		}, {
			"id": "370303",
			"name": "张店区",
			"parent": "370300",
			"children": null
		}, {
			"id": "370304",
			"name": "博山区",
			"parent": "370300",
			"children": null
		}, {
			"id": "370305",
			"name": "临淄区",
			"parent": "370300",
			"children": null
		}, {
			"id": "370306",
			"name": "周村区",
			"parent": "370300",
			"children": null
		}, {
			"id": "370321",
			"name": "桓台县",
			"parent": "370300",
			"children": null
		}, {
			"id": "370322",
			"name": "高青县",
			"parent": "370300",
			"children": null
		}, {
			"id": "370323",
			"name": "沂源县",
			"parent": "370300",
			"children": null
		}, {
			"id": "370324",
			"name": "其它区",
			"parent": "370300",
			"children": null
		}]
	}, {
		"id": "370400",
		"name": "枣庄市",
		"parent": "370000",
		"children": [{
			"id": "370402",
			"name": "市中区",
			"parent": "370400",
			"children": null
		}, {
			"id": "370403",
			"name": "薛城区",
			"parent": "370400",
			"children": null
		}, {
			"id": "370404",
			"name": "峄城区",
			"parent": "370400",
			"children": null
		}, {
			"id": "370405",
			"name": "台儿庄区",
			"parent": "370400",
			"children": null
		}, {
			"id": "370406",
			"name": "山亭区",
			"parent": "370400",
			"children": null
		}, {
			"id": "370481",
			"name": "滕州市",
			"parent": "370400",
			"children": null
		}, {
			"id": "370482",
			"name": "其它区",
			"parent": "370400",
			"children": null
		}]
	}, {
		"id": "370500",
		"name": "东营市",
		"parent": "370000",
		"children": [{
			"id": "370502",
			"name": "东营区",
			"parent": "370500",
			"children": null
		}, {
			"id": "370503",
			"name": "河口区",
			"parent": "370500",
			"children": null
		}, {
			"id": "370521",
			"name": "垦利县",
			"parent": "370500",
			"children": null
		}, {
			"id": "370522",
			"name": "利津县",
			"parent": "370500",
			"children": null
		}, {
			"id": "370523",
			"name": "广饶县",
			"parent": "370500",
			"children": null
		}, {
			"id": "370589",
			"name": "西城区",
			"parent": "370500",
			"children": null
		}, {
			"id": "370590",
			"name": "东城区",
			"parent": "370500",
			"children": null
		}, {
			"id": "370591",
			"name": "其它区",
			"parent": "370500",
			"children": null
		}]
	}, {
		"id": "370600",
		"name": "烟台市",
		"parent": "370000",
		"children": [{
			"id": "370602",
			"name": "芝罘区",
			"parent": "370600",
			"children": null
		}, {
			"id": "370611",
			"name": "福山区",
			"parent": "370600",
			"children": null
		}, {
			"id": "370612",
			"name": "牟平区",
			"parent": "370600",
			"children": null
		}, {
			"id": "370613",
			"name": "莱山区",
			"parent": "370600",
			"children": null
		}, {
			"id": "370634",
			"name": "长岛县",
			"parent": "370600",
			"children": null
		}, {
			"id": "370681",
			"name": "龙口市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370682",
			"name": "莱阳市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370683",
			"name": "莱州市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370684",
			"name": "蓬莱市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370685",
			"name": "招远市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370686",
			"name": "栖霞市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370687",
			"name": "海阳市",
			"parent": "370600",
			"children": null
		}, {
			"id": "370688",
			"name": "其它区",
			"parent": "370600",
			"children": null
		}]
	}, {
		"id": "370700",
		"name": "潍坊市",
		"parent": "370000",
		"children": [{
			"id": "370702",
			"name": "潍城区",
			"parent": "370700",
			"children": null
		}, {
			"id": "370703",
			"name": "寒亭区",
			"parent": "370700",
			"children": null
		}, {
			"id": "370704",
			"name": "坊子区",
			"parent": "370700",
			"children": null
		}, {
			"id": "370705",
			"name": "奎文区",
			"parent": "370700",
			"children": null
		}, {
			"id": "370724",
			"name": "临朐县",
			"parent": "370700",
			"children": null
		}, {
			"id": "370725",
			"name": "昌乐县",
			"parent": "370700",
			"children": null
		}, {
			"id": "370751",
			"name": "开发区",
			"parent": "370700",
			"children": null
		}, {
			"id": "370781",
			"name": "青州市",
			"parent": "370700",
			"children": null
		}, {
			"id": "370782",
			"name": "诸城市",
			"parent": "370700",
			"children": null
		}, {
			"id": "370783",
			"name": "寿光市",
			"parent": "370700",
			"children": null
		}, {
			"id": "370784",
			"name": "安丘市",
			"parent": "370700",
			"children": null
		}, {
			"id": "370785",
			"name": "高密市",
			"parent": "370700",
			"children": null
		}, {
			"id": "370786",
			"name": "昌邑市",
			"parent": "370700",
			"children": null
		}, {
			"id": "370787",
			"name": "其它区",
			"parent": "370700",
			"children": null
		}]
	}, {
		"id": "370800",
		"name": "济宁市",
		"parent": "370000",
		"children": [{
			"id": "370802",
			"name": "市中区",
			"parent": "370800",
			"children": null
		}, {
			"id": "370811",
			"name": "任城区",
			"parent": "370800",
			"children": null
		}, {
			"id": "370826",
			"name": "微山县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370827",
			"name": "鱼台县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370828",
			"name": "金乡县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370829",
			"name": "嘉祥县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370830",
			"name": "汶上县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370831",
			"name": "泗水县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370832",
			"name": "梁山县",
			"parent": "370800",
			"children": null
		}, {
			"id": "370881",
			"name": "曲阜市",
			"parent": "370800",
			"children": null
		}, {
			"id": "370882",
			"name": "兖州区",
			"parent": "370800",
			"children": null
		}, {
			"id": "370883",
			"name": "邹城市",
			"parent": "370800",
			"children": null
		}, {
			"id": "370884",
			"name": "其它区",
			"parent": "370800",
			"children": null
		}]
	}, {
		"id": "370900",
		"name": "泰安市",
		"parent": "370000",
		"children": [{
			"id": "370902",
			"name": "泰山区",
			"parent": "370900",
			"children": null
		}, {
			"id": "370903",
			"name": "岱岳区",
			"parent": "370900",
			"children": null
		}, {
			"id": "370921",
			"name": "宁阳县",
			"parent": "370900",
			"children": null
		}, {
			"id": "370923",
			"name": "东平县",
			"parent": "370900",
			"children": null
		}, {
			"id": "370982",
			"name": "新泰市",
			"parent": "370900",
			"children": null
		}, {
			"id": "370983",
			"name": "肥城市",
			"parent": "370900",
			"children": null
		}, {
			"id": "370984",
			"name": "其它区",
			"parent": "370900",
			"children": null
		}]
	}, {
		"id": "371000",
		"name": "威海市",
		"parent": "370000",
		"children": [{
			"id": "371002",
			"name": "环翠区",
			"parent": "371000",
			"children": null
		}, {
			"id": "371081",
			"name": "文登区",
			"parent": "371000",
			"children": null
		}, {
			"id": "371082",
			"name": "荣成市",
			"parent": "371000",
			"children": null
		}, {
			"id": "371083",
			"name": "乳山市",
			"parent": "371000",
			"children": null
		}, {
			"id": "371084",
			"name": "其它区",
			"parent": "371000",
			"children": null
		}]
	}, {
		"id": "371100",
		"name": "日照市",
		"parent": "370000",
		"children": [{
			"id": "371102",
			"name": "东港区",
			"parent": "371100",
			"children": null
		}, {
			"id": "371103",
			"name": "岚山区",
			"parent": "371100",
			"children": null
		}, {
			"id": "371121",
			"name": "五莲县",
			"parent": "371100",
			"children": null
		}, {
			"id": "371122",
			"name": "莒县",
			"parent": "371100",
			"children": null
		}, {
			"id": "371123",
			"name": "其它区",
			"parent": "371100",
			"children": null
		}]
	}, {
		"id": "371200",
		"name": "莱芜市",
		"parent": "370000",
		"children": [{
			"id": "371202",
			"name": "莱城区",
			"parent": "371200",
			"children": null
		}, {
			"id": "371203",
			"name": "钢城区",
			"parent": "371200",
			"children": null
		}, {
			"id": "371204",
			"name": "其它区",
			"parent": "371200",
			"children": null
		}]
	}, {
		"id": "371300",
		"name": "临沂市",
		"parent": "370000",
		"children": [{
			"id": "371302",
			"name": "兰山区",
			"parent": "371300",
			"children": null
		}, {
			"id": "371311",
			"name": "罗庄区",
			"parent": "371300",
			"children": null
		}, {
			"id": "371312",
			"name": "河东区",
			"parent": "371300",
			"children": null
		}, {
			"id": "371321",
			"name": "沂南县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371322",
			"name": "郯城县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371323",
			"name": "沂水县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371324",
			"name": "兰陵县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371325",
			"name": "费县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371326",
			"name": "平邑县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371327",
			"name": "莒南县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371328",
			"name": "蒙阴县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371329",
			"name": "临沭县",
			"parent": "371300",
			"children": null
		}, {
			"id": "371330",
			"name": "其它区",
			"parent": "371300",
			"children": null
		}]
	}, {
		"id": "371400",
		"name": "德州市",
		"parent": "370000",
		"children": [{
			"id": "371402",
			"name": "德城区",
			"parent": "371400",
			"children": null
		}, {
			"id": "371421",
			"name": "陵城区",
			"parent": "371400",
			"children": null
		}, {
			"id": "371422",
			"name": "宁津县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371423",
			"name": "庆云县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371424",
			"name": "临邑县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371425",
			"name": "齐河县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371426",
			"name": "平原县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371427",
			"name": "夏津县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371428",
			"name": "武城县",
			"parent": "371400",
			"children": null
		}, {
			"id": "371451",
			"name": "开发区",
			"parent": "371400",
			"children": null
		}, {
			"id": "371481",
			"name": "乐陵市",
			"parent": "371400",
			"children": null
		}, {
			"id": "371482",
			"name": "禹城市",
			"parent": "371400",
			"children": null
		}, {
			"id": "371483",
			"name": "其它区",
			"parent": "371400",
			"children": null
		}]
	}, {
		"id": "371500",
		"name": "聊城市",
		"parent": "370000",
		"children": [{
			"id": "371502",
			"name": "东昌府区",
			"parent": "371500",
			"children": null
		}, {
			"id": "371521",
			"name": "阳谷县",
			"parent": "371500",
			"children": null
		}, {
			"id": "371522",
			"name": "莘县",
			"parent": "371500",
			"children": null
		}, {
			"id": "371523",
			"name": "茌平县",
			"parent": "371500",
			"children": null
		}, {
			"id": "371524",
			"name": "东阿县",
			"parent": "371500",
			"children": null
		}, {
			"id": "371525",
			"name": "冠县",
			"parent": "371500",
			"children": null
		}, {
			"id": "371526",
			"name": "高唐县",
			"parent": "371500",
			"children": null
		}, {
			"id": "371581",
			"name": "临清市",
			"parent": "371500",
			"children": null
		}, {
			"id": "371582",
			"name": "其它区",
			"parent": "371500",
			"children": null
		}]
	}, {
		"id": "371600",
		"name": "滨州市",
		"parent": "370000",
		"children": [{
			"id": "371602",
			"name": "滨城区",
			"parent": "371600",
			"children": null
		}, {
			"id": "371621",
			"name": "惠民县",
			"parent": "371600",
			"children": null
		}, {
			"id": "371622",
			"name": "阳信县",
			"parent": "371600",
			"children": null
		}, {
			"id": "371623",
			"name": "无棣县",
			"parent": "371600",
			"children": null
		}, {
			"id": "371624",
			"name": "沾化区",
			"parent": "371600",
			"children": null
		}, {
			"id": "371625",
			"name": "博兴县",
			"parent": "371600",
			"children": null
		}, {
			"id": "371626",
			"name": "邹平县",
			"parent": "371600",
			"children": null
		}, {
			"id": "371627",
			"name": "其它区",
			"parent": "371600",
			"children": null
		}]
	}, {
		"id": "371700",
		"name": "菏泽市",
		"parent": "370000",
		"children": [{
			"id": "371702",
			"name": "牡丹区",
			"parent": "371700",
			"children": null
		}, {
			"id": "371721",
			"name": "曹县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371722",
			"name": "单县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371723",
			"name": "成武县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371724",
			"name": "巨野县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371725",
			"name": "郓城县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371726",
			"name": "鄄城县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371727",
			"name": "定陶县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371728",
			"name": "东明县",
			"parent": "371700",
			"children": null
		}, {
			"id": "371729",
			"name": "其它区",
			"parent": "371700",
			"children": null
		}]
	}]
}, {
	"id": "410000",
	"name": "河南省",
	"children": [{
		"id": "410100",
		"name": "郑州市",
		"parent": "410000",
		"children": [{
			"id": "410102",
			"name": "中原区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410103",
			"name": "二七区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410104",
			"name": "管城回族区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410105",
			"name": "金水区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410106",
			"name": "上街区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410108",
			"name": "惠济区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410122",
			"name": "中牟县",
			"parent": "410100",
			"children": null
		}, {
			"id": "410181",
			"name": "巩义市",
			"parent": "410100",
			"children": null
		}, {
			"id": "410182",
			"name": "荥阳市",
			"parent": "410100",
			"children": null
		}, {
			"id": "410183",
			"name": "新密市",
			"parent": "410100",
			"children": null
		}, {
			"id": "410184",
			"name": "新郑市",
			"parent": "410100",
			"children": null
		}, {
			"id": "410185",
			"name": "登封市",
			"parent": "410100",
			"children": null
		}, {
			"id": "410186",
			"name": "郑东新区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410187",
			"name": "高新区",
			"parent": "410100",
			"children": null
		}, {
			"id": "410188",
			"name": "其它区",
			"parent": "410100",
			"children": null
		}]
	}, {
		"id": "410200",
		"name": "开封市",
		"parent": "410000",
		"children": [{
			"id": "410202",
			"name": "龙亭区",
			"parent": "410200",
			"children": null
		}, {
			"id": "410203",
			"name": "顺河回族区",
			"parent": "410200",
			"children": null
		}, {
			"id": "410204",
			"name": "鼓楼区",
			"parent": "410200",
			"children": null
		}, {
			"id": "410205",
			"name": "禹王台区",
			"parent": "410200",
			"children": null
		}, {
			"id": "410211",
			"name": "金明区",
			"parent": "410200",
			"children": null
		}, {
			"id": "410221",
			"name": "杞县",
			"parent": "410200",
			"children": null
		}, {
			"id": "410222",
			"name": "通许县",
			"parent": "410200",
			"children": null
		}, {
			"id": "410223",
			"name": "尉氏县",
			"parent": "410200",
			"children": null
		}, {
			"id": "410224",
			"name": "祥符区",
			"parent": "410200",
			"children": null
		}, {
			"id": "410225",
			"name": "兰考县",
			"parent": "410200",
			"children": null
		}, {
			"id": "410226",
			"name": "其它区",
			"parent": "410200",
			"children": null
		}]
	}, {
		"id": "410300",
		"name": "洛阳市",
		"parent": "410000",
		"children": [{
			"id": "410302",
			"name": "老城区",
			"parent": "410300",
			"children": null
		}, {
			"id": "410303",
			"name": "西工区",
			"parent": "410300",
			"children": null
		}, {
			"id": "410304",
			"name": "瀍河回族区",
			"parent": "410300",
			"children": null
		}, {
			"id": "410305",
			"name": "涧西区",
			"parent": "410300",
			"children": null
		}, {
			"id": "410306",
			"name": "吉利区",
			"parent": "410300",
			"children": null
		}, {
			"id": "410307",
			"name": "洛龙区",
			"parent": "410300",
			"children": null
		}, {
			"id": "410322",
			"name": "孟津县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410323",
			"name": "新安县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410324",
			"name": "栾川县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410325",
			"name": "嵩县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410326",
			"name": "汝阳县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410327",
			"name": "宜阳县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410328",
			"name": "洛宁县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410329",
			"name": "伊川县",
			"parent": "410300",
			"children": null
		}, {
			"id": "410381",
			"name": "偃师市",
			"parent": "410300",
			"children": null
		}, {
			"id": "471004",
			"name": "高新区",
			"parent": "410300",
			"children": null
		}, {
			"id": "471005",
			"name": "其它区",
			"parent": "410300",
			"children": null
		}]
	}, {
		"id": "410400",
		"name": "平顶山市",
		"parent": "410000",
		"children": [{
			"id": "410402",
			"name": "新华区",
			"parent": "410400",
			"children": null
		}, {
			"id": "410403",
			"name": "卫东区",
			"parent": "410400",
			"children": null
		}, {
			"id": "410404",
			"name": "石龙区",
			"parent": "410400",
			"children": null
		}, {
			"id": "410411",
			"name": "湛河区",
			"parent": "410400",
			"children": null
		}, {
			"id": "410421",
			"name": "宝丰县",
			"parent": "410400",
			"children": null
		}, {
			"id": "410422",
			"name": "叶县",
			"parent": "410400",
			"children": null
		}, {
			"id": "410423",
			"name": "鲁山县",
			"parent": "410400",
			"children": null
		}, {
			"id": "410425",
			"name": "郏县",
			"parent": "410400",
			"children": null
		}, {
			"id": "410481",
			"name": "舞钢市",
			"parent": "410400",
			"children": null
		}, {
			"id": "410482",
			"name": "汝州市",
			"parent": "410400",
			"children": null
		}, {
			"id": "410483",
			"name": "其它区",
			"parent": "410400",
			"children": null
		}]
	}, {
		"id": "410500",
		"name": "安阳市",
		"parent": "410000",
		"children": [{
			"id": "410502",
			"name": "文峰区",
			"parent": "410500",
			"children": null
		}, {
			"id": "410503",
			"name": "北关区",
			"parent": "410500",
			"children": null
		}, {
			"id": "410505",
			"name": "殷都区",
			"parent": "410500",
			"children": null
		}, {
			"id": "410506",
			"name": "龙安区",
			"parent": "410500",
			"children": null
		}, {
			"id": "410522",
			"name": "安阳县",
			"parent": "410500",
			"children": null
		}, {
			"id": "410523",
			"name": "汤阴县",
			"parent": "410500",
			"children": null
		}, {
			"id": "410526",
			"name": "滑县",
			"parent": "410500",
			"children": null
		}, {
			"id": "410527",
			"name": "内黄县",
			"parent": "410500",
			"children": null
		}, {
			"id": "410581",
			"name": "林州市",
			"parent": "410500",
			"children": null
		}, {
			"id": "410582",
			"name": "其它区",
			"parent": "410500",
			"children": null
		}]
	}, {
		"id": "410600",
		"name": "鹤壁市",
		"parent": "410000",
		"children": [{
			"id": "410602",
			"name": "鹤山区",
			"parent": "410600",
			"children": null
		}, {
			"id": "410603",
			"name": "山城区",
			"parent": "410600",
			"children": null
		}, {
			"id": "410611",
			"name": "淇滨区",
			"parent": "410600",
			"children": null
		}, {
			"id": "410621",
			"name": "浚县",
			"parent": "410600",
			"children": null
		}, {
			"id": "410622",
			"name": "淇县",
			"parent": "410600",
			"children": null
		}, {
			"id": "410623",
			"name": "其它区",
			"parent": "410600",
			"children": null
		}]
	}, {
		"id": "410700",
		"name": "新乡市",
		"parent": "410000",
		"children": [{
			"id": "410702",
			"name": "红旗区",
			"parent": "410700",
			"children": null
		}, {
			"id": "410703",
			"name": "卫滨区",
			"parent": "410700",
			"children": null
		}, {
			"id": "410704",
			"name": "凤泉区",
			"parent": "410700",
			"children": null
		}, {
			"id": "410711",
			"name": "牧野区",
			"parent": "410700",
			"children": null
		}, {
			"id": "410721",
			"name": "新乡县",
			"parent": "410700",
			"children": null
		}, {
			"id": "410724",
			"name": "获嘉县",
			"parent": "410700",
			"children": null
		}, {
			"id": "410725",
			"name": "原阳县",
			"parent": "410700",
			"children": null
		}, {
			"id": "410726",
			"name": "延津县",
			"parent": "410700",
			"children": null
		}, {
			"id": "410727",
			"name": "封丘县",
			"parent": "410700",
			"children": null
		}, {
			"id": "410728",
			"name": "长垣县",
			"parent": "410700",
			"children": null
		}, {
			"id": "410781",
			"name": "卫辉市",
			"parent": "410700",
			"children": null
		}, {
			"id": "410782",
			"name": "辉县市",
			"parent": "410700",
			"children": null
		}, {
			"id": "410783",
			"name": "其它区",
			"parent": "410700",
			"children": null
		}]
	}, {
		"id": "410800",
		"name": "焦作市",
		"parent": "410000",
		"children": [{
			"id": "410802",
			"name": "解放区",
			"parent": "410800",
			"children": null
		}, {
			"id": "410803",
			"name": "中站区",
			"parent": "410800",
			"children": null
		}, {
			"id": "410804",
			"name": "马村区",
			"parent": "410800",
			"children": null
		}, {
			"id": "410811",
			"name": "山阳区",
			"parent": "410800",
			"children": null
		}, {
			"id": "410821",
			"name": "修武县",
			"parent": "410800",
			"children": null
		}, {
			"id": "410822",
			"name": "博爱县",
			"parent": "410800",
			"children": null
		}, {
			"id": "410823",
			"name": "武陟县",
			"parent": "410800",
			"children": null
		}, {
			"id": "410825",
			"name": "温县",
			"parent": "410800",
			"children": null
		}, {
			"id": "410882",
			"name": "沁阳市",
			"parent": "410800",
			"children": null
		}, {
			"id": "410883",
			"name": "孟州市",
			"parent": "410800",
			"children": null
		}, {
			"id": "410884",
			"name": "其它区",
			"parent": "410800",
			"children": null
		}]
	}, {
		"id": "410900",
		"name": "濮阳市",
		"parent": "410000",
		"children": [{
			"id": "410902",
			"name": "华龙区",
			"parent": "410900",
			"children": null
		}, {
			"id": "410922",
			"name": "清丰县",
			"parent": "410900",
			"children": null
		}, {
			"id": "410923",
			"name": "南乐县",
			"parent": "410900",
			"children": null
		}, {
			"id": "410926",
			"name": "范县",
			"parent": "410900",
			"children": null
		}, {
			"id": "410927",
			"name": "台前县",
			"parent": "410900",
			"children": null
		}, {
			"id": "410928",
			"name": "濮阳县",
			"parent": "410900",
			"children": null
		}, {
			"id": "410929",
			"name": "其它区",
			"parent": "410900",
			"children": null
		}]
	}, {
		"id": "411000",
		"name": "许昌市",
		"parent": "410000",
		"children": [{
			"id": "411002",
			"name": "魏都区",
			"parent": "411000",
			"children": null
		}, {
			"id": "411023",
			"name": "许昌县",
			"parent": "411000",
			"children": null
		}, {
			"id": "411024",
			"name": "鄢陵县",
			"parent": "411000",
			"children": null
		}, {
			"id": "411025",
			"name": "襄城县",
			"parent": "411000",
			"children": null
		}, {
			"id": "411081",
			"name": "禹州市",
			"parent": "411000",
			"children": null
		}, {
			"id": "411082",
			"name": "长葛市",
			"parent": "411000",
			"children": null
		}, {
			"id": "411083",
			"name": "其它区",
			"parent": "411000",
			"children": null
		}]
	}, {
		"id": "411100",
		"name": "漯河市",
		"parent": "410000",
		"children": [{
			"id": "411102",
			"name": "源汇区",
			"parent": "411100",
			"children": null
		}, {
			"id": "411103",
			"name": "郾城区",
			"parent": "411100",
			"children": null
		}, {
			"id": "411104",
			"name": "召陵区",
			"parent": "411100",
			"children": null
		}, {
			"id": "411121",
			"name": "舞阳县",
			"parent": "411100",
			"children": null
		}, {
			"id": "411122",
			"name": "临颍县",
			"parent": "411100",
			"children": null
		}, {
			"id": "411123",
			"name": "其它区",
			"parent": "411100",
			"children": null
		}]
	}, {
		"id": "411200",
		"name": "三门峡市",
		"parent": "410000",
		"children": [{
			"id": "411202",
			"name": "湖滨区",
			"parent": "411200",
			"children": null
		}, {
			"id": "411221",
			"name": "渑池县",
			"parent": "411200",
			"children": null
		}, {
			"id": "411222",
			"name": "陕州区",
			"parent": "411200",
			"children": null
		}, {
			"id": "411224",
			"name": "卢氏县",
			"parent": "411200",
			"children": null
		}, {
			"id": "411281",
			"name": "义马市",
			"parent": "411200",
			"children": null
		}, {
			"id": "411282",
			"name": "灵宝市",
			"parent": "411200",
			"children": null
		}, {
			"id": "411283",
			"name": "其它区",
			"parent": "411200",
			"children": null
		}]
	}, {
		"id": "411300",
		"name": "南阳市",
		"parent": "410000",
		"children": [{
			"id": "411302",
			"name": "宛城区",
			"parent": "411300",
			"children": null
		}, {
			"id": "411303",
			"name": "卧龙区",
			"parent": "411300",
			"children": null
		}, {
			"id": "411321",
			"name": "南召县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411322",
			"name": "方城县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411323",
			"name": "西峡县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411324",
			"name": "镇平县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411325",
			"name": "内乡县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411326",
			"name": "淅川县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411327",
			"name": "社旗县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411328",
			"name": "唐河县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411329",
			"name": "新野县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411330",
			"name": "桐柏县",
			"parent": "411300",
			"children": null
		}, {
			"id": "411381",
			"name": "邓州市",
			"parent": "411300",
			"children": null
		}, {
			"id": "411382",
			"name": "其它区",
			"parent": "411300",
			"children": null
		}]
	}, {
		"id": "411400",
		"name": "商丘市",
		"parent": "410000",
		"children": [{
			"id": "411402",
			"name": "梁园区",
			"parent": "411400",
			"children": null
		}, {
			"id": "411403",
			"name": "睢阳区",
			"parent": "411400",
			"children": null
		}, {
			"id": "411421",
			"name": "民权县",
			"parent": "411400",
			"children": null
		}, {
			"id": "411422",
			"name": "睢县",
			"parent": "411400",
			"children": null
		}, {
			"id": "411423",
			"name": "宁陵县",
			"parent": "411400",
			"children": null
		}, {
			"id": "411424",
			"name": "柘城县",
			"parent": "411400",
			"children": null
		}, {
			"id": "411425",
			"name": "虞城县",
			"parent": "411400",
			"children": null
		}, {
			"id": "411426",
			"name": "夏邑县",
			"parent": "411400",
			"children": null
		}, {
			"id": "411481",
			"name": "永城市",
			"parent": "411400",
			"children": null
		}, {
			"id": "411482",
			"name": "其它区",
			"parent": "411400",
			"children": null
		}]
	}, {
		"id": "411500",
		"name": "信阳市",
		"parent": "410000",
		"children": [{
			"id": "411502",
			"name": "浉河区",
			"parent": "411500",
			"children": null
		}, {
			"id": "411503",
			"name": "平桥区",
			"parent": "411500",
			"children": null
		}, {
			"id": "411521",
			"name": "罗山县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411522",
			"name": "光山县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411523",
			"name": "新县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411524",
			"name": "商城县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411525",
			"name": "固始县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411526",
			"name": "潢川县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411527",
			"name": "淮滨县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411528",
			"name": "息县",
			"parent": "411500",
			"children": null
		}, {
			"id": "411529",
			"name": "其它区",
			"parent": "411500",
			"children": null
		}]
	}, {
		"id": "411600",
		"name": "周口市",
		"parent": "410000",
		"children": [{
			"id": "411602",
			"name": "川汇区",
			"parent": "411600",
			"children": null
		}, {
			"id": "411621",
			"name": "扶沟县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411622",
			"name": "西华县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411623",
			"name": "商水县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411624",
			"name": "沈丘县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411625",
			"name": "郸城县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411626",
			"name": "淮阳县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411627",
			"name": "太康县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411628",
			"name": "鹿邑县",
			"parent": "411600",
			"children": null
		}, {
			"id": "411681",
			"name": "项城市",
			"parent": "411600",
			"children": null
		}, {
			"id": "411682",
			"name": "其它区",
			"parent": "411600",
			"children": null
		}]
	}, {
		"id": "411700",
		"name": "驻马店市",
		"parent": "410000",
		"children": [{
			"id": "411702",
			"name": "驿城区",
			"parent": "411700",
			"children": null
		}, {
			"id": "411721",
			"name": "西平县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411722",
			"name": "上蔡县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411723",
			"name": "平舆县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411724",
			"name": "正阳县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411725",
			"name": "确山县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411726",
			"name": "泌阳县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411727",
			"name": "汝南县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411728",
			"name": "遂平县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411729",
			"name": "新蔡县",
			"parent": "411700",
			"children": null
		}, {
			"id": "411730",
			"name": "其它区",
			"parent": "411700",
			"children": null
		}]
	}, {
		"id": "410881",
		"name": "济源市",
		"parent": "410000",
		"children": null
	}]
}, {
	"id": "420000",
	"name": "湖北省",
	"children": [{
		"id": "420100",
		"name": "武汉市",
		"parent": "420000",
		"children": [{
			"id": "420102",
			"name": "江岸区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420103",
			"name": "江汉区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420104",
			"name": "硚口区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420105",
			"name": "汉阳区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420106",
			"name": "武昌区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420107",
			"name": "青山区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420111",
			"name": "洪山区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420112",
			"name": "东西湖区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420113",
			"name": "汉南区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420114",
			"name": "蔡甸区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420115",
			"name": "江夏区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420116",
			"name": "黄陂区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420117",
			"name": "新洲区",
			"parent": "420100",
			"children": null
		}, {
			"id": "420118",
			"name": "其它区",
			"parent": "420100",
			"children": null
		}]
	}, {
		"id": "420200",
		"name": "黄石市",
		"parent": "420000",
		"children": [{
			"id": "420202",
			"name": "黄石港区",
			"parent": "420200",
			"children": null
		}, {
			"id": "420203",
			"name": "西塞山区",
			"parent": "420200",
			"children": null
		}, {
			"id": "420204",
			"name": "下陆区",
			"parent": "420200",
			"children": null
		}, {
			"id": "420205",
			"name": "铁山区",
			"parent": "420200",
			"children": null
		}, {
			"id": "420222",
			"name": "阳新县",
			"parent": "420200",
			"children": null
		}, {
			"id": "420281",
			"name": "大冶市",
			"parent": "420200",
			"children": null
		}, {
			"id": "420282",
			"name": "其它区",
			"parent": "420200",
			"children": null
		}]
	}, {
		"id": "420300",
		"name": "十堰市",
		"parent": "420000",
		"children": [{
			"id": "420302",
			"name": "茅箭区",
			"parent": "420300",
			"children": null
		}, {
			"id": "420303",
			"name": "张湾区",
			"parent": "420300",
			"children": null
		}, {
			"id": "420321",
			"name": "郧阳区",
			"parent": "420300",
			"children": null
		}, {
			"id": "420322",
			"name": "郧西县",
			"parent": "420300",
			"children": null
		}, {
			"id": "420323",
			"name": "竹山县",
			"parent": "420300",
			"children": null
		}, {
			"id": "420324",
			"name": "竹溪县",
			"parent": "420300",
			"children": null
		}, {
			"id": "420325",
			"name": "房县",
			"parent": "420300",
			"children": null
		}, {
			"id": "420381",
			"name": "丹江口市",
			"parent": "420300",
			"children": null
		}, {
			"id": "420382",
			"name": "城区",
			"parent": "420300",
			"children": null
		}, {
			"id": "420383",
			"name": "其它区",
			"parent": "420300",
			"children": null
		}]
	}, {
		"id": "420500",
		"name": "宜昌市",
		"parent": "420000",
		"children": [{
			"id": "420502",
			"name": "西陵区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420503",
			"name": "伍家岗区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420504",
			"name": "点军区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420505",
			"name": "猇亭区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420506",
			"name": "夷陵区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420525",
			"name": "远安县",
			"parent": "420500",
			"children": null
		}, {
			"id": "420526",
			"name": "兴山县",
			"parent": "420500",
			"children": null
		}, {
			"id": "420527",
			"name": "秭归县",
			"parent": "420500",
			"children": null
		}, {
			"id": "420528",
			"name": "长阳土家族自治县",
			"parent": "420500",
			"children": null
		}, {
			"id": "420529",
			"name": "五峰土家族自治县",
			"parent": "420500",
			"children": null
		}, {
			"id": "420551",
			"name": "葛洲坝区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420552",
			"name": "开发区",
			"parent": "420500",
			"children": null
		}, {
			"id": "420581",
			"name": "宜都市",
			"parent": "420500",
			"children": null
		}, {
			"id": "420582",
			"name": "当阳市",
			"parent": "420500",
			"children": null
		}, {
			"id": "420583",
			"name": "枝江市",
			"parent": "420500",
			"children": null
		}, {
			"id": "420584",
			"name": "其它区",
			"parent": "420500",
			"children": null
		}]
	}, {
		"id": "420600",
		"name": "襄阳市",
		"parent": "420000",
		"children": [{
			"id": "420602",
			"name": "襄城区",
			"parent": "420600",
			"children": null
		}, {
			"id": "420606",
			"name": "樊城区",
			"parent": "420600",
			"children": null
		}, {
			"id": "420607",
			"name": "襄州区",
			"parent": "420600",
			"children": null
		}, {
			"id": "420624",
			"name": "南漳县",
			"parent": "420600",
			"children": null
		}, {
			"id": "420625",
			"name": "谷城县",
			"parent": "420600",
			"children": null
		}, {
			"id": "420626",
			"name": "保康县",
			"parent": "420600",
			"children": null
		}, {
			"id": "420682",
			"name": "老河口市",
			"parent": "420600",
			"children": null
		}, {
			"id": "420683",
			"name": "枣阳市",
			"parent": "420600",
			"children": null
		}, {
			"id": "420684",
			"name": "宜城市",
			"parent": "420600",
			"children": null
		}, {
			"id": "420685",
			"name": "其它区",
			"parent": "420600",
			"children": null
		}]
	}, {
		"id": "420700",
		"name": "鄂州市",
		"parent": "420000",
		"children": [{
			"id": "420702",
			"name": "梁子湖区",
			"parent": "420700",
			"children": null
		}, {
			"id": "420703",
			"name": "华容区",
			"parent": "420700",
			"children": null
		}, {
			"id": "420704",
			"name": "鄂城区",
			"parent": "420700",
			"children": null
		}, {
			"id": "420705",
			"name": "其它区",
			"parent": "420700",
			"children": null
		}]
	}, {
		"id": "420800",
		"name": "荆门市",
		"parent": "420000",
		"children": [{
			"id": "420802",
			"name": "东宝区",
			"parent": "420800",
			"children": null
		}, {
			"id": "420804",
			"name": "掇刀区",
			"parent": "420800",
			"children": null
		}, {
			"id": "420821",
			"name": "京山县",
			"parent": "420800",
			"children": null
		}, {
			"id": "420822",
			"name": "沙洋县",
			"parent": "420800",
			"children": null
		}, {
			"id": "420881",
			"name": "钟祥市",
			"parent": "420800",
			"children": null
		}, {
			"id": "420882",
			"name": "其它区",
			"parent": "420800",
			"children": null
		}]
	}, {
		"id": "420900",
		"name": "孝感市",
		"parent": "420000",
		"children": [{
			"id": "420902",
			"name": "孝南区",
			"parent": "420900",
			"children": null
		}, {
			"id": "420921",
			"name": "孝昌县",
			"parent": "420900",
			"children": null
		}, {
			"id": "420922",
			"name": "大悟县",
			"parent": "420900",
			"children": null
		}, {
			"id": "420923",
			"name": "云梦县",
			"parent": "420900",
			"children": null
		}, {
			"id": "420981",
			"name": "应城市",
			"parent": "420900",
			"children": null
		}, {
			"id": "420982",
			"name": "安陆市",
			"parent": "420900",
			"children": null
		}, {
			"id": "420984",
			"name": "汉川市",
			"parent": "420900",
			"children": null
		}, {
			"id": "420985",
			"name": "其它区",
			"parent": "420900",
			"children": null
		}]
	}, {
		"id": "421000",
		"name": "荆州市",
		"parent": "420000",
		"children": [{
			"id": "421002",
			"name": "沙市区",
			"parent": "421000",
			"children": null
		}, {
			"id": "421003",
			"name": "荆州区",
			"parent": "421000",
			"children": null
		}, {
			"id": "421022",
			"name": "公安县",
			"parent": "421000",
			"children": null
		}, {
			"id": "421023",
			"name": "监利县",
			"parent": "421000",
			"children": null
		}, {
			"id": "421024",
			"name": "江陵县",
			"parent": "421000",
			"children": null
		}, {
			"id": "421081",
			"name": "石首市",
			"parent": "421000",
			"children": null
		}, {
			"id": "421083",
			"name": "洪湖市",
			"parent": "421000",
			"children": null
		}, {
			"id": "421087",
			"name": "松滋市",
			"parent": "421000",
			"children": null
		}, {
			"id": "421088",
			"name": "其它区",
			"parent": "421000",
			"children": null
		}]
	}, {
		"id": "421100",
		"name": "黄冈市",
		"parent": "420000",
		"children": [{
			"id": "421102",
			"name": "黄州区",
			"parent": "421100",
			"children": null
		}, {
			"id": "421121",
			"name": "团风县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421122",
			"name": "红安县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421123",
			"name": "罗田县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421124",
			"name": "英山县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421125",
			"name": "浠水县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421126",
			"name": "蕲春县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421127",
			"name": "黄梅县",
			"parent": "421100",
			"children": null
		}, {
			"id": "421181",
			"name": "麻城市",
			"parent": "421100",
			"children": null
		}, {
			"id": "421182",
			"name": "武穴市",
			"parent": "421100",
			"children": null
		}, {
			"id": "421183",
			"name": "其它区",
			"parent": "421100",
			"children": null
		}]
	}, {
		"id": "421200",
		"name": "咸宁市",
		"parent": "420000",
		"children": [{
			"id": "421202",
			"name": "咸安区",
			"parent": "421200",
			"children": null
		}, {
			"id": "421221",
			"name": "嘉鱼县",
			"parent": "421200",
			"children": null
		}, {
			"id": "421222",
			"name": "通城县",
			"parent": "421200",
			"children": null
		}, {
			"id": "421223",
			"name": "崇阳县",
			"parent": "421200",
			"children": null
		}, {
			"id": "421224",
			"name": "通山县",
			"parent": "421200",
			"children": null
		}, {
			"id": "421281",
			"name": "赤壁市",
			"parent": "421200",
			"children": null
		}, {
			"id": "421282",
			"name": "温泉城区",
			"parent": "421200",
			"children": null
		}, {
			"id": "421283",
			"name": "其它区",
			"parent": "421200",
			"children": null
		}]
	}, {
		"id": "421300",
		"name": "随州市",
		"parent": "420000",
		"children": [{
			"id": "421302",
			"name": "曾都区",
			"parent": "421300",
			"children": null
		}, {
			"id": "421321",
			"name": "随县",
			"parent": "421300",
			"children": null
		}, {
			"id": "421381",
			"name": "广水市",
			"parent": "421300",
			"children": null
		}, {
			"id": "421382",
			"name": "其它区",
			"parent": "421300",
			"children": null
		}]
	}, {
		"id": "422800",
		"name": "恩施土家族苗族自治州",
		"parent": "420000",
		"children": [{
			"id": "422801",
			"name": "恩施市",
			"parent": "422800",
			"children": null
		}, {
			"id": "422802",
			"name": "利川市",
			"parent": "422800",
			"children": null
		}, {
			"id": "422822",
			"name": "建始县",
			"parent": "422800",
			"children": null
		}, {
			"id": "422823",
			"name": "巴东县",
			"parent": "422800",
			"children": null
		}, {
			"id": "422825",
			"name": "宣恩县",
			"parent": "422800",
			"children": null
		}, {
			"id": "422826",
			"name": "咸丰县",
			"parent": "422800",
			"children": null
		}, {
			"id": "422827",
			"name": "来凤县",
			"parent": "422800",
			"children": null
		}, {
			"id": "422828",
			"name": "鹤峰县",
			"parent": "422800",
			"children": null
		}, {
			"id": "422829",
			"name": "其它区",
			"parent": "422800",
			"children": null
		}]
	}, {
		"id": "429004",
		"name": "仙桃市",
		"parent": "420000",
		"children": null
	}, {
		"id": "429005",
		"name": "潜江市",
		"parent": "420000",
		"children": null
	}, {
		"id": "429006",
		"name": "天门市",
		"parent": "420000",
		"children": null
	}, {
		"id": "429021",
		"name": "神农架林区",
		"parent": "420000",
		"children": null
	}]
}, {
	"id": "430000",
	"name": "湖南省",
	"children": [{
		"id": "430100",
		"name": "长沙市",
		"parent": "430000",
		"children": [{
			"id": "430102",
			"name": "芙蓉区",
			"parent": "430100",
			"children": null
		}, {
			"id": "430103",
			"name": "天心区",
			"parent": "430100",
			"children": null
		}, {
			"id": "430104",
			"name": "岳麓区",
			"parent": "430100",
			"children": null
		}, {
			"id": "430105",
			"name": "开福区",
			"parent": "430100",
			"children": null
		}, {
			"id": "430111",
			"name": "雨花区",
			"parent": "430100",
			"children": null
		}, {
			"id": "430121",
			"name": "长沙县",
			"parent": "430100",
			"children": null
		}, {
			"id": "430122",
			"name": "望城区",
			"parent": "430100",
			"children": null
		}, {
			"id": "430124",
			"name": "宁乡县",
			"parent": "430100",
			"children": null
		}, {
			"id": "430181",
			"name": "浏阳市",
			"parent": "430100",
			"children": null
		}, {
			"id": "430182",
			"name": "其它区",
			"parent": "430100",
			"children": null
		}]
	}, {
		"id": "430200",
		"name": "株洲市",
		"parent": "430000",
		"children": [{
			"id": "430202",
			"name": "荷塘区",
			"parent": "430200",
			"children": null
		}, {
			"id": "430203",
			"name": "芦淞区",
			"parent": "430200",
			"children": null
		}, {
			"id": "430204",
			"name": "石峰区",
			"parent": "430200",
			"children": null
		}, {
			"id": "430211",
			"name": "天元区",
			"parent": "430200",
			"children": null
		}, {
			"id": "430221",
			"name": "株洲县",
			"parent": "430200",
			"children": null
		}, {
			"id": "430223",
			"name": "攸县",
			"parent": "430200",
			"children": null
		}, {
			"id": "430224",
			"name": "茶陵县",
			"parent": "430200",
			"children": null
		}, {
			"id": "430225",
			"name": "炎陵县",
			"parent": "430200",
			"children": null
		}, {
			"id": "430281",
			"name": "醴陵市",
			"parent": "430200",
			"children": null
		}, {
			"id": "430282",
			"name": "其它区",
			"parent": "430200",
			"children": null
		}]
	}, {
		"id": "430300",
		"name": "湘潭市",
		"parent": "430000",
		"children": [{
			"id": "430302",
			"name": "雨湖区",
			"parent": "430300",
			"children": null
		}, {
			"id": "430304",
			"name": "岳塘区",
			"parent": "430300",
			"children": null
		}, {
			"id": "430321",
			"name": "湘潭县",
			"parent": "430300",
			"children": null
		}, {
			"id": "430381",
			"name": "湘乡市",
			"parent": "430300",
			"children": null
		}, {
			"id": "430382",
			"name": "韶山市",
			"parent": "430300",
			"children": null
		}, {
			"id": "430383",
			"name": "其它区",
			"parent": "430300",
			"children": null
		}]
	}, {
		"id": "430400",
		"name": "衡阳市",
		"parent": "430000",
		"children": [{
			"id": "430405",
			"name": "珠晖区",
			"parent": "430400",
			"children": null
		}, {
			"id": "430406",
			"name": "雁峰区",
			"parent": "430400",
			"children": null
		}, {
			"id": "430407",
			"name": "石鼓区",
			"parent": "430400",
			"children": null
		}, {
			"id": "430408",
			"name": "蒸湘区",
			"parent": "430400",
			"children": null
		}, {
			"id": "430412",
			"name": "南岳区",
			"parent": "430400",
			"children": null
		}, {
			"id": "430421",
			"name": "衡阳县",
			"parent": "430400",
			"children": null
		}, {
			"id": "430422",
			"name": "衡南县",
			"parent": "430400",
			"children": null
		}, {
			"id": "430423",
			"name": "衡山县",
			"parent": "430400",
			"children": null
		}, {
			"id": "430424",
			"name": "衡东县",
			"parent": "430400",
			"children": null
		}, {
			"id": "430426",
			"name": "祁东县",
			"parent": "430400",
			"children": null
		}, {
			"id": "430481",
			"name": "耒阳市",
			"parent": "430400",
			"children": null
		}, {
			"id": "430482",
			"name": "常宁市",
			"parent": "430400",
			"children": null
		}, {
			"id": "430483",
			"name": "其它区",
			"parent": "430400",
			"children": null
		}]
	}, {
		"id": "430500",
		"name": "邵阳市",
		"parent": "430000",
		"children": [{
			"id": "430502",
			"name": "双清区",
			"parent": "430500",
			"children": null
		}, {
			"id": "430503",
			"name": "大祥区",
			"parent": "430500",
			"children": null
		}, {
			"id": "430511",
			"name": "北塔区",
			"parent": "430500",
			"children": null
		}, {
			"id": "430521",
			"name": "邵东县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430522",
			"name": "新邵县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430523",
			"name": "邵阳县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430524",
			"name": "隆回县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430525",
			"name": "洞口县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430527",
			"name": "绥宁县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430528",
			"name": "新宁县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430529",
			"name": "城步苗族自治县",
			"parent": "430500",
			"children": null
		}, {
			"id": "430581",
			"name": "武冈市",
			"parent": "430500",
			"children": null
		}, {
			"id": "430582",
			"name": "其它区",
			"parent": "430500",
			"children": null
		}]
	}, {
		"id": "430600",
		"name": "岳阳市",
		"parent": "430000",
		"children": [{
			"id": "430602",
			"name": "岳阳楼区",
			"parent": "430600",
			"children": null
		}, {
			"id": "430603",
			"name": "云溪区",
			"parent": "430600",
			"children": null
		}, {
			"id": "430611",
			"name": "君山区",
			"parent": "430600",
			"children": null
		}, {
			"id": "430621",
			"name": "岳阳县",
			"parent": "430600",
			"children": null
		}, {
			"id": "430623",
			"name": "华容县",
			"parent": "430600",
			"children": null
		}, {
			"id": "430624",
			"name": "湘阴县",
			"parent": "430600",
			"children": null
		}, {
			"id": "430626",
			"name": "平江县",
			"parent": "430600",
			"children": null
		}, {
			"id": "430681",
			"name": "汨罗市",
			"parent": "430600",
			"children": null
		}, {
			"id": "430682",
			"name": "临湘市",
			"parent": "430600",
			"children": null
		}, {
			"id": "430683",
			"name": "其它区",
			"parent": "430600",
			"children": null
		}]
	}, {
		"id": "430700",
		"name": "常德市",
		"parent": "430000",
		"children": [{
			"id": "430702",
			"name": "武陵区",
			"parent": "430700",
			"children": null
		}, {
			"id": "430703",
			"name": "鼎城区",
			"parent": "430700",
			"children": null
		}, {
			"id": "430721",
			"name": "安乡县",
			"parent": "430700",
			"children": null
		}, {
			"id": "430722",
			"name": "汉寿县",
			"parent": "430700",
			"children": null
		}, {
			"id": "430723",
			"name": "澧县",
			"parent": "430700",
			"children": null
		}, {
			"id": "430724",
			"name": "临澧县",
			"parent": "430700",
			"children": null
		}, {
			"id": "430725",
			"name": "桃源县",
			"parent": "430700",
			"children": null
		}, {
			"id": "430726",
			"name": "石门县",
			"parent": "430700",
			"children": null
		}, {
			"id": "430781",
			"name": "津市市",
			"parent": "430700",
			"children": null
		}, {
			"id": "430782",
			"name": "其它区",
			"parent": "430700",
			"children": null
		}]
	}, {
		"id": "430800",
		"name": "张家界市",
		"parent": "430000",
		"children": [{
			"id": "430802",
			"name": "永定区",
			"parent": "430800",
			"children": null
		}, {
			"id": "430811",
			"name": "武陵源区",
			"parent": "430800",
			"children": null
		}, {
			"id": "430821",
			"name": "慈利县",
			"parent": "430800",
			"children": null
		}, {
			"id": "430822",
			"name": "桑植县",
			"parent": "430800",
			"children": null
		}, {
			"id": "430823",
			"name": "其它区",
			"parent": "430800",
			"children": null
		}]
	}, {
		"id": "430900",
		"name": "益阳市",
		"parent": "430000",
		"children": [{
			"id": "430902",
			"name": "资阳区",
			"parent": "430900",
			"children": null
		}, {
			"id": "430903",
			"name": "赫山区",
			"parent": "430900",
			"children": null
		}, {
			"id": "430921",
			"name": "南县",
			"parent": "430900",
			"children": null
		}, {
			"id": "430922",
			"name": "桃江县",
			"parent": "430900",
			"children": null
		}, {
			"id": "430923",
			"name": "安化县",
			"parent": "430900",
			"children": null
		}, {
			"id": "430981",
			"name": "沅江市",
			"parent": "430900",
			"children": null
		}, {
			"id": "430982",
			"name": "其它区",
			"parent": "430900",
			"children": null
		}]
	}, {
		"id": "431000",
		"name": "郴州市",
		"parent": "430000",
		"children": [{
			"id": "431002",
			"name": "北湖区",
			"parent": "431000",
			"children": null
		}, {
			"id": "431003",
			"name": "苏仙区",
			"parent": "431000",
			"children": null
		}, {
			"id": "431021",
			"name": "桂阳县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431022",
			"name": "宜章县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431023",
			"name": "永兴县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431024",
			"name": "嘉禾县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431025",
			"name": "临武县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431026",
			"name": "汝城县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431027",
			"name": "桂东县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431028",
			"name": "安仁县",
			"parent": "431000",
			"children": null
		}, {
			"id": "431081",
			"name": "资兴市",
			"parent": "431000",
			"children": null
		}, {
			"id": "431082",
			"name": "其它区",
			"parent": "431000",
			"children": null
		}]
	}, {
		"id": "431100",
		"name": "永州市",
		"parent": "430000",
		"children": [{
			"id": "431102",
			"name": "零陵区",
			"parent": "431100",
			"children": null
		}, {
			"id": "431103",
			"name": "冷水滩区",
			"parent": "431100",
			"children": null
		}, {
			"id": "431121",
			"name": "祁阳县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431122",
			"name": "东安县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431123",
			"name": "双牌县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431124",
			"name": "道县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431125",
			"name": "江永县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431126",
			"name": "宁远县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431127",
			"name": "蓝山县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431128",
			"name": "新田县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431129",
			"name": "江华瑶族自治县",
			"parent": "431100",
			"children": null
		}, {
			"id": "431130",
			"name": "其它区",
			"parent": "431100",
			"children": null
		}]
	}, {
		"id": "431200",
		"name": "怀化市",
		"parent": "430000",
		"children": [{
			"id": "431202",
			"name": "鹤城区",
			"parent": "431200",
			"children": null
		}, {
			"id": "431221",
			"name": "中方县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431222",
			"name": "沅陵县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431223",
			"name": "辰溪县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431224",
			"name": "溆浦县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431225",
			"name": "会同县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431226",
			"name": "麻阳苗族自治县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431227",
			"name": "新晃侗族自治县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431228",
			"name": "芷江侗族自治县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431229",
			"name": "靖州苗族侗族自治县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431230",
			"name": "通道侗族自治县",
			"parent": "431200",
			"children": null
		}, {
			"id": "431281",
			"name": "洪江市",
			"parent": "431200",
			"children": null
		}, {
			"id": "431282",
			"name": "其它区",
			"parent": "431200",
			"children": null
		}]
	}, {
		"id": "431300",
		"name": "娄底市",
		"parent": "430000",
		"children": [{
			"id": "431302",
			"name": "娄星区",
			"parent": "431300",
			"children": null
		}, {
			"id": "431321",
			"name": "双峰县",
			"parent": "431300",
			"children": null
		}, {
			"id": "431322",
			"name": "新化县",
			"parent": "431300",
			"children": null
		}, {
			"id": "431381",
			"name": "冷水江市",
			"parent": "431300",
			"children": null
		}, {
			"id": "431382",
			"name": "涟源市",
			"parent": "431300",
			"children": null
		}, {
			"id": "431383",
			"name": "其它区",
			"parent": "431300",
			"children": null
		}]
	}, {
		"id": "433100",
		"name": "湘西土家族苗族自治州",
		"parent": "430000",
		"children": [{
			"id": "433101",
			"name": "吉首市",
			"parent": "433100",
			"children": null
		}, {
			"id": "433122",
			"name": "泸溪县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433123",
			"name": "凤凰县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433124",
			"name": "花垣县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433125",
			"name": "保靖县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433126",
			"name": "古丈县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433127",
			"name": "永顺县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433130",
			"name": "龙山县",
			"parent": "433100",
			"children": null
		}, {
			"id": "433131",
			"name": "其它区",
			"parent": "433100",
			"children": null
		}]
	}]
}, {
	"id": "440000",
	"name": "广东省",
	"children": [{
		"id": "440100",
		"name": "广州市",
		"parent": "440000",
		"children": [{
			"id": "440103",
			"name": "荔湾区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440104",
			"name": "越秀区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440105",
			"name": "海珠区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440106",
			"name": "天河区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440111",
			"name": "白云区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440112",
			"name": "黄埔区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440113",
			"name": "番禺区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440114",
			"name": "花都区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440115",
			"name": "南沙区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440116",
			"name": "萝岗区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440183",
			"name": "增城区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440184",
			"name": "从化区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440188",
			"name": "东山区",
			"parent": "440100",
			"children": null
		}, {
			"id": "440189",
			"name": "其它区",
			"parent": "440100",
			"children": null
		}]
	}, {
		"id": "440200",
		"name": "韶关市",
		"parent": "440000",
		"children": [{
			"id": "440203",
			"name": "武江区",
			"parent": "440200",
			"children": null
		}, {
			"id": "440204",
			"name": "浈江区",
			"parent": "440200",
			"children": null
		}, {
			"id": "440205",
			"name": "曲江区",
			"parent": "440200",
			"children": null
		}, {
			"id": "440222",
			"name": "始兴县",
			"parent": "440200",
			"children": null
		}, {
			"id": "440224",
			"name": "仁化县",
			"parent": "440200",
			"children": null
		}, {
			"id": "440229",
			"name": "翁源县",
			"parent": "440200",
			"children": null
		}, {
			"id": "440232",
			"name": "乳源瑶族自治县",
			"parent": "440200",
			"children": null
		}, {
			"id": "440233",
			"name": "新丰县",
			"parent": "440200",
			"children": null
		}, {
			"id": "440281",
			"name": "乐昌市",
			"parent": "440200",
			"children": null
		}, {
			"id": "440282",
			"name": "南雄市",
			"parent": "440200",
			"children": null
		}, {
			"id": "440283",
			"name": "其它区",
			"parent": "440200",
			"children": null
		}]
	}, {
		"id": "440300",
		"name": "深圳市",
		"parent": "440000",
		"children": [{
			"id": "440303",
			"name": "罗湖区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440304",
			"name": "福田区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440305",
			"name": "南山区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440306",
			"name": "宝安区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440307",
			"name": "龙岗区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440308",
			"name": "盐田区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440309",
			"name": "其它区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440320",
			"name": "光明新区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440321",
			"name": "坪山新区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440322",
			"name": "大鹏新区",
			"parent": "440300",
			"children": null
		}, {
			"id": "440323",
			"name": "龙华新区",
			"parent": "440300",
			"children": null
		}]
	}, {
		"id": "440400",
		"name": "珠海市",
		"parent": "440000",
		"children": [{
			"id": "440402",
			"name": "香洲区",
			"parent": "440400",
			"children": null
		}, {
			"id": "440403",
			"name": "斗门区",
			"parent": "440400",
			"children": null
		}, {
			"id": "440404",
			"name": "金湾区",
			"parent": "440400",
			"children": null
		}, {
			"id": "440486",
			"name": "金唐区",
			"parent": "440400",
			"children": null
		}, {
			"id": "440487",
			"name": "南湾区",
			"parent": "440400",
			"children": null
		}, {
			"id": "440488",
			"name": "其它区",
			"parent": "440400",
			"children": null
		}]
	}, {
		"id": "440500",
		"name": "汕头市",
		"parent": "440000",
		"children": [{
			"id": "440507",
			"name": "龙湖区",
			"parent": "440500",
			"children": null
		}, {
			"id": "440511",
			"name": "金平区",
			"parent": "440500",
			"children": null
		}, {
			"id": "440512",
			"name": "濠江区",
			"parent": "440500",
			"children": null
		}, {
			"id": "440513",
			"name": "潮阳区",
			"parent": "440500",
			"children": null
		}, {
			"id": "440514",
			"name": "潮南区",
			"parent": "440500",
			"children": null
		}, {
			"id": "440515",
			"name": "澄海区",
			"parent": "440500",
			"children": null
		}, {
			"id": "440523",
			"name": "南澳县",
			"parent": "440500",
			"children": null
		}, {
			"id": "440524",
			"name": "其它区",
			"parent": "440500",
			"children": null
		}]
	}, {
		"id": "440600",
		"name": "佛山市",
		"parent": "440000",
		"children": [{
			"id": "440604",
			"name": "禅城区",
			"parent": "440600",
			"children": null
		}, {
			"id": "440605",
			"name": "南海区",
			"parent": "440600",
			"children": null
		}, {
			"id": "440606",
			"name": "顺德区",
			"parent": "440600",
			"children": null
		}, {
			"id": "440607",
			"name": "三水区",
			"parent": "440600",
			"children": null
		}, {
			"id": "440608",
			"name": "高明区",
			"parent": "440600",
			"children": null
		}, {
			"id": "440609",
			"name": "其它区",
			"parent": "440600",
			"children": null
		}]
	}, {
		"id": "440700",
		"name": "江门市",
		"parent": "440000",
		"children": [{
			"id": "440703",
			"name": "蓬江区",
			"parent": "440700",
			"children": null
		}, {
			"id": "440704",
			"name": "江海区",
			"parent": "440700",
			"children": null
		}, {
			"id": "440705",
			"name": "新会区",
			"parent": "440700",
			"children": null
		}, {
			"id": "440781",
			"name": "台山市",
			"parent": "440700",
			"children": null
		}, {
			"id": "440783",
			"name": "开平市",
			"parent": "440700",
			"children": null
		}, {
			"id": "440784",
			"name": "鹤山市",
			"parent": "440700",
			"children": null
		}, {
			"id": "440785",
			"name": "恩平市",
			"parent": "440700",
			"children": null
		}, {
			"id": "440786",
			"name": "其它区",
			"parent": "440700",
			"children": null
		}]
	}, {
		"id": "440800",
		"name": "湛江市",
		"parent": "440000",
		"children": [{
			"id": "440802",
			"name": "赤坎区",
			"parent": "440800",
			"children": null
		}, {
			"id": "440803",
			"name": "霞山区",
			"parent": "440800",
			"children": null
		}, {
			"id": "440804",
			"name": "坡头区",
			"parent": "440800",
			"children": null
		}, {
			"id": "440811",
			"name": "麻章区",
			"parent": "440800",
			"children": null
		}, {
			"id": "440823",
			"name": "遂溪县",
			"parent": "440800",
			"children": null
		}, {
			"id": "440825",
			"name": "徐闻县",
			"parent": "440800",
			"children": null
		}, {
			"id": "440881",
			"name": "廉江市",
			"parent": "440800",
			"children": null
		}, {
			"id": "440882",
			"name": "雷州市",
			"parent": "440800",
			"children": null
		}, {
			"id": "440883",
			"name": "吴川市",
			"parent": "440800",
			"children": null
		}, {
			"id": "440884",
			"name": "其它区",
			"parent": "440800",
			"children": null
		}]
	}, {
		"id": "440900",
		"name": "茂名市",
		"parent": "440000",
		"children": [{
			"id": "440902",
			"name": "茂南区",
			"parent": "440900",
			"children": null
		}, {
			"id": "440903",
			"name": "电白区",
			"parent": "440900",
			"children": null
		}, {
			"id": "440923",
			"name": "电白县",
			"parent": "440900",
			"children": null
		}, {
			"id": "440981",
			"name": "高州市",
			"parent": "440900",
			"children": null
		}, {
			"id": "440982",
			"name": "化州市",
			"parent": "440900",
			"children": null
		}, {
			"id": "440983",
			"name": "信宜市",
			"parent": "440900",
			"children": null
		}, {
			"id": "440984",
			"name": "其它区",
			"parent": "440900",
			"children": null
		}]
	}, {
		"id": "441200",
		"name": "肇庆市",
		"parent": "440000",
		"children": [{
			"id": "441202",
			"name": "端州区",
			"parent": "441200",
			"children": null
		}, {
			"id": "441203",
			"name": "鼎湖区",
			"parent": "441200",
			"children": null
		}, {
			"id": "441223",
			"name": "广宁县",
			"parent": "441200",
			"children": null
		}, {
			"id": "441224",
			"name": "怀集县",
			"parent": "441200",
			"children": null
		}, {
			"id": "441225",
			"name": "封开县",
			"parent": "441200",
			"children": null
		}, {
			"id": "441226",
			"name": "德庆县",
			"parent": "441200",
			"children": null
		}, {
			"id": "441283",
			"name": "高要市",
			"parent": "441200",
			"children": null
		}, {
			"id": "441284",
			"name": "四会市",
			"parent": "441200",
			"children": null
		}, {
			"id": "441285",
			"name": "其它区",
			"parent": "441200",
			"children": null
		}]
	}, {
		"id": "441300",
		"name": "惠州市",
		"parent": "440000",
		"children": [{
			"id": "441302",
			"name": "惠城区",
			"parent": "441300",
			"children": null
		}, {
			"id": "441303",
			"name": "惠阳区",
			"parent": "441300",
			"children": null
		}, {
			"id": "441322",
			"name": "博罗县",
			"parent": "441300",
			"children": null
		}, {
			"id": "441323",
			"name": "惠东县",
			"parent": "441300",
			"children": null
		}, {
			"id": "441324",
			"name": "龙门县",
			"parent": "441300",
			"children": null
		}, {
			"id": "441325",
			"name": "其它区",
			"parent": "441300",
			"children": null
		}]
	}, {
		"id": "441400",
		"name": "梅州市",
		"parent": "440000",
		"children": [{
			"id": "441402",
			"name": "梅江区",
			"parent": "441400",
			"children": null
		}, {
			"id": "441421",
			"name": "梅县区",
			"parent": "441400",
			"children": null
		}, {
			"id": "441422",
			"name": "大埔县",
			"parent": "441400",
			"children": null
		}, {
			"id": "441423",
			"name": "丰顺县",
			"parent": "441400",
			"children": null
		}, {
			"id": "441424",
			"name": "五华县",
			"parent": "441400",
			"children": null
		}, {
			"id": "441426",
			"name": "平远县",
			"parent": "441400",
			"children": null
		}, {
			"id": "441427",
			"name": "蕉岭县",
			"parent": "441400",
			"children": null
		}, {
			"id": "441481",
			"name": "兴宁市",
			"parent": "441400",
			"children": null
		}, {
			"id": "441482",
			"name": "其它区",
			"parent": "441400",
			"children": null
		}]
	}, {
		"id": "441500",
		"name": "汕尾市",
		"parent": "440000",
		"children": [{
			"id": "441502",
			"name": "城区",
			"parent": "441500",
			"children": null
		}, {
			"id": "441521",
			"name": "海丰县",
			"parent": "441500",
			"children": null
		}, {
			"id": "441523",
			"name": "陆河县",
			"parent": "441500",
			"children": null
		}, {
			"id": "441581",
			"name": "陆丰市",
			"parent": "441500",
			"children": null
		}, {
			"id": "441582",
			"name": "其它区",
			"parent": "441500",
			"children": null
		}]
	}, {
		"id": "441600",
		"name": "河源市",
		"parent": "440000",
		"children": [{
			"id": "441602",
			"name": "源城区",
			"parent": "441600",
			"children": null
		}, {
			"id": "441621",
			"name": "紫金县",
			"parent": "441600",
			"children": null
		}, {
			"id": "441622",
			"name": "龙川县",
			"parent": "441600",
			"children": null
		}, {
			"id": "441623",
			"name": "连平县",
			"parent": "441600",
			"children": null
		}, {
			"id": "441624",
			"name": "和平县",
			"parent": "441600",
			"children": null
		}, {
			"id": "441625",
			"name": "东源县",
			"parent": "441600",
			"children": null
		}, {
			"id": "441626",
			"name": "其它区",
			"parent": "441600",
			"children": null
		}]
	}, {
		"id": "441700",
		"name": "阳江市",
		"parent": "440000",
		"children": [{
			"id": "441702",
			"name": "江城区",
			"parent": "441700",
			"children": null
		}, {
			"id": "441721",
			"name": "阳西县",
			"parent": "441700",
			"children": null
		}, {
			"id": "441723",
			"name": "阳东区",
			"parent": "441700",
			"children": null
		}, {
			"id": "441781",
			"name": "阳春市",
			"parent": "441700",
			"children": null
		}, {
			"id": "441782",
			"name": "其它区",
			"parent": "441700",
			"children": null
		}]
	}, {
		"id": "441800",
		"name": "清远市",
		"parent": "440000",
		"children": [{
			"id": "441802",
			"name": "清城区",
			"parent": "441800",
			"children": null
		}, {
			"id": "441821",
			"name": "佛冈县",
			"parent": "441800",
			"children": null
		}, {
			"id": "441823",
			"name": "阳山县",
			"parent": "441800",
			"children": null
		}, {
			"id": "441825",
			"name": "连山壮族瑶族自治县",
			"parent": "441800",
			"children": null
		}, {
			"id": "441826",
			"name": "连南瑶族自治县",
			"parent": "441800",
			"children": null
		}, {
			"id": "441827",
			"name": "清新区",
			"parent": "441800",
			"children": null
		}, {
			"id": "441881",
			"name": "英德市",
			"parent": "441800",
			"children": null
		}, {
			"id": "441882",
			"name": "连州市",
			"parent": "441800",
			"children": null
		}, {
			"id": "441883",
			"name": "其它区",
			"parent": "441800",
			"children": null
		}]
	}, {
		"id": "441900",
		"name": "东莞市",
		"parent": "440000",
		"children": [{
			"id": "441901",
			"parent": "441900",
			"name": "莞城区",
			"children": null
		}, {
			"id": "441902",
			"parent": "441900",
			"name": "南城区",
			"children": null
		}, {
			"id": "441904",
			"parent": "441900",
			"name": "万江区",
			"children": null
		}, {
			"id": "441905",
			"parent": "441900",
			"name": "石碣镇",
			"children": null
		}, {
			"id": "441906",
			"parent": "441900",
			"name": "石龙镇",
			"children": null
		}, {
			"id": "441907",
			"parent": "441900",
			"name": "茶山镇",
			"children": null
		}, {
			"id": "441908",
			"parent": "441900",
			"name": "石排镇",
			"children": null
		}, {
			"id": "441909",
			"parent": "441900",
			"name": "企石镇",
			"children": null
		}, {
			"id": "441910",
			"parent": "441900",
			"name": "横沥镇",
			"children": null
		}, {
			"id": "441911",
			"parent": "441900",
			"name": "桥头镇",
			"children": null
		}, {
			"id": "441912",
			"parent": "441900",
			"name": "谢岗镇",
			"children": null
		}, {
			"id": "441913",
			"parent": "441900",
			"name": "东坑镇",
			"children": null
		}, {
			"id": "441914",
			"parent": "441900",
			"name": "常平镇",
			"children": null
		}, {
			"id": "441915",
			"parent": "441900",
			"name": "寮步镇",
			"children": null
		}, {
			"id": "441916",
			"parent": "441900",
			"name": "大朗镇",
			"children": null
		}, {
			"id": "441917",
			"parent": "441900",
			"name": "麻涌镇",
			"children": null
		}, {
			"id": "441918",
			"parent": "441900",
			"name": "中堂镇",
			"children": null
		}, {
			"id": "441919",
			"parent": "441900",
			"name": "高埗镇",
			"children": null
		}, {
			"id": "441920",
			"parent": "441900",
			"name": "樟木头镇",
			"children": null
		}, {
			"id": "441921",
			"parent": "441900",
			"name": "大岭山镇",
			"children": null
		}, {
			"id": "441922",
			"parent": "441900",
			"name": "望牛墩镇",
			"children": null
		}, {
			"id": "441923",
			"parent": "441900",
			"name": "黄江镇",
			"children": null
		}, {
			"id": "441924",
			"parent": "441900",
			"name": "洪梅镇",
			"children": null
		}, {
			"id": "441925",
			"parent": "441900",
			"name": "清溪镇",
			"children": null
		}, {
			"id": "441926",
			"parent": "441900",
			"name": "沙田镇",
			"children": null
		}, {
			"id": "441927",
			"parent": "441900",
			"name": "道滘镇",
			"children": null
		}, {
			"id": "441928",
			"parent": "441900",
			"name": "塘厦镇",
			"children": null
		}, {
			"id": "441929",
			"parent": "441900",
			"name": "虎门镇",
			"children": null
		}, {
			"id": "441930",
			"parent": "441900",
			"name": "厚街镇",
			"children": null
		}, {
			"id": "441931",
			"parent": "441900",
			"name": "凤岗镇",
			"children": null
		}, {
			"id": "441932",
			"parent": "441900",
			"name": "长安镇",
			"children": null
		}]
	}, {
		"id": "442000",
		"name": "中山市",
		"parent": "440000",
		"children": [{
			"id": "442001",
			"parent": "442000",
			"name": "石岐区",
			"children": null
		}, {
			"id": "442004",
			"parent": "442000",
			"name": "南区",
			"children": null
		}, {
			"id": "442005",
			"parent": "442000",
			"name": "五桂山区",
			"children": null
		}, {
			"id": "442006",
			"parent": "442000",
			"name": "火炬开发区",
			"children": null
		}, {
			"id": "442007",
			"parent": "442000",
			"name": "黄圃镇",
			"children": null
		}, {
			"id": "442008",
			"parent": "442000",
			"name": "南头镇",
			"children": null
		}, {
			"id": "442009",
			"parent": "442000",
			"name": "东凤镇",
			"children": null
		}, {
			"id": "442010",
			"parent": "442000",
			"name": "阜沙镇",
			"children": null
		}, {
			"id": "442011",
			"parent": "442000",
			"name": "小榄镇",
			"children": null
		}, {
			"id": "442012",
			"parent": "442000",
			"name": "东升镇",
			"children": null
		}, {
			"id": "442013",
			"parent": "442000",
			"name": "古镇镇",
			"children": null
		}, {
			"id": "442014",
			"parent": "442000",
			"name": "横栏镇",
			"children": null
		}, {
			"id": "442015",
			"parent": "442000",
			"name": "三角镇",
			"children": null
		}, {
			"id": "442016",
			"parent": "442000",
			"name": "民众镇",
			"children": null
		}, {
			"id": "442017",
			"parent": "442000",
			"name": "南朗镇",
			"children": null
		}, {
			"id": "442018",
			"parent": "442000",
			"name": "港口镇",
			"children": null
		}, {
			"id": "442019",
			"parent": "442000",
			"name": "大涌镇",
			"children": null
		}, {
			"id": "442020",
			"parent": "442000",
			"name": "沙溪镇",
			"children": null
		}, {
			"id": "442021",
			"parent": "442000",
			"name": "三乡镇",
			"children": null
		}, {
			"id": "442022",
			"parent": "442000",
			"name": "板芙镇",
			"children": null
		}, {
			"id": "442023",
			"parent": "442000",
			"name": "神湾镇",
			"children": null
		}, {
			"id": "442024",
			"parent": "442000",
			"name": "坦洲镇",
			"children": null
		}]
	}, {
		"id": "442101",
		"name": "东沙群岛",
		"parent": "440000",
		"children": null
	}, {
		"id": "445100",
		"name": "潮州市",
		"parent": "440000",
		"children": [{
			"id": "445102",
			"name": "湘桥区",
			"parent": "445100",
			"children": null
		}, {
			"id": "445121",
			"name": "潮安区",
			"parent": "445100",
			"children": null
		}, {
			"id": "445122",
			"name": "饶平县",
			"parent": "445100",
			"children": null
		}, {
			"id": "445185",
			"name": "枫溪区",
			"parent": "445100",
			"children": null
		}, {
			"id": "445186",
			"name": "其它区",
			"parent": "445100",
			"children": null
		}]
	}, {
		"id": "445200",
		"name": "揭阳市",
		"parent": "440000",
		"children": [{
			"id": "445202",
			"name": "榕城区",
			"parent": "445200",
			"children": null
		}, {
			"id": "445221",
			"name": "揭东区",
			"parent": "445200",
			"children": null
		}, {
			"id": "445222",
			"name": "揭西县",
			"parent": "445200",
			"children": null
		}, {
			"id": "445224",
			"name": "惠来县",
			"parent": "445200",
			"children": null
		}, {
			"id": "445281",
			"name": "普宁市",
			"parent": "445200",
			"children": null
		}, {
			"id": "445284",
			"name": "东山区",
			"parent": "445200",
			"children": null
		}, {
			"id": "445285",
			"name": "其它区",
			"parent": "445200",
			"children": null
		}]
	}, {
		"id": "445300",
		"name": "云浮市",
		"parent": "440000",
		"children": [{
			"id": "445302",
			"name": "云城区",
			"parent": "445300",
			"children": null
		}, {
			"id": "445321",
			"name": "新兴县",
			"parent": "445300",
			"children": null
		}, {
			"id": "445322",
			"name": "郁南县",
			"parent": "445300",
			"children": null
		}, {
			"id": "445323",
			"name": "云安区",
			"parent": "445300",
			"children": null
		}, {
			"id": "445381",
			"name": "罗定市",
			"parent": "445300",
			"children": null
		}, {
			"id": "445382",
			"name": "其它区",
			"parent": "445300",
			"children": null
		}]
	}]
}, {
	"id": "450000",
	"name": "广西壮族自治区",
	"children": [{
		"id": "450100",
		"name": "南宁市",
		"parent": "450000",
		"children": [{
			"id": "450102",
			"name": "兴宁区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450103",
			"name": "青秀区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450105",
			"name": "江南区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450107",
			"name": "西乡塘区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450108",
			"name": "良庆区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450109",
			"name": "邕宁区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450122",
			"name": "武鸣区",
			"parent": "450100",
			"children": null
		}, {
			"id": "450123",
			"name": "隆安县",
			"parent": "450100",
			"children": null
		}, {
			"id": "450124",
			"name": "马山县",
			"parent": "450100",
			"children": null
		}, {
			"id": "450125",
			"name": "上林县",
			"parent": "450100",
			"children": null
		}, {
			"id": "450126",
			"name": "宾阳县",
			"parent": "450100",
			"children": null
		}, {
			"id": "450127",
			"name": "横县",
			"parent": "450100",
			"children": null
		}, {
			"id": "450128",
			"name": "其它区",
			"parent": "450100",
			"children": null
		}]
	}, {
		"id": "450200",
		"name": "柳州市",
		"parent": "450000",
		"children": [{
			"id": "450202",
			"name": "城中区",
			"parent": "450200",
			"children": null
		}, {
			"id": "450203",
			"name": "鱼峰区",
			"parent": "450200",
			"children": null
		}, {
			"id": "450204",
			"name": "柳南区",
			"parent": "450200",
			"children": null
		}, {
			"id": "450205",
			"name": "柳北区",
			"parent": "450200",
			"children": null
		}, {
			"id": "450221",
			"name": "柳江县",
			"parent": "450200",
			"children": null
		}, {
			"id": "450222",
			"name": "柳城县",
			"parent": "450200",
			"children": null
		}, {
			"id": "450223",
			"name": "鹿寨县",
			"parent": "450200",
			"children": null
		}, {
			"id": "450224",
			"name": "融安县",
			"parent": "450200",
			"children": null
		}, {
			"id": "450225",
			"name": "融水苗族自治县",
			"parent": "450200",
			"children": null
		}, {
			"id": "450226",
			"name": "三江侗族自治县",
			"parent": "450200",
			"children": null
		}, {
			"id": "450227",
			"name": "其它区",
			"parent": "450200",
			"children": null
		}]
	}, {
		"id": "450300",
		"name": "桂林市",
		"parent": "450000",
		"children": [{
			"id": "450302",
			"name": "秀峰区",
			"parent": "450300",
			"children": null
		}, {
			"id": "450303",
			"name": "叠彩区",
			"parent": "450300",
			"children": null
		}, {
			"id": "450304",
			"name": "象山区",
			"parent": "450300",
			"children": null
		}, {
			"id": "450305",
			"name": "七星区",
			"parent": "450300",
			"children": null
		}, {
			"id": "450311",
			"name": "雁山区",
			"parent": "450300",
			"children": null
		}, {
			"id": "450321",
			"name": "阳朔县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450322",
			"name": "临桂区",
			"parent": "450300",
			"children": null
		}, {
			"id": "450323",
			"name": "灵川县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450324",
			"name": "全州县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450325",
			"name": "兴安县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450326",
			"name": "永福县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450327",
			"name": "灌阳县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450328",
			"name": "龙胜各族自治县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450329",
			"name": "资源县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450330",
			"name": "平乐县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450331",
			"name": "荔浦县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450332",
			"name": "恭城瑶族自治县",
			"parent": "450300",
			"children": null
		}, {
			"id": "450333",
			"name": "其它区",
			"parent": "450300",
			"children": null
		}]
	}, {
		"id": "450400",
		"name": "梧州市",
		"parent": "450000",
		"children": [{
			"id": "450403",
			"name": "万秀区",
			"parent": "450400",
			"children": null
		}, {
			"id": "450404",
			"name": "蝶山区",
			"parent": "450400",
			"children": null
		}, {
			"id": "450405",
			"name": "长洲区",
			"parent": "450400",
			"children": null
		}, {
			"id": "450406",
			"name": "龙圩区",
			"parent": "450400",
			"children": null
		}, {
			"id": "450421",
			"name": "苍梧县",
			"parent": "450400",
			"children": null
		}, {
			"id": "450422",
			"name": "藤县",
			"parent": "450400",
			"children": null
		}, {
			"id": "450423",
			"name": "蒙山县",
			"parent": "450400",
			"children": null
		}, {
			"id": "450481",
			"name": "岑溪市",
			"parent": "450400",
			"children": null
		}, {
			"id": "450482",
			"name": "其它区",
			"parent": "450400",
			"children": null
		}]
	}, {
		"id": "450500",
		"name": "北海市",
		"parent": "450000",
		"children": [{
			"id": "450502",
			"name": "海城区",
			"parent": "450500",
			"children": null
		}, {
			"id": "450503",
			"name": "银海区",
			"parent": "450500",
			"children": null
		}, {
			"id": "450512",
			"name": "铁山港区",
			"parent": "450500",
			"children": null
		}, {
			"id": "450521",
			"name": "合浦县",
			"parent": "450500",
			"children": null
		}, {
			"id": "450522",
			"name": "其它区",
			"parent": "450500",
			"children": null
		}]
	}, {
		"id": "450600",
		"name": "防城港市",
		"parent": "450000",
		"children": [{
			"id": "450602",
			"name": "港口区",
			"parent": "450600",
			"children": null
		}, {
			"id": "450603",
			"name": "防城区",
			"parent": "450600",
			"children": null
		}, {
			"id": "450621",
			"name": "上思县",
			"parent": "450600",
			"children": null
		}, {
			"id": "450681",
			"name": "东兴市",
			"parent": "450600",
			"children": null
		}, {
			"id": "450682",
			"name": "其它区",
			"parent": "450600",
			"children": null
		}]
	}, {
		"id": "450700",
		"name": "钦州市",
		"parent": "450000",
		"children": [{
			"id": "450702",
			"name": "钦南区",
			"parent": "450700",
			"children": null
		}, {
			"id": "450703",
			"name": "钦北区",
			"parent": "450700",
			"children": null
		}, {
			"id": "450721",
			"name": "灵山县",
			"parent": "450700",
			"children": null
		}, {
			"id": "450722",
			"name": "浦北县",
			"parent": "450700",
			"children": null
		}, {
			"id": "450723",
			"name": "其它区",
			"parent": "450700",
			"children": null
		}]
	}, {
		"id": "450800",
		"name": "贵港市",
		"parent": "450000",
		"children": [{
			"id": "450802",
			"name": "港北区",
			"parent": "450800",
			"children": null
		}, {
			"id": "450803",
			"name": "港南区",
			"parent": "450800",
			"children": null
		}, {
			"id": "450804",
			"name": "覃塘区",
			"parent": "450800",
			"children": null
		}, {
			"id": "450821",
			"name": "平南县",
			"parent": "450800",
			"children": null
		}, {
			"id": "450881",
			"name": "桂平市",
			"parent": "450800",
			"children": null
		}, {
			"id": "450882",
			"name": "其它区",
			"parent": "450800",
			"children": null
		}]
	}, {
		"id": "450900",
		"name": "玉林市",
		"parent": "450000",
		"children": [{
			"id": "450902",
			"name": "玉州区",
			"parent": "450900",
			"children": null
		}, {
			"id": "450903",
			"name": "福绵区",
			"parent": "450900",
			"children": null
		}, {
			"id": "450921",
			"name": "容县",
			"parent": "450900",
			"children": null
		}, {
			"id": "450922",
			"name": "陆川县",
			"parent": "450900",
			"children": null
		}, {
			"id": "450923",
			"name": "博白县",
			"parent": "450900",
			"children": null
		}, {
			"id": "450924",
			"name": "兴业县",
			"parent": "450900",
			"children": null
		}, {
			"id": "450981",
			"name": "北流市",
			"parent": "450900",
			"children": null
		}, {
			"id": "450982",
			"name": "其它区",
			"parent": "450900",
			"children": null
		}]
	}, {
		"id": "451000",
		"name": "百色市",
		"parent": "450000",
		"children": [{
			"id": "451002",
			"name": "右江区",
			"parent": "451000",
			"children": null
		}, {
			"id": "451021",
			"name": "田阳县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451022",
			"name": "田东县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451023",
			"name": "平果县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451024",
			"name": "德保县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451025",
			"name": "靖西县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451026",
			"name": "那坡县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451027",
			"name": "凌云县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451028",
			"name": "乐业县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451029",
			"name": "田林县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451030",
			"name": "西林县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451031",
			"name": "隆林各族自治县",
			"parent": "451000",
			"children": null
		}, {
			"id": "451032",
			"name": "其它区",
			"parent": "451000",
			"children": null
		}]
	}, {
		"id": "451100",
		"name": "贺州市",
		"parent": "450000",
		"children": [{
			"id": "451102",
			"name": "八步区",
			"parent": "451100",
			"children": null
		}, {
			"id": "451119",
			"name": "平桂管理区",
			"parent": "451100",
			"children": null
		}, {
			"id": "451121",
			"name": "昭平县",
			"parent": "451100",
			"children": null
		}, {
			"id": "451122",
			"name": "钟山县",
			"parent": "451100",
			"children": null
		}, {
			"id": "451123",
			"name": "富川瑶族自治县",
			"parent": "451100",
			"children": null
		}, {
			"id": "451124",
			"name": "其它区",
			"parent": "451100",
			"children": null
		}]
	}, {
		"id": "451200",
		"name": "河池市",
		"parent": "450000",
		"children": [{
			"id": "451202",
			"name": "金城江区",
			"parent": "451200",
			"children": null
		}, {
			"id": "451221",
			"name": "南丹县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451222",
			"name": "天峨县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451223",
			"name": "凤山县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451224",
			"name": "东兰县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451225",
			"name": "罗城仫佬族自治县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451226",
			"name": "环江毛南族自治县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451227",
			"name": "巴马瑶族自治县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451228",
			"name": "都安瑶族自治县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451229",
			"name": "大化瑶族自治县",
			"parent": "451200",
			"children": null
		}, {
			"id": "451281",
			"name": "宜州市",
			"parent": "451200",
			"children": null
		}, {
			"id": "451282",
			"name": "其它区",
			"parent": "451200",
			"children": null
		}]
	}, {
		"id": "451300",
		"name": "来宾市",
		"parent": "450000",
		"children": [{
			"id": "451302",
			"name": "兴宾区",
			"parent": "451300",
			"children": null
		}, {
			"id": "451321",
			"name": "忻城县",
			"parent": "451300",
			"children": null
		}, {
			"id": "451322",
			"name": "象州县",
			"parent": "451300",
			"children": null
		}, {
			"id": "451323",
			"name": "武宣县",
			"parent": "451300",
			"children": null
		}, {
			"id": "451324",
			"name": "金秀瑶族自治县",
			"parent": "451300",
			"children": null
		}, {
			"id": "451381",
			"name": "合山市",
			"parent": "451300",
			"children": null
		}, {
			"id": "451382",
			"name": "其它区",
			"parent": "451300",
			"children": null
		}]
	}, {
		"id": "451400",
		"name": "崇左市",
		"parent": "450000",
		"children": [{
			"id": "451402",
			"name": "江州区",
			"parent": "451400",
			"children": null
		}, {
			"id": "451421",
			"name": "扶绥县",
			"parent": "451400",
			"children": null
		}, {
			"id": "451422",
			"name": "宁明县",
			"parent": "451400",
			"children": null
		}, {
			"id": "451423",
			"name": "龙州县",
			"parent": "451400",
			"children": null
		}, {
			"id": "451424",
			"name": "大新县",
			"parent": "451400",
			"children": null
		}, {
			"id": "451425",
			"name": "天等县",
			"parent": "451400",
			"children": null
		}, {
			"id": "451481",
			"name": "凭祥市",
			"parent": "451400",
			"children": null
		}, {
			"id": "451482",
			"name": "其它区",
			"parent": "451400",
			"children": null
		}]
	}]
}, {
	"id": "460000",
	"name": "海南省",
	"children": [{
		"id": "460100",
		"name": "海口市",
		"parent": "460000",
		"children": [{
			"id": "460105",
			"name": "秀英区",
			"parent": "460100",
			"children": null
		}, {
			"id": "460106",
			"name": "龙华区",
			"parent": "460100",
			"children": null
		}, {
			"id": "460107",
			"name": "琼山区",
			"parent": "460100",
			"children": null
		}, {
			"id": "460108",
			"name": "美兰区",
			"parent": "460100",
			"children": null
		}, {
			"id": "460109",
			"name": "其它区",
			"parent": "460100",
			"children": null
		}]
	}, {
		"id": "460200",
		"name": "三亚市",
		"parent": "460000",
		"children": null
	}, {
		"id": "460300",
		"name": "三沙市",
		"parent": "460000",
		"children": [{
			"id": "460321",
			"name": "西沙群岛",
			"parent": "460300",
			"children": null
		}, {
			"id": "460322",
			"name": "南沙群岛",
			"parent": "460300",
			"children": null
		}, {
			"id": "460323",
			"name": "中沙群岛的岛礁及其海域",
			"parent": "460300",
			"children": null
		}]
	}, {
		"id": "469001",
		"name": "五指山市",
		"parent": "460000",
		"children": null
	}, {
		"id": "469002",
		"name": "琼海市",
		"parent": "460000",
		"children": null
	}, {
		"id": "469003",
		"name": "儋州市",
		"parent": "460000",
		"children": null
	}, {
		"id": "469005",
		"name": "文昌市",
		"parent": "460000",
		"children": null
	}, {
		"id": "469006",
		"name": "万宁市",
		"parent": "460000",
		"children": null
	}, {
		"id": "469007",
		"name": "东方市",
		"parent": "460000",
		"children": null
	}, {
		"id": "469025",
		"name": "定安县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469026",
		"name": "屯昌县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469027",
		"name": "澄迈县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469028",
		"name": "临高县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469030",
		"name": "白沙黎族自治县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469031",
		"name": "昌江黎族自治县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469033",
		"name": "乐东黎族自治县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469034",
		"name": "陵水黎族自治县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469035",
		"name": "保亭黎族苗族自治县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469036",
		"name": "琼中黎族苗族自治县",
		"parent": "460000",
		"children": null
	}, {
		"id": "469037",
		"name": "西沙群岛",
		"parent": "460000",
		"children": null
	}, {
		"id": "469038",
		"name": "南沙群岛",
		"parent": "460000",
		"children": null
	}, {
		"id": "469039",
		"name": "中沙群岛的岛礁及其海域",
		"parent": "460000",
		"children": null
	}]
}, {
	"id": "500000",
	"name": "重庆市",
	"children": [{
		"id": "500100",
		"name": "重庆市",
		"parent": "500000",
		"children": [{
			"id": "500101",
			"name": "万州区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500102",
			"name": "涪陵区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500103",
			"name": "渝中区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500104",
			"name": "大渡口区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500105",
			"name": "江北区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500106",
			"name": "沙坪坝区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500107",
			"name": "九龙坡区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500108",
			"name": "南岸区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500109",
			"name": "北碚区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500110",
			"name": "万盛区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500111",
			"name": "双桥区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500112",
			"name": "渝北区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500113",
			"name": "巴南区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500114",
			"name": "黔江区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500115",
			"name": "长寿区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500222",
			"name": "綦江区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500223",
			"name": "潼南县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500224",
			"name": "铜梁区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500225",
			"name": "大足区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500226",
			"name": "荣昌县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500227",
			"name": "璧山区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500228",
			"name": "梁平县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500229",
			"name": "城口县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500230",
			"name": "丰都县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500231",
			"name": "垫江县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500232",
			"name": "武隆县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500233",
			"name": "忠县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500234",
			"name": "开县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500235",
			"name": "云阳县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500236",
			"name": "奉节县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500237",
			"name": "巫山县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500238",
			"name": "巫溪县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500240",
			"name": "石柱土家族自治县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500241",
			"name": "秀山土家族苗族自治县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500242",
			"name": "酉阳土家族苗族自治县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500243",
			"name": "彭水苗族土家族自治县",
			"parent": "500100",
			"children": null
		}, {
			"id": "500381",
			"name": "江津区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500382",
			"name": "合川区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500383",
			"name": "永川区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500384",
			"name": "南川区",
			"parent": "500100",
			"children": null
		}, {
			"id": "500385",
			"name": "其它区",
			"parent": "500100",
			"children": null
		}]
	}]
}, {
	"id": "510000",
	"name": "四川省",
	"children": [{
		"id": "510100",
		"name": "成都市",
		"parent": "510000",
		"children": [{
			"id": "510104",
			"name": "锦江区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510105",
			"name": "青羊区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510106",
			"name": "金牛区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510107",
			"name": "武侯区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510108",
			"name": "成华区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510112",
			"name": "龙泉驿区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510113",
			"name": "青白江区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510114",
			"name": "新都区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510115",
			"name": "温江区",
			"parent": "510100",
			"children": null
		}, {
			"id": "510121",
			"name": "金堂县",
			"parent": "510100",
			"children": null
		}, {
			"id": "510122",
			"name": "双流县",
			"parent": "510100",
			"children": null
		}, {
			"id": "510124",
			"name": "郫县",
			"parent": "510100",
			"children": null
		}, {
			"id": "510129",
			"name": "大邑县",
			"parent": "510100",
			"children": null
		}, {
			"id": "510131",
			"name": "蒲江县",
			"parent": "510100",
			"children": null
		}, {
			"id": "510132",
			"name": "新津县",
			"parent": "510100",
			"children": null
		}, {
			"id": "510181",
			"name": "都江堰市",
			"parent": "510100",
			"children": null
		}, {
			"id": "510182",
			"name": "彭州市",
			"parent": "510100",
			"children": null
		}, {
			"id": "510183",
			"name": "邛崃市",
			"parent": "510100",
			"children": null
		}, {
			"id": "510184",
			"name": "崇州市",
			"parent": "510100",
			"children": null
		}, {
			"id": "510185",
			"name": "其它区",
			"parent": "510100",
			"children": null
		}]
	}, {
		"id": "510300",
		"name": "自贡市",
		"parent": "510000",
		"children": [{
			"id": "510302",
			"name": "自流井区",
			"parent": "510300",
			"children": null
		}, {
			"id": "510303",
			"name": "贡井区",
			"parent": "510300",
			"children": null
		}, {
			"id": "510304",
			"name": "大安区",
			"parent": "510300",
			"children": null
		}, {
			"id": "510311",
			"name": "沿滩区",
			"parent": "510300",
			"children": null
		}, {
			"id": "510321",
			"name": "荣县",
			"parent": "510300",
			"children": null
		}, {
			"id": "510322",
			"name": "富顺县",
			"parent": "510300",
			"children": null
		}, {
			"id": "510323",
			"name": "其它区",
			"parent": "510300",
			"children": null
		}]
	}, {
		"id": "510400",
		"name": "攀枝花市",
		"parent": "510000",
		"children": [{
			"id": "510402",
			"name": "东区",
			"parent": "510400",
			"children": null
		}, {
			"id": "510403",
			"name": "西区",
			"parent": "510400",
			"children": null
		}, {
			"id": "510411",
			"name": "仁和区",
			"parent": "510400",
			"children": null
		}, {
			"id": "510421",
			"name": "米易县",
			"parent": "510400",
			"children": null
		}, {
			"id": "510422",
			"name": "盐边县",
			"parent": "510400",
			"children": null
		}, {
			"id": "510423",
			"name": "其它区",
			"parent": "510400",
			"children": null
		}]
	}, {
		"id": "510500",
		"name": "泸州市",
		"parent": "510000",
		"children": [{
			"id": "510502",
			"name": "江阳区",
			"parent": "510500",
			"children": null
		}, {
			"id": "510503",
			"name": "纳溪区",
			"parent": "510500",
			"children": null
		}, {
			"id": "510504",
			"name": "龙马潭区",
			"parent": "510500",
			"children": null
		}, {
			"id": "510521",
			"name": "泸县",
			"parent": "510500",
			"children": null
		}, {
			"id": "510522",
			"name": "合江县",
			"parent": "510500",
			"children": null
		}, {
			"id": "510524",
			"name": "叙永县",
			"parent": "510500",
			"children": null
		}, {
			"id": "510525",
			"name": "古蔺县",
			"parent": "510500",
			"children": null
		}, {
			"id": "510526",
			"name": "其它区",
			"parent": "510500",
			"children": null
		}]
	}, {
		"id": "510600",
		"name": "德阳市",
		"parent": "510000",
		"children": [{
			"id": "510603",
			"name": "旌阳区",
			"parent": "510600",
			"children": null
		}, {
			"id": "510623",
			"name": "中江县",
			"parent": "510600",
			"children": null
		}, {
			"id": "510626",
			"name": "罗江县",
			"parent": "510600",
			"children": null
		}, {
			"id": "510681",
			"name": "广汉市",
			"parent": "510600",
			"children": null
		}, {
			"id": "510682",
			"name": "什邡市",
			"parent": "510600",
			"children": null
		}, {
			"id": "510683",
			"name": "绵竹市",
			"parent": "510600",
			"children": null
		}, {
			"id": "510684",
			"name": "其它区",
			"parent": "510600",
			"children": null
		}]
	}, {
		"id": "510700",
		"name": "绵阳市",
		"parent": "510000",
		"children": [{
			"id": "510703",
			"name": "涪城区",
			"parent": "510700",
			"children": null
		}, {
			"id": "510704",
			"name": "游仙区",
			"parent": "510700",
			"children": null
		}, {
			"id": "510722",
			"name": "三台县",
			"parent": "510700",
			"children": null
		}, {
			"id": "510723",
			"name": "盐亭县",
			"parent": "510700",
			"children": null
		}, {
			"id": "510724",
			"name": "安县",
			"parent": "510700",
			"children": null
		}, {
			"id": "510725",
			"name": "梓潼县",
			"parent": "510700",
			"children": null
		}, {
			"id": "510726",
			"name": "北川羌族自治县",
			"parent": "510700",
			"children": null
		}, {
			"id": "510727",
			"name": "平武县",
			"parent": "510700",
			"children": null
		}, {
			"id": "510751",
			"name": "高新区",
			"parent": "510700",
			"children": null
		}, {
			"id": "510781",
			"name": "江油市",
			"parent": "510700",
			"children": null
		}, {
			"id": "510782",
			"name": "其它区",
			"parent": "510700",
			"children": null
		}]
	}, {
		"id": "510800",
		"name": "广元市",
		"parent": "510000",
		"children": [{
			"id": "510802",
			"name": "利州区",
			"parent": "510800",
			"children": null
		}, {
			"id": "510811",
			"name": "昭化区",
			"parent": "510800",
			"children": null
		}, {
			"id": "510812",
			"name": "朝天区",
			"parent": "510800",
			"children": null
		}, {
			"id": "510821",
			"name": "旺苍县",
			"parent": "510800",
			"children": null
		}, {
			"id": "510822",
			"name": "青川县",
			"parent": "510800",
			"children": null
		}, {
			"id": "510823",
			"name": "剑阁县",
			"parent": "510800",
			"children": null
		}, {
			"id": "510824",
			"name": "苍溪县",
			"parent": "510800",
			"children": null
		}, {
			"id": "510825",
			"name": "其它区",
			"parent": "510800",
			"children": null
		}]
	}, {
		"id": "510900",
		"name": "遂宁市",
		"parent": "510000",
		"children": [{
			"id": "510903",
			"name": "船山区",
			"parent": "510900",
			"children": null
		}, {
			"id": "510904",
			"name": "安居区",
			"parent": "510900",
			"children": null
		}, {
			"id": "510921",
			"name": "蓬溪县",
			"parent": "510900",
			"children": null
		}, {
			"id": "510922",
			"name": "射洪县",
			"parent": "510900",
			"children": null
		}, {
			"id": "510923",
			"name": "大英县",
			"parent": "510900",
			"children": null
		}, {
			"id": "510924",
			"name": "其它区",
			"parent": "510900",
			"children": null
		}]
	}, {
		"id": "511000",
		"name": "内江市",
		"parent": "510000",
		"children": [{
			"id": "511002",
			"name": "市中区",
			"parent": "511000",
			"children": null
		}, {
			"id": "511011",
			"name": "东兴区",
			"parent": "511000",
			"children": null
		}, {
			"id": "511024",
			"name": "威远县",
			"parent": "511000",
			"children": null
		}, {
			"id": "511025",
			"name": "资中县",
			"parent": "511000",
			"children": null
		}, {
			"id": "511028",
			"name": "隆昌县",
			"parent": "511000",
			"children": null
		}, {
			"id": "511029",
			"name": "其它区",
			"parent": "511000",
			"children": null
		}]
	}, {
		"id": "511100",
		"name": "乐山市",
		"parent": "510000",
		"children": [{
			"id": "511102",
			"name": "市中区",
			"parent": "511100",
			"children": null
		}, {
			"id": "511111",
			"name": "沙湾区",
			"parent": "511100",
			"children": null
		}, {
			"id": "511112",
			"name": "五通桥区",
			"parent": "511100",
			"children": null
		}, {
			"id": "511113",
			"name": "金口河区",
			"parent": "511100",
			"children": null
		}, {
			"id": "511123",
			"name": "犍为县",
			"parent": "511100",
			"children": null
		}, {
			"id": "511124",
			"name": "井研县",
			"parent": "511100",
			"children": null
		}, {
			"id": "511126",
			"name": "夹江县",
			"parent": "511100",
			"children": null
		}, {
			"id": "511129",
			"name": "沐川县",
			"parent": "511100",
			"children": null
		}, {
			"id": "511132",
			"name": "峨边彝族自治县",
			"parent": "511100",
			"children": null
		}, {
			"id": "511133",
			"name": "马边彝族自治县",
			"parent": "511100",
			"children": null
		}, {
			"id": "511181",
			"name": "峨眉山市",
			"parent": "511100",
			"children": null
		}, {
			"id": "511182",
			"name": "其它区",
			"parent": "511100",
			"children": null
		}]
	}, {
		"id": "511300",
		"name": "南充市",
		"parent": "510000",
		"children": [{
			"id": "511302",
			"name": "顺庆区",
			"parent": "511300",
			"children": null
		}, {
			"id": "511303",
			"name": "高坪区",
			"parent": "511300",
			"children": null
		}, {
			"id": "511304",
			"name": "嘉陵区",
			"parent": "511300",
			"children": null
		}, {
			"id": "511321",
			"name": "南部县",
			"parent": "511300",
			"children": null
		}, {
			"id": "511322",
			"name": "营山县",
			"parent": "511300",
			"children": null
		}, {
			"id": "511323",
			"name": "蓬安县",
			"parent": "511300",
			"children": null
		}, {
			"id": "511324",
			"name": "仪陇县",
			"parent": "511300",
			"children": null
		}, {
			"id": "511325",
			"name": "西充县",
			"parent": "511300",
			"children": null
		}, {
			"id": "511381",
			"name": "阆中市",
			"parent": "511300",
			"children": null
		}, {
			"id": "511382",
			"name": "其它区",
			"parent": "511300",
			"children": null
		}]
	}, {
		"id": "511400",
		"name": "眉山市",
		"parent": "510000",
		"children": [{
			"id": "511402",
			"name": "东坡区",
			"parent": "511400",
			"children": null
		}, {
			"id": "511421",
			"name": "仁寿县",
			"parent": "511400",
			"children": null
		}, {
			"id": "511422",
			"name": "彭山区",
			"parent": "511400",
			"children": null
		}, {
			"id": "511423",
			"name": "洪雅县",
			"parent": "511400",
			"children": null
		}, {
			"id": "511424",
			"name": "丹棱县",
			"parent": "511400",
			"children": null
		}, {
			"id": "511425",
			"name": "青神县",
			"parent": "511400",
			"children": null
		}, {
			"id": "511426",
			"name": "其它区",
			"parent": "511400",
			"children": null
		}]
	}, {
		"id": "511500",
		"name": "宜宾市",
		"parent": "510000",
		"children": [{
			"id": "511502",
			"name": "翠屏区",
			"parent": "511500",
			"children": null
		}, {
			"id": "511521",
			"name": "宜宾县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511522",
			"name": "南溪区",
			"parent": "511500",
			"children": null
		}, {
			"id": "511523",
			"name": "江安县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511524",
			"name": "长宁县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511525",
			"name": "高县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511526",
			"name": "珙县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511527",
			"name": "筠连县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511528",
			"name": "兴文县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511529",
			"name": "屏山县",
			"parent": "511500",
			"children": null
		}, {
			"id": "511530",
			"name": "其它区",
			"parent": "511500",
			"children": null
		}]
	}, {
		"id": "511600",
		"name": "广安市",
		"parent": "510000",
		"children": [{
			"id": "511602",
			"name": "广安区",
			"parent": "511600",
			"children": null
		}, {
			"id": "511603",
			"name": "前锋区",
			"parent": "511600",
			"children": null
		}, {
			"id": "511621",
			"name": "岳池县",
			"parent": "511600",
			"children": null
		}, {
			"id": "511622",
			"name": "武胜县",
			"parent": "511600",
			"children": null
		}, {
			"id": "511623",
			"name": "邻水县",
			"parent": "511600",
			"children": null
		}, {
			"id": "511681",
			"name": "华蓥市",
			"parent": "511600",
			"children": null
		}, {
			"id": "511682",
			"name": "市辖区",
			"parent": "511600",
			"children": null
		}, {
			"id": "511683",
			"name": "其它区",
			"parent": "511600",
			"children": null
		}]
	}, {
		"id": "511700",
		"name": "达州市",
		"parent": "510000",
		"children": [{
			"id": "511702",
			"name": "通川区",
			"parent": "511700",
			"children": null
		}, {
			"id": "511721",
			"name": "达川区",
			"parent": "511700",
			"children": null
		}, {
			"id": "511722",
			"name": "宣汉县",
			"parent": "511700",
			"children": null
		}, {
			"id": "511723",
			"name": "开江县",
			"parent": "511700",
			"children": null
		}, {
			"id": "511724",
			"name": "大竹县",
			"parent": "511700",
			"children": null
		}, {
			"id": "511725",
			"name": "渠县",
			"parent": "511700",
			"children": null
		}, {
			"id": "511781",
			"name": "万源市",
			"parent": "511700",
			"children": null
		}, {
			"id": "511782",
			"name": "其它区",
			"parent": "511700",
			"children": null
		}]
	}, {
		"id": "511800",
		"name": "雅安市",
		"parent": "510000",
		"children": [{
			"id": "511802",
			"name": "雨城区",
			"parent": "511800",
			"children": null
		}, {
			"id": "511821",
			"name": "名山区",
			"parent": "511800",
			"children": null
		}, {
			"id": "511822",
			"name": "荥经县",
			"parent": "511800",
			"children": null
		}, {
			"id": "511823",
			"name": "汉源县",
			"parent": "511800",
			"children": null
		}, {
			"id": "511824",
			"name": "石棉县",
			"parent": "511800",
			"children": null
		}, {
			"id": "511825",
			"name": "天全县",
			"parent": "511800",
			"children": null
		}, {
			"id": "511826",
			"name": "芦山县",
			"parent": "511800",
			"children": null
		}, {
			"id": "511827",
			"name": "宝兴县",
			"parent": "511800",
			"children": null
		}, {
			"id": "511828",
			"name": "其它区",
			"parent": "511800",
			"children": null
		}]
	}, {
		"id": "511900",
		"name": "巴中市",
		"parent": "510000",
		"children": [{
			"id": "511902",
			"name": "巴州区",
			"parent": "511900",
			"children": null
		}, {
			"id": "511903",
			"name": "恩阳区",
			"parent": "511900",
			"children": null
		}, {
			"id": "511921",
			"name": "通江县",
			"parent": "511900",
			"children": null
		}, {
			"id": "511922",
			"name": "南江县",
			"parent": "511900",
			"children": null
		}, {
			"id": "511923",
			"name": "平昌县",
			"parent": "511900",
			"children": null
		}, {
			"id": "511924",
			"name": "其它区",
			"parent": "511900",
			"children": null
		}]
	}, {
		"id": "512000",
		"name": "资阳市",
		"parent": "510000",
		"children": [{
			"id": "512002",
			"name": "雁江区",
			"parent": "512000",
			"children": null
		}, {
			"id": "512021",
			"name": "安岳县",
			"parent": "512000",
			"children": null
		}, {
			"id": "512022",
			"name": "乐至县",
			"parent": "512000",
			"children": null
		}, {
			"id": "512081",
			"name": "简阳市",
			"parent": "512000",
			"children": null
		}, {
			"id": "512082",
			"name": "其它区",
			"parent": "512000",
			"children": null
		}]
	}, {
		"id": "513200",
		"name": "阿坝藏族羌族自治州",
		"parent": "510000",
		"children": [{
			"id": "513221",
			"name": "汶川县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513222",
			"name": "理县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513223",
			"name": "茂县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513224",
			"name": "松潘县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513225",
			"name": "九寨沟县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513226",
			"name": "金川县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513227",
			"name": "小金县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513228",
			"name": "黑水县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513229",
			"name": "马尔康县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513230",
			"name": "壤塘县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513231",
			"name": "阿坝县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513232",
			"name": "若尔盖县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513233",
			"name": "红原县",
			"parent": "513200",
			"children": null
		}, {
			"id": "513234",
			"name": "其它区",
			"parent": "513200",
			"children": null
		}]
	}, {
		"id": "513300",
		"name": "甘孜藏族自治州",
		"parent": "510000",
		"children": [{
			"id": "513321",
			"name": "康定市",
			"parent": "513300",
			"children": null
		}, {
			"id": "513322",
			"name": "泸定县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513323",
			"name": "丹巴县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513324",
			"name": "九龙县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513325",
			"name": "雅江县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513326",
			"name": "道孚县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513327",
			"name": "炉霍县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513328",
			"name": "甘孜县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513329",
			"name": "新龙县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513330",
			"name": "德格县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513331",
			"name": "白玉县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513332",
			"name": "石渠县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513333",
			"name": "色达县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513334",
			"name": "理塘县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513335",
			"name": "巴塘县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513336",
			"name": "乡城县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513337",
			"name": "稻城县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513338",
			"name": "得荣县",
			"parent": "513300",
			"children": null
		}, {
			"id": "513339",
			"name": "其它区",
			"parent": "513300",
			"children": null
		}]
	}, {
		"id": "513400",
		"name": "凉山彝族自治州",
		"parent": "510000",
		"children": [{
			"id": "513401",
			"name": "西昌市",
			"parent": "513400",
			"children": null
		}, {
			"id": "513422",
			"name": "木里藏族自治县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513423",
			"name": "盐源县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513424",
			"name": "德昌县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513425",
			"name": "会理县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513426",
			"name": "会东县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513427",
			"name": "宁南县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513428",
			"name": "普格县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513429",
			"name": "布拖县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513430",
			"name": "金阳县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513431",
			"name": "昭觉县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513432",
			"name": "喜德县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513433",
			"name": "冕宁县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513434",
			"name": "越西县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513435",
			"name": "甘洛县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513436",
			"name": "美姑县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513437",
			"name": "雷波县",
			"parent": "513400",
			"children": null
		}, {
			"id": "513438",
			"name": "其它区",
			"parent": "513400",
			"children": null
		}]
	}]
}, {
	"id": "520000",
	"name": "贵州省",
	"children": [{
		"id": "520100",
		"name": "贵阳市",
		"parent": "520000",
		"children": [{
			"id": "520102",
			"name": "南明区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520103",
			"name": "云岩区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520111",
			"name": "花溪区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520112",
			"name": "乌当区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520113",
			"name": "白云区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520114",
			"name": "小河区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520121",
			"name": "开阳县",
			"parent": "520100",
			"children": null
		}, {
			"id": "520122",
			"name": "息烽县",
			"parent": "520100",
			"children": null
		}, {
			"id": "520123",
			"name": "修文县",
			"parent": "520100",
			"children": null
		}, {
			"id": "520151",
			"name": "观山湖区",
			"parent": "520100",
			"children": null
		}, {
			"id": "520181",
			"name": "清镇市",
			"parent": "520100",
			"children": null
		}, {
			"id": "520182",
			"name": "其它区",
			"parent": "520100",
			"children": null
		}]
	}, {
		"id": "520200",
		"name": "六盘水市",
		"parent": "520000",
		"children": [{
			"id": "520201",
			"name": "钟山区",
			"parent": "520200",
			"children": null
		}, {
			"id": "520203",
			"name": "六枝特区",
			"parent": "520200",
			"children": null
		}, {
			"id": "520221",
			"name": "水城县",
			"parent": "520200",
			"children": null
		}, {
			"id": "520222",
			"name": "盘县",
			"parent": "520200",
			"children": null
		}, {
			"id": "520223",
			"name": "其它区",
			"parent": "520200",
			"children": null
		}]
	}, {
		"id": "520300",
		"name": "遵义市",
		"parent": "520000",
		"children": [{
			"id": "520302",
			"name": "红花岗区",
			"parent": "520300",
			"children": null
		}, {
			"id": "520303",
			"name": "汇川区",
			"parent": "520300",
			"children": null
		}, {
			"id": "520321",
			"name": "遵义县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520322",
			"name": "桐梓县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520323",
			"name": "绥阳县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520324",
			"name": "正安县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520325",
			"name": "道真仡佬族苗族自治县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520326",
			"name": "务川仡佬族苗族自治县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520327",
			"name": "凤冈县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520328",
			"name": "湄潭县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520329",
			"name": "余庆县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520330",
			"name": "习水县",
			"parent": "520300",
			"children": null
		}, {
			"id": "520381",
			"name": "赤水市",
			"parent": "520300",
			"children": null
		}, {
			"id": "520382",
			"name": "仁怀市",
			"parent": "520300",
			"children": null
		}, {
			"id": "520383",
			"name": "其它区",
			"parent": "520300",
			"children": null
		}]
	}, {
		"id": "520400",
		"name": "安顺市",
		"parent": "520000",
		"children": [{
			"id": "520402",
			"name": "西秀区",
			"parent": "520400",
			"children": null
		}, {
			"id": "520421",
			"name": "平坝区",
			"parent": "520400",
			"children": null
		}, {
			"id": "520422",
			"name": "普定县",
			"parent": "520400",
			"children": null
		}, {
			"id": "520423",
			"name": "镇宁布依族苗族自治县",
			"parent": "520400",
			"children": null
		}, {
			"id": "520424",
			"name": "关岭布依族苗族自治县",
			"parent": "520400",
			"children": null
		}, {
			"id": "520425",
			"name": "紫云苗族布依族自治县",
			"parent": "520400",
			"children": null
		}, {
			"id": "520426",
			"name": "其它区",
			"parent": "520400",
			"children": null
		}]
	}, {
		"id": "522200",
		"name": "铜仁市",
		"parent": "520000",
		"children": [{
			"id": "522201",
			"name": "碧江区",
			"parent": "522200",
			"children": null
		}, {
			"id": "522222",
			"name": "江口县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522223",
			"name": "玉屏侗族自治县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522224",
			"name": "石阡县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522225",
			"name": "思南县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522226",
			"name": "印江土家族苗族自治县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522227",
			"name": "德江县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522228",
			"name": "沿河土家族自治县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522229",
			"name": "松桃苗族自治县",
			"parent": "522200",
			"children": null
		}, {
			"id": "522230",
			"name": "万山区",
			"parent": "522200",
			"children": null
		}, {
			"id": "522231",
			"name": "其它区",
			"parent": "522200",
			"children": null
		}]
	}, {
		"id": "522300",
		"name": "黔西南布依族苗族自治州",
		"parent": "520000",
		"children": [{
			"id": "522301",
			"name": "兴义市",
			"parent": "522300",
			"children": null
		}, {
			"id": "522322",
			"name": "兴仁县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522323",
			"name": "普安县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522324",
			"name": "晴隆县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522325",
			"name": "贞丰县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522326",
			"name": "望谟县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522327",
			"name": "册亨县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522328",
			"name": "安龙县",
			"parent": "522300",
			"children": null
		}, {
			"id": "522329",
			"name": "其它区",
			"parent": "522300",
			"children": null
		}]
	}, {
		"id": "522400",
		"name": "毕节市",
		"parent": "520000",
		"children": [{
			"id": "522401",
			"name": "七星关区",
			"parent": "522400",
			"children": null
		}, {
			"id": "522422",
			"name": "大方县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522423",
			"name": "黔西县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522424",
			"name": "金沙县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522425",
			"name": "织金县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522426",
			"name": "纳雍县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522427",
			"name": "威宁彝族回族苗族自治县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522428",
			"name": "赫章县",
			"parent": "522400",
			"children": null
		}, {
			"id": "522429",
			"name": "其它区",
			"parent": "522400",
			"children": null
		}]
	}, {
		"id": "522600",
		"name": "黔东南苗族侗族自治州",
		"parent": "520000",
		"children": [{
			"id": "522601",
			"name": "凯里市",
			"parent": "522600",
			"children": null
		}, {
			"id": "522622",
			"name": "黄平县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522623",
			"name": "施秉县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522624",
			"name": "三穗县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522625",
			"name": "镇远县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522626",
			"name": "岑巩县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522627",
			"name": "天柱县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522628",
			"name": "锦屏县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522629",
			"name": "剑河县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522630",
			"name": "台江县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522631",
			"name": "黎平县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522632",
			"name": "榕江县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522633",
			"name": "从江县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522634",
			"name": "雷山县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522635",
			"name": "麻江县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522636",
			"name": "丹寨县",
			"parent": "522600",
			"children": null
		}, {
			"id": "522637",
			"name": "其它区",
			"parent": "522600",
			"children": null
		}]
	}, {
		"id": "522700",
		"name": "黔南布依族苗族自治州",
		"parent": "520000",
		"children": [{
			"id": "522701",
			"name": "都匀市",
			"parent": "522700",
			"children": null
		}, {
			"id": "522702",
			"name": "福泉市",
			"parent": "522700",
			"children": null
		}, {
			"id": "522722",
			"name": "荔波县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522723",
			"name": "贵定县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522725",
			"name": "瓮安县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522726",
			"name": "独山县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522727",
			"name": "平塘县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522728",
			"name": "罗甸县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522729",
			"name": "长顺县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522730",
			"name": "龙里县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522731",
			"name": "惠水县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522732",
			"name": "三都水族自治县",
			"parent": "522700",
			"children": null
		}, {
			"id": "522733",
			"name": "其它区",
			"parent": "522700",
			"children": null
		}]
	}]
}, {
	"id": "530000",
	"name": "云南省",
	"children": [{
		"id": "530100",
		"name": "昆明市",
		"parent": "530000",
		"children": [{
			"id": "530102",
			"name": "五华区",
			"parent": "530100",
			"children": null
		}, {
			"id": "530103",
			"name": "盘龙区",
			"parent": "530100",
			"children": null
		}, {
			"id": "530111",
			"name": "官渡区",
			"parent": "530100",
			"children": null
		}, {
			"id": "530112",
			"name": "西山区",
			"parent": "530100",
			"children": null
		}, {
			"id": "530113",
			"name": "东川区",
			"parent": "530100",
			"children": null
		}, {
			"id": "530121",
			"name": "呈贡区",
			"parent": "530100",
			"children": null
		}, {
			"id": "530122",
			"name": "晋宁县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530124",
			"name": "富民县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530125",
			"name": "宜良县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530126",
			"name": "石林彝族自治县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530127",
			"name": "嵩明县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530128",
			"name": "禄劝彝族苗族自治县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530129",
			"name": "寻甸回族彝族自治县",
			"parent": "530100",
			"children": null
		}, {
			"id": "530181",
			"name": "安宁市",
			"parent": "530100",
			"children": null
		}, {
			"id": "530182",
			"name": "其它区",
			"parent": "530100",
			"children": null
		}]
	}, {
		"id": "530300",
		"name": "曲靖市",
		"parent": "530000",
		"children": [{
			"id": "530302",
			"name": "麒麟区",
			"parent": "530300",
			"children": null
		}, {
			"id": "530321",
			"name": "马龙县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530322",
			"name": "陆良县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530323",
			"name": "师宗县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530324",
			"name": "罗平县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530325",
			"name": "富源县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530326",
			"name": "会泽县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530328",
			"name": "沾益县",
			"parent": "530300",
			"children": null
		}, {
			"id": "530381",
			"name": "宣威市",
			"parent": "530300",
			"children": null
		}, {
			"id": "530382",
			"name": "其它区",
			"parent": "530300",
			"children": null
		}]
	}, {
		"id": "530400",
		"name": "玉溪市",
		"parent": "530000",
		"children": [{
			"id": "530402",
			"name": "红塔区",
			"parent": "530400",
			"children": null
		}, {
			"id": "530421",
			"name": "江川县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530422",
			"name": "澄江县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530423",
			"name": "通海县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530424",
			"name": "华宁县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530425",
			"name": "易门县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530426",
			"name": "峨山彝族自治县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530427",
			"name": "新平彝族傣族自治县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530428",
			"name": "元江哈尼族彝族傣族自治县",
			"parent": "530400",
			"children": null
		}, {
			"id": "530429",
			"name": "其它区",
			"parent": "530400",
			"children": null
		}]
	}, {
		"id": "530500",
		"name": "保山市",
		"parent": "530000",
		"children": [{
			"id": "530502",
			"name": "隆阳区",
			"parent": "530500",
			"children": null
		}, {
			"id": "530521",
			"name": "施甸县",
			"parent": "530500",
			"children": null
		}, {
			"id": "530522",
			"name": "腾冲县",
			"parent": "530500",
			"children": null
		}, {
			"id": "530523",
			"name": "龙陵县",
			"parent": "530500",
			"children": null
		}, {
			"id": "530524",
			"name": "昌宁县",
			"parent": "530500",
			"children": null
		}, {
			"id": "530525",
			"name": "其它区",
			"parent": "530500",
			"children": null
		}]
	}, {
		"id": "530600",
		"name": "昭通市",
		"parent": "530000",
		"children": [{
			"id": "530602",
			"name": "昭阳区",
			"parent": "530600",
			"children": null
		}, {
			"id": "530621",
			"name": "鲁甸县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530622",
			"name": "巧家县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530623",
			"name": "盐津县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530624",
			"name": "大关县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530625",
			"name": "永善县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530626",
			"name": "绥江县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530627",
			"name": "镇雄县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530628",
			"name": "彝良县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530629",
			"name": "威信县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530630",
			"name": "水富县",
			"parent": "530600",
			"children": null
		}, {
			"id": "530631",
			"name": "其它区",
			"parent": "530600",
			"children": null
		}]
	}, {
		"id": "530700",
		"name": "丽江市",
		"parent": "530000",
		"children": [{
			"id": "530702",
			"name": "古城区",
			"parent": "530700",
			"children": null
		}, {
			"id": "530721",
			"name": "玉龙纳西族自治县",
			"parent": "530700",
			"children": null
		}, {
			"id": "530722",
			"name": "永胜县",
			"parent": "530700",
			"children": null
		}, {
			"id": "530723",
			"name": "华坪县",
			"parent": "530700",
			"children": null
		}, {
			"id": "530724",
			"name": "宁蒗彝族自治县",
			"parent": "530700",
			"children": null
		}, {
			"id": "530725",
			"name": "其它区",
			"parent": "530700",
			"children": null
		}]
	}, {
		"id": "530800",
		"name": "普洱市",
		"parent": "530000",
		"children": [{
			"id": "530802",
			"name": "思茅区",
			"parent": "530800",
			"children": null
		}, {
			"id": "530821",
			"name": "宁洱哈尼族彝族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530822",
			"name": "墨江哈尼族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530823",
			"name": "景东彝族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530824",
			"name": "景谷傣族彝族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530825",
			"name": "镇沅彝族哈尼族拉祜族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530826",
			"name": "江城哈尼族彝族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530827",
			"name": "孟连傣族拉祜族佤族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530828",
			"name": "澜沧拉祜族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530829",
			"name": "西盟佤族自治县",
			"parent": "530800",
			"children": null
		}, {
			"id": "530830",
			"name": "其它区",
			"parent": "530800",
			"children": null
		}]
	}, {
		"id": "530900",
		"name": "临沧市",
		"parent": "530000",
		"children": [{
			"id": "530902",
			"name": "临翔区",
			"parent": "530900",
			"children": null
		}, {
			"id": "530921",
			"name": "凤庆县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530922",
			"name": "云县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530923",
			"name": "永德县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530924",
			"name": "镇康县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530925",
			"name": "双江拉祜族佤族布朗族傣族自治县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530926",
			"name": "耿马傣族佤族自治县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530927",
			"name": "沧源佤族自治县",
			"parent": "530900",
			"children": null
		}, {
			"id": "530928",
			"name": "其它区",
			"parent": "530900",
			"children": null
		}]
	}, {
		"id": "532300",
		"name": "楚雄彝族自治州",
		"parent": "530000",
		"children": [{
			"id": "532301",
			"name": "楚雄市",
			"parent": "532300",
			"children": null
		}, {
			"id": "532322",
			"name": "双柏县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532323",
			"name": "牟定县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532324",
			"name": "南华县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532325",
			"name": "姚安县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532326",
			"name": "大姚县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532327",
			"name": "永仁县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532328",
			"name": "元谋县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532329",
			"name": "武定县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532331",
			"name": "禄丰县",
			"parent": "532300",
			"children": null
		}, {
			"id": "532332",
			"name": "其它区",
			"parent": "532300",
			"children": null
		}]
	}, {
		"id": "532500",
		"name": "红河哈尼族彝族自治州",
		"parent": "530000",
		"children": [{
			"id": "532501",
			"name": "个旧市",
			"parent": "532500",
			"children": null
		}, {
			"id": "532502",
			"name": "开远市",
			"parent": "532500",
			"children": null
		}, {
			"id": "532522",
			"name": "蒙自市",
			"parent": "532500",
			"children": null
		}, {
			"id": "532523",
			"name": "屏边苗族自治县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532524",
			"name": "建水县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532525",
			"name": "石屏县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532526",
			"name": "弥勒市",
			"parent": "532500",
			"children": null
		}, {
			"id": "532527",
			"name": "泸西县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532528",
			"name": "元阳县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532529",
			"name": "红河县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532530",
			"name": "金平苗族瑶族傣族自治县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532531",
			"name": "绿春县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532532",
			"name": "河口瑶族自治县",
			"parent": "532500",
			"children": null
		}, {
			"id": "532533",
			"name": "其它区",
			"parent": "532500",
			"children": null
		}]
	}, {
		"id": "532600",
		"name": "文山壮族苗族自治州",
		"parent": "530000",
		"children": [{
			"id": "532621",
			"name": "文山市",
			"parent": "532600",
			"children": null
		}, {
			"id": "532622",
			"name": "砚山县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532623",
			"name": "西畴县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532624",
			"name": "麻栗坡县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532625",
			"name": "马关县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532626",
			"name": "丘北县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532627",
			"name": "广南县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532628",
			"name": "富宁县",
			"parent": "532600",
			"children": null
		}, {
			"id": "532629",
			"name": "其它区",
			"parent": "532600",
			"children": null
		}]
	}, {
		"id": "532800",
		"name": "西双版纳傣族自治州",
		"parent": "530000",
		"children": [{
			"id": "532801",
			"name": "景洪市",
			"parent": "532800",
			"children": null
		}, {
			"id": "532822",
			"name": "勐海县",
			"parent": "532800",
			"children": null
		}, {
			"id": "532823",
			"name": "勐腊县",
			"parent": "532800",
			"children": null
		}, {
			"id": "532824",
			"name": "其它区",
			"parent": "532800",
			"children": null
		}]
	}, {
		"id": "532900",
		"name": "大理白族自治州",
		"parent": "530000",
		"children": [{
			"id": "532901",
			"name": "大理市",
			"parent": "532900",
			"children": null
		}, {
			"id": "532922",
			"name": "漾濞彝族自治县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532923",
			"name": "祥云县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532924",
			"name": "宾川县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532925",
			"name": "弥渡县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532926",
			"name": "南涧彝族自治县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532927",
			"name": "巍山彝族回族自治县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532928",
			"name": "永平县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532929",
			"name": "云龙县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532930",
			"name": "洱源县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532931",
			"name": "剑川县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532932",
			"name": "鹤庆县",
			"parent": "532900",
			"children": null
		}, {
			"id": "532933",
			"name": "其它区",
			"parent": "532900",
			"children": null
		}]
	}, {
		"id": "533100",
		"name": "德宏傣族景颇族自治州",
		"parent": "530000",
		"children": [{
			"id": "533102",
			"name": "瑞丽市",
			"parent": "533100",
			"children": null
		}, {
			"id": "533103",
			"name": "芒市",
			"parent": "533100",
			"children": null
		}, {
			"id": "533122",
			"name": "梁河县",
			"parent": "533100",
			"children": null
		}, {
			"id": "533123",
			"name": "盈江县",
			"parent": "533100",
			"children": null
		}, {
			"id": "533124",
			"name": "陇川县",
			"parent": "533100",
			"children": null
		}, {
			"id": "533125",
			"name": "其它区",
			"parent": "533100",
			"children": null
		}]
	}, {
		"id": "533300",
		"name": "怒江傈僳族自治州",
		"parent": "530000",
		"children": [{
			"id": "533321",
			"name": "泸水县",
			"parent": "533300",
			"children": null
		}, {
			"id": "533323",
			"name": "福贡县",
			"parent": "533300",
			"children": null
		}, {
			"id": "533324",
			"name": "贡山独龙族怒族自治县",
			"parent": "533300",
			"children": null
		}, {
			"id": "533325",
			"name": "兰坪白族普米族自治县",
			"parent": "533300",
			"children": null
		}, {
			"id": "533326",
			"name": "其它区",
			"parent": "533300",
			"children": null
		}]
	}, {
		"id": "533400",
		"name": "迪庆藏族自治州",
		"parent": "530000",
		"children": [{
			"id": "533421",
			"name": "香格里拉市",
			"parent": "533400",
			"children": null
		}, {
			"id": "533422",
			"name": "德钦县",
			"parent": "533400",
			"children": null
		}, {
			"id": "533423",
			"name": "维西傈僳族自治县",
			"parent": "533400",
			"children": null
		}, {
			"id": "533424",
			"name": "其它区",
			"parent": "533400",
			"children": null
		}]
	}]
}, {
	"id": "540000",
	"name": "西藏自治区",
	"children": [{
		"id": "540100",
		"name": "拉萨市",
		"parent": "540000",
		"children": [{
			"id": "540102",
			"name": "城关区",
			"parent": "540100",
			"children": null
		}, {
			"id": "540121",
			"name": "林周县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540122",
			"name": "当雄县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540123",
			"name": "尼木县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540124",
			"name": "曲水县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540125",
			"name": "堆龙德庆县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540126",
			"name": "达孜县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540127",
			"name": "墨竹工卡县",
			"parent": "540100",
			"children": null
		}, {
			"id": "540128",
			"name": "其它区",
			"parent": "540100",
			"children": null
		}]
	}, {
		"id": "542100",
		"name": "昌都市",
		"parent": "540000",
		"children": [{
			"id": "542121",
			"name": "卡若区",
			"parent": "542100",
			"children": null
		}, {
			"id": "542122",
			"name": "江达县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542123",
			"name": "贡觉县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542124",
			"name": "类乌齐县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542125",
			"name": "丁青县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542126",
			"name": "察雅县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542127",
			"name": "八宿县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542128",
			"name": "左贡县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542129",
			"name": "芒康县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542132",
			"name": "洛隆县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542133",
			"name": "边坝县",
			"parent": "542100",
			"children": null
		}, {
			"id": "542134",
			"name": "其它区",
			"parent": "542100",
			"children": null
		}]
	}, {
		"id": "542200",
		"name": "山南地区",
		"parent": "540000",
		"children": [{
			"id": "542221",
			"name": "乃东县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542222",
			"name": "扎囊县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542223",
			"name": "贡嘎县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542224",
			"name": "桑日县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542225",
			"name": "琼结县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542226",
			"name": "曲松县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542227",
			"name": "措美县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542228",
			"name": "洛扎县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542229",
			"name": "加查县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542231",
			"name": "隆子县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542232",
			"name": "错那县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542233",
			"name": "浪卡子县",
			"parent": "542200",
			"children": null
		}, {
			"id": "542234",
			"name": "其它区",
			"parent": "542200",
			"children": null
		}]
	}, {
		"id": "542300",
		"name": "日喀则市",
		"parent": "540000",
		"children": [{
			"id": "542301",
			"name": "桑珠孜区",
			"parent": "542300",
			"children": null
		}, {
			"id": "542322",
			"name": "南木林县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542323",
			"name": "江孜县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542324",
			"name": "定日县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542325",
			"name": "萨迦县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542326",
			"name": "拉孜县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542327",
			"name": "昂仁县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542328",
			"name": "谢通门县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542329",
			"name": "白朗县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542330",
			"name": "仁布县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542331",
			"name": "康马县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542332",
			"name": "定结县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542333",
			"name": "仲巴县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542334",
			"name": "亚东县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542335",
			"name": "吉隆县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542336",
			"name": "聂拉木县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542337",
			"name": "萨嘎县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542338",
			"name": "岗巴县",
			"parent": "542300",
			"children": null
		}, {
			"id": "542339",
			"name": "其它区",
			"parent": "542300",
			"children": null
		}]
	}, {
		"id": "542400",
		"name": "那曲地区",
		"parent": "540000",
		"children": [{
			"id": "542421",
			"name": "那曲县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542422",
			"name": "嘉黎县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542423",
			"name": "比如县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542424",
			"name": "聂荣县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542425",
			"name": "安多县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542426",
			"name": "申扎县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542427",
			"name": "索县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542428",
			"name": "班戈县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542429",
			"name": "巴青县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542430",
			"name": "尼玛县",
			"parent": "542400",
			"children": null
		}, {
			"id": "542431",
			"name": "其它区",
			"parent": "542400",
			"children": null
		}, {
			"id": "542432",
			"name": "双湖县",
			"parent": "542400",
			"children": null
		}]
	}, {
		"id": "542500",
		"name": "阿里地区",
		"parent": "540000",
		"children": [{
			"id": "542521",
			"name": "普兰县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542522",
			"name": "札达县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542523",
			"name": "噶尔县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542524",
			"name": "日土县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542525",
			"name": "革吉县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542526",
			"name": "改则县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542527",
			"name": "措勤县",
			"parent": "542500",
			"children": null
		}, {
			"id": "542528",
			"name": "其它区",
			"parent": "542500",
			"children": null
		}]
	}, {
		"id": "542600",
		"name": "林芝市",
		"parent": "540000",
		"children": [{
			"id": "542621",
			"name": "巴宜区",
			"parent": "542600",
			"children": null
		}, {
			"id": "542622",
			"name": "工布江达县",
			"parent": "542600",
			"children": null
		}, {
			"id": "542623",
			"name": "米林县",
			"parent": "542600",
			"children": null
		}, {
			"id": "542624",
			"name": "墨脱县",
			"parent": "542600",
			"children": null
		}, {
			"id": "542625",
			"name": "波密县",
			"parent": "542600",
			"children": null
		}, {
			"id": "542626",
			"name": "察隅县",
			"parent": "542600",
			"children": null
		}, {
			"id": "542627",
			"name": "朗县",
			"parent": "542600",
			"children": null
		}, {
			"id": "542628",
			"name": "其它区",
			"parent": "542600",
			"children": null
		}]
	}]
}, {
	"id": "610000",
	"name": "陕西省",
	"children": [{
		"id": "610100",
		"name": "西安市",
		"parent": "610000",
		"children": [{
			"id": "610102",
			"name": "新城区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610103",
			"name": "碑林区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610104",
			"name": "莲湖区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610111",
			"name": "灞桥区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610112",
			"name": "未央区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610113",
			"name": "雁塔区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610114",
			"name": "阎良区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610115",
			"name": "临潼区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610116",
			"name": "长安区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610122",
			"name": "蓝田县",
			"parent": "610100",
			"children": null
		}, {
			"id": "610124",
			"name": "周至县",
			"parent": "610100",
			"children": null
		}, {
			"id": "610125",
			"name": "户县",
			"parent": "610100",
			"children": null
		}, {
			"id": "610126",
			"name": "高陵区",
			"parent": "610100",
			"children": null
		}, {
			"id": "610127",
			"name": "其它区",
			"parent": "610100",
			"children": null
		}]
	}, {
		"id": "610200",
		"name": "铜川市",
		"parent": "610000",
		"children": [{
			"id": "610202",
			"name": "王益区",
			"parent": "610200",
			"children": null
		}, {
			"id": "610203",
			"name": "印台区",
			"parent": "610200",
			"children": null
		}, {
			"id": "610204",
			"name": "耀州区",
			"parent": "610200",
			"children": null
		}, {
			"id": "610222",
			"name": "宜君县",
			"parent": "610200",
			"children": null
		}, {
			"id": "610223",
			"name": "其它区",
			"parent": "610200",
			"children": null
		}]
	}, {
		"id": "610300",
		"name": "宝鸡市",
		"parent": "610000",
		"children": [{
			"id": "610302",
			"name": "渭滨区",
			"parent": "610300",
			"children": null
		}, {
			"id": "610303",
			"name": "金台区",
			"parent": "610300",
			"children": null
		}, {
			"id": "610304",
			"name": "陈仓区",
			"parent": "610300",
			"children": null
		}, {
			"id": "610322",
			"name": "凤翔县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610323",
			"name": "岐山县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610324",
			"name": "扶风县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610326",
			"name": "眉县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610327",
			"name": "陇县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610328",
			"name": "千阳县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610329",
			"name": "麟游县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610330",
			"name": "凤县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610331",
			"name": "太白县",
			"parent": "610300",
			"children": null
		}, {
			"id": "610332",
			"name": "其它区",
			"parent": "610300",
			"children": null
		}]
	}, {
		"id": "610400",
		"name": "咸阳市",
		"parent": "610000",
		"children": [{
			"id": "610402",
			"name": "秦都区",
			"parent": "610400",
			"children": null
		}, {
			"id": "610403",
			"name": "杨陵区",
			"parent": "610400",
			"children": null
		}, {
			"id": "610404",
			"name": "渭城区",
			"parent": "610400",
			"children": null
		}, {
			"id": "610422",
			"name": "三原县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610423",
			"name": "泾阳县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610424",
			"name": "乾县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610425",
			"name": "礼泉县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610426",
			"name": "永寿县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610427",
			"name": "彬县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610428",
			"name": "长武县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610429",
			"name": "旬邑县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610430",
			"name": "淳化县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610431",
			"name": "武功县",
			"parent": "610400",
			"children": null
		}, {
			"id": "610481",
			"name": "兴平市",
			"parent": "610400",
			"children": null
		}, {
			"id": "610482",
			"name": "其它区",
			"parent": "610400",
			"children": null
		}]
	}, {
		"id": "610500",
		"name": "渭南市",
		"parent": "610000",
		"children": [{
			"id": "610502",
			"name": "临渭区",
			"parent": "610500",
			"children": null
		}, {
			"id": "610521",
			"name": "华县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610522",
			"name": "潼关县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610523",
			"name": "大荔县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610524",
			"name": "合阳县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610525",
			"name": "澄城县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610526",
			"name": "蒲城县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610527",
			"name": "白水县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610528",
			"name": "富平县",
			"parent": "610500",
			"children": null
		}, {
			"id": "610581",
			"name": "韩城市",
			"parent": "610500",
			"children": null
		}, {
			"id": "610582",
			"name": "华阴市",
			"parent": "610500",
			"children": null
		}, {
			"id": "610583",
			"name": "其它区",
			"parent": "610500",
			"children": null
		}]
	}, {
		"id": "610600",
		"name": "延安市",
		"parent": "610000",
		"children": [{
			"id": "610602",
			"name": "宝塔区",
			"parent": "610600",
			"children": null
		}, {
			"id": "610621",
			"name": "延长县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610622",
			"name": "延川县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610623",
			"name": "子长县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610624",
			"name": "安塞县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610625",
			"name": "志丹县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610626",
			"name": "吴起县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610627",
			"name": "甘泉县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610628",
			"name": "富县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610629",
			"name": "洛川县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610630",
			"name": "宜川县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610631",
			"name": "黄龙县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610632",
			"name": "黄陵县",
			"parent": "610600",
			"children": null
		}, {
			"id": "610633",
			"name": "其它区",
			"parent": "610600",
			"children": null
		}]
	}, {
		"id": "610700",
		"name": "汉中市",
		"parent": "610000",
		"children": [{
			"id": "610702",
			"name": "汉台区",
			"parent": "610700",
			"children": null
		}, {
			"id": "610721",
			"name": "南郑县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610722",
			"name": "城固县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610723",
			"name": "洋县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610724",
			"name": "西乡县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610725",
			"name": "勉县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610726",
			"name": "宁强县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610727",
			"name": "略阳县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610728",
			"name": "镇巴县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610729",
			"name": "留坝县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610730",
			"name": "佛坪县",
			"parent": "610700",
			"children": null
		}, {
			"id": "610731",
			"name": "其它区",
			"parent": "610700",
			"children": null
		}]
	}, {
		"id": "610800",
		"name": "榆林市",
		"parent": "610000",
		"children": [{
			"id": "610802",
			"name": "榆阳区",
			"parent": "610800",
			"children": null
		}, {
			"id": "610821",
			"name": "神木县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610822",
			"name": "府谷县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610823",
			"name": "横山县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610824",
			"name": "靖边县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610825",
			"name": "定边县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610826",
			"name": "绥德县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610827",
			"name": "米脂县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610828",
			"name": "佳县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610829",
			"name": "吴堡县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610830",
			"name": "清涧县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610831",
			"name": "子洲县",
			"parent": "610800",
			"children": null
		}, {
			"id": "610832",
			"name": "其它区",
			"parent": "610800",
			"children": null
		}]
	}, {
		"id": "610900",
		"name": "安康市",
		"parent": "610000",
		"children": [{
			"id": "610902",
			"name": "汉滨区",
			"parent": "610900",
			"children": null
		}, {
			"id": "610921",
			"name": "汉阴县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610922",
			"name": "石泉县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610923",
			"name": "宁陕县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610924",
			"name": "紫阳县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610925",
			"name": "岚皋县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610926",
			"name": "平利县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610927",
			"name": "镇坪县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610928",
			"name": "旬阳县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610929",
			"name": "白河县",
			"parent": "610900",
			"children": null
		}, {
			"id": "610930",
			"name": "其它区",
			"parent": "610900",
			"children": null
		}]
	}, {
		"id": "611000",
		"name": "商洛市",
		"parent": "610000",
		"children": [{
			"id": "611002",
			"name": "商州区",
			"parent": "611000",
			"children": null
		}, {
			"id": "611021",
			"name": "洛南县",
			"parent": "611000",
			"children": null
		}, {
			"id": "611022",
			"name": "丹凤县",
			"parent": "611000",
			"children": null
		}, {
			"id": "611023",
			"name": "商南县",
			"parent": "611000",
			"children": null
		}, {
			"id": "611024",
			"name": "山阳县",
			"parent": "611000",
			"children": null
		}, {
			"id": "611025",
			"name": "镇安县",
			"parent": "611000",
			"children": null
		}, {
			"id": "611026",
			"name": "柞水县",
			"parent": "611000",
			"children": null
		}, {
			"id": "611027",
			"name": "其它区",
			"parent": "611000",
			"children": null
		}]
	}]
}, {
	"id": "620000",
	"name": "甘肃省",
	"children": [{
		"id": "620100",
		"name": "兰州市",
		"parent": "620000",
		"children": [{
			"id": "620102",
			"name": "城关区",
			"parent": "620100",
			"children": null
		}, {
			"id": "620103",
			"name": "七里河区",
			"parent": "620100",
			"children": null
		}, {
			"id": "620104",
			"name": "西固区",
			"parent": "620100",
			"children": null
		}, {
			"id": "620105",
			"name": "安宁区",
			"parent": "620100",
			"children": null
		}, {
			"id": "620111",
			"name": "红古区",
			"parent": "620100",
			"children": null
		}, {
			"id": "620121",
			"name": "永登县",
			"parent": "620100",
			"children": null
		}, {
			"id": "620122",
			"name": "皋兰县",
			"parent": "620100",
			"children": null
		}, {
			"id": "620123",
			"name": "榆中县",
			"parent": "620100",
			"children": null
		}, {
			"id": "620124",
			"name": "其它区",
			"parent": "620100",
			"children": null
		}]
	}, {
		"id": "620200",
		"name": "嘉峪关市",
		"parent": "620000",
		"children": null
	}, {
		"id": "620300",
		"name": "金昌市",
		"parent": "620000",
		"children": [{
			"id": "620302",
			"name": "金川区",
			"parent": "620300",
			"children": null
		}, {
			"id": "620321",
			"name": "永昌县",
			"parent": "620300",
			"children": null
		}, {
			"id": "620322",
			"name": "其它区",
			"parent": "620300",
			"children": null
		}]
	}, {
		"id": "620400",
		"name": "白银市",
		"parent": "620000",
		"children": [{
			"id": "620402",
			"name": "白银区",
			"parent": "620400",
			"children": null
		}, {
			"id": "620403",
			"name": "平川区",
			"parent": "620400",
			"children": null
		}, {
			"id": "620421",
			"name": "靖远县",
			"parent": "620400",
			"children": null
		}, {
			"id": "620422",
			"name": "会宁县",
			"parent": "620400",
			"children": null
		}, {
			"id": "620423",
			"name": "景泰县",
			"parent": "620400",
			"children": null
		}, {
			"id": "620424",
			"name": "其它区",
			"parent": "620400",
			"children": null
		}]
	}, {
		"id": "620500",
		"name": "天水市",
		"parent": "620000",
		"children": [{
			"id": "620502",
			"name": "秦州区",
			"parent": "620500",
			"children": null
		}, {
			"id": "620503",
			"name": "麦积区",
			"parent": "620500",
			"children": null
		}, {
			"id": "620521",
			"name": "清水县",
			"parent": "620500",
			"children": null
		}, {
			"id": "620522",
			"name": "秦安县",
			"parent": "620500",
			"children": null
		}, {
			"id": "620523",
			"name": "甘谷县",
			"parent": "620500",
			"children": null
		}, {
			"id": "620524",
			"name": "武山县",
			"parent": "620500",
			"children": null
		}, {
			"id": "620525",
			"name": "张家川回族自治县",
			"parent": "620500",
			"children": null
		}, {
			"id": "620526",
			"name": "其它区",
			"parent": "620500",
			"children": null
		}]
	}, {
		"id": "620600",
		"name": "武威市",
		"parent": "620000",
		"children": [{
			"id": "620602",
			"name": "凉州区",
			"parent": "620600",
			"children": null
		}, {
			"id": "620621",
			"name": "民勤县",
			"parent": "620600",
			"children": null
		}, {
			"id": "620622",
			"name": "古浪县",
			"parent": "620600",
			"children": null
		}, {
			"id": "620623",
			"name": "天祝藏族自治县",
			"parent": "620600",
			"children": null
		}, {
			"id": "620624",
			"name": "其它区",
			"parent": "620600",
			"children": null
		}]
	}, {
		"id": "620700",
		"name": "张掖市",
		"parent": "620000",
		"children": [{
			"id": "620702",
			"name": "甘州区",
			"parent": "620700",
			"children": null
		}, {
			"id": "620721",
			"name": "肃南裕固族自治县",
			"parent": "620700",
			"children": null
		}, {
			"id": "620722",
			"name": "民乐县",
			"parent": "620700",
			"children": null
		}, {
			"id": "620723",
			"name": "临泽县",
			"parent": "620700",
			"children": null
		}, {
			"id": "620724",
			"name": "高台县",
			"parent": "620700",
			"children": null
		}, {
			"id": "620725",
			"name": "山丹县",
			"parent": "620700",
			"children": null
		}, {
			"id": "620726",
			"name": "其它区",
			"parent": "620700",
			"children": null
		}]
	}, {
		"id": "620800",
		"name": "平凉市",
		"parent": "620000",
		"children": [{
			"id": "620802",
			"name": "崆峒区",
			"parent": "620800",
			"children": null
		}, {
			"id": "620821",
			"name": "泾川县",
			"parent": "620800",
			"children": null
		}, {
			"id": "620822",
			"name": "灵台县",
			"parent": "620800",
			"children": null
		}, {
			"id": "620823",
			"name": "崇信县",
			"parent": "620800",
			"children": null
		}, {
			"id": "620824",
			"name": "华亭县",
			"parent": "620800",
			"children": null
		}, {
			"id": "620825",
			"name": "庄浪县",
			"parent": "620800",
			"children": null
		}, {
			"id": "620826",
			"name": "静宁县",
			"parent": "620800",
			"children": null
		}, {
			"id": "620827",
			"name": "其它区",
			"parent": "620800",
			"children": null
		}]
	}, {
		"id": "620900",
		"name": "酒泉市",
		"parent": "620000",
		"children": [{
			"id": "620902",
			"name": "肃州区",
			"parent": "620900",
			"children": null
		}, {
			"id": "620921",
			"name": "金塔县",
			"parent": "620900",
			"children": null
		}, {
			"id": "620922",
			"name": "瓜州县",
			"parent": "620900",
			"children": null
		}, {
			"id": "620923",
			"name": "肃北蒙古族自治县",
			"parent": "620900",
			"children": null
		}, {
			"id": "620924",
			"name": "阿克塞哈萨克族自治县",
			"parent": "620900",
			"children": null
		}, {
			"id": "620981",
			"name": "玉门市",
			"parent": "620900",
			"children": null
		}, {
			"id": "620982",
			"name": "敦煌市",
			"parent": "620900",
			"children": null
		}, {
			"id": "620983",
			"name": "其它区",
			"parent": "620900",
			"children": null
		}]
	}, {
		"id": "621000",
		"name": "庆阳市",
		"parent": "620000",
		"children": [{
			"id": "621002",
			"name": "西峰区",
			"parent": "621000",
			"children": null
		}, {
			"id": "621021",
			"name": "庆城县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621022",
			"name": "环县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621023",
			"name": "华池县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621024",
			"name": "合水县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621025",
			"name": "正宁县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621026",
			"name": "宁县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621027",
			"name": "镇原县",
			"parent": "621000",
			"children": null
		}, {
			"id": "621028",
			"name": "其它区",
			"parent": "621000",
			"children": null
		}]
	}, {
		"id": "621100",
		"name": "定西市",
		"parent": "620000",
		"children": [{
			"id": "621102",
			"name": "安定区",
			"parent": "621100",
			"children": null
		}, {
			"id": "621121",
			"name": "通渭县",
			"parent": "621100",
			"children": null
		}, {
			"id": "621122",
			"name": "陇西县",
			"parent": "621100",
			"children": null
		}, {
			"id": "621123",
			"name": "渭源县",
			"parent": "621100",
			"children": null
		}, {
			"id": "621124",
			"name": "临洮县",
			"parent": "621100",
			"children": null
		}, {
			"id": "621125",
			"name": "漳县",
			"parent": "621100",
			"children": null
		}, {
			"id": "621126",
			"name": "岷县",
			"parent": "621100",
			"children": null
		}, {
			"id": "621127",
			"name": "其它区",
			"parent": "621100",
			"children": null
		}]
	}, {
		"id": "621200",
		"name": "陇南市",
		"parent": "620000",
		"children": [{
			"id": "621202",
			"name": "武都区",
			"parent": "621200",
			"children": null
		}, {
			"id": "621221",
			"name": "成县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621222",
			"name": "文县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621223",
			"name": "宕昌县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621224",
			"name": "康县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621225",
			"name": "西和县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621226",
			"name": "礼县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621227",
			"name": "徽县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621228",
			"name": "两当县",
			"parent": "621200",
			"children": null
		}, {
			"id": "621229",
			"name": "其它区",
			"parent": "621200",
			"children": null
		}]
	}, {
		"id": "622900",
		"name": "临夏回族自治州",
		"parent": "620000",
		"children": [{
			"id": "622901",
			"name": "临夏市",
			"parent": "622900",
			"children": null
		}, {
			"id": "622921",
			"name": "临夏县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622922",
			"name": "康乐县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622923",
			"name": "永靖县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622924",
			"name": "广河县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622925",
			"name": "和政县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622926",
			"name": "东乡族自治县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622927",
			"name": "积石山保安族东乡族撒拉族自治县",
			"parent": "622900",
			"children": null
		}, {
			"id": "622928",
			"name": "其它区",
			"parent": "622900",
			"children": null
		}]
	}, {
		"id": "623000",
		"name": "甘南藏族自治州",
		"parent": "620000",
		"children": [{
			"id": "623001",
			"name": "合作市",
			"parent": "623000",
			"children": null
		}, {
			"id": "623021",
			"name": "临潭县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623022",
			"name": "卓尼县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623023",
			"name": "舟曲县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623024",
			"name": "迭部县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623025",
			"name": "玛曲县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623026",
			"name": "碌曲县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623027",
			"name": "夏河县",
			"parent": "623000",
			"children": null
		}, {
			"id": "623028",
			"name": "其它区",
			"parent": "623000",
			"children": null
		}]
	}]
}, {
	"id": "630000",
	"name": "青海省",
	"children": [{
		"id": "630100",
		"name": "西宁市",
		"parent": "630000",
		"children": [{
			"id": "630102",
			"name": "城东区",
			"parent": "630100",
			"children": null
		}, {
			"id": "630103",
			"name": "城中区",
			"parent": "630100",
			"children": null
		}, {
			"id": "630104",
			"name": "城西区",
			"parent": "630100",
			"children": null
		}, {
			"id": "630105",
			"name": "城北区",
			"parent": "630100",
			"children": null
		}, {
			"id": "630121",
			"name": "大通回族土族自治县",
			"parent": "630100",
			"children": null
		}, {
			"id": "630122",
			"name": "湟中县",
			"parent": "630100",
			"children": null
		}, {
			"id": "630123",
			"name": "湟源县",
			"parent": "630100",
			"children": null
		}, {
			"id": "630124",
			"name": "其它区",
			"parent": "630100",
			"children": null
		}]
	}, {
		"id": "632100",
		"name": "海东市",
		"parent": "630000",
		"children": [{
			"id": "632121",
			"name": "平安区",
			"parent": "632100",
			"children": null
		}, {
			"id": "632122",
			"name": "民和回族土族自治县",
			"parent": "632100",
			"children": null
		}, {
			"id": "632123",
			"name": "乐都区",
			"parent": "632100",
			"children": null
		}, {
			"id": "632126",
			"name": "互助土族自治县",
			"parent": "632100",
			"children": null
		}, {
			"id": "632127",
			"name": "化隆回族自治县",
			"parent": "632100",
			"children": null
		}, {
			"id": "632128",
			"name": "循化撒拉族自治县",
			"parent": "632100",
			"children": null
		}, {
			"id": "632129",
			"name": "其它区",
			"parent": "632100",
			"children": null
		}]
	}, {
		"id": "632200",
		"name": "海北藏族自治州",
		"parent": "630000",
		"children": [{
			"id": "632221",
			"name": "门源回族自治县",
			"parent": "632200",
			"children": null
		}, {
			"id": "632222",
			"name": "祁连县",
			"parent": "632200",
			"children": null
		}, {
			"id": "632223",
			"name": "海晏县",
			"parent": "632200",
			"children": null
		}, {
			"id": "632224",
			"name": "刚察县",
			"parent": "632200",
			"children": null
		}, {
			"id": "632225",
			"name": "其它区",
			"parent": "632200",
			"children": null
		}]
	}, {
		"id": "632300",
		"name": "黄南藏族自治州",
		"parent": "630000",
		"children": [{
			"id": "632321",
			"name": "同仁县",
			"parent": "632300",
			"children": null
		}, {
			"id": "632322",
			"name": "尖扎县",
			"parent": "632300",
			"children": null
		}, {
			"id": "632323",
			"name": "泽库县",
			"parent": "632300",
			"children": null
		}, {
			"id": "632324",
			"name": "河南蒙古族自治县",
			"parent": "632300",
			"children": null
		}, {
			"id": "632325",
			"name": "其它区",
			"parent": "632300",
			"children": null
		}]
	}, {
		"id": "632500",
		"name": "海南藏族自治州",
		"parent": "630000",
		"children": [{
			"id": "632521",
			"name": "共和县",
			"parent": "632500",
			"children": null
		}, {
			"id": "632522",
			"name": "同德县",
			"parent": "632500",
			"children": null
		}, {
			"id": "632523",
			"name": "贵德县",
			"parent": "632500",
			"children": null
		}, {
			"id": "632524",
			"name": "兴海县",
			"parent": "632500",
			"children": null
		}, {
			"id": "632525",
			"name": "贵南县",
			"parent": "632500",
			"children": null
		}, {
			"id": "632526",
			"name": "其它区",
			"parent": "632500",
			"children": null
		}]
	}, {
		"id": "632600",
		"name": "果洛藏族自治州",
		"parent": "630000",
		"children": [{
			"id": "632621",
			"name": "玛沁县",
			"parent": "632600",
			"children": null
		}, {
			"id": "632622",
			"name": "班玛县",
			"parent": "632600",
			"children": null
		}, {
			"id": "632623",
			"name": "甘德县",
			"parent": "632600",
			"children": null
		}, {
			"id": "632624",
			"name": "达日县",
			"parent": "632600",
			"children": null
		}, {
			"id": "632625",
			"name": "久治县",
			"parent": "632600",
			"children": null
		}, {
			"id": "632626",
			"name": "玛多县",
			"parent": "632600",
			"children": null
		}, {
			"id": "632627",
			"name": "其它区",
			"parent": "632600",
			"children": null
		}]
	}, {
		"id": "632700",
		"name": "玉树藏族自治州",
		"parent": "630000",
		"children": [{
			"id": "632721",
			"name": "玉树市",
			"parent": "632700",
			"children": null
		}, {
			"id": "632722",
			"name": "杂多县",
			"parent": "632700",
			"children": null
		}, {
			"id": "632723",
			"name": "称多县",
			"parent": "632700",
			"children": null
		}, {
			"id": "632724",
			"name": "治多县",
			"parent": "632700",
			"children": null
		}, {
			"id": "632725",
			"name": "囊谦县",
			"parent": "632700",
			"children": null
		}, {
			"id": "632726",
			"name": "曲麻莱县",
			"parent": "632700",
			"children": null
		}, {
			"id": "632727",
			"name": "其它区",
			"parent": "632700",
			"children": null
		}]
	}, {
		"id": "632800",
		"name": "海西蒙古族藏族自治州",
		"parent": "630000",
		"children": [{
			"id": "632801",
			"name": "格尔木市",
			"parent": "632800",
			"children": null
		}, {
			"id": "632802",
			"name": "德令哈市",
			"parent": "632800",
			"children": null
		}, {
			"id": "632821",
			"name": "乌兰县",
			"parent": "632800",
			"children": null
		}, {
			"id": "632822",
			"name": "都兰县",
			"parent": "632800",
			"children": null
		}, {
			"id": "632823",
			"name": "天峻县",
			"parent": "632800",
			"children": null
		}, {
			"id": "632824",
			"name": "其它区",
			"parent": "632800",
			"children": null
		}]
	}]
}, {
	"id": "640000",
	"name": "宁夏回族自治区",
	"children": [{
		"id": "640100",
		"name": "银川市",
		"parent": "640000",
		"children": [{
			"id": "640104",
			"name": "兴庆区",
			"parent": "640100",
			"children": null
		}, {
			"id": "640105",
			"name": "西夏区",
			"parent": "640100",
			"children": null
		}, {
			"id": "640106",
			"name": "金凤区",
			"parent": "640100",
			"children": null
		}, {
			"id": "640121",
			"name": "永宁县",
			"parent": "640100",
			"children": null
		}, {
			"id": "640122",
			"name": "贺兰县",
			"parent": "640100",
			"children": null
		}, {
			"id": "640181",
			"name": "灵武市",
			"parent": "640100",
			"children": null
		}, {
			"id": "640182",
			"name": "其它区",
			"parent": "640100",
			"children": null
		}]
	}, {
		"id": "640200",
		"name": "石嘴山市",
		"parent": "640000",
		"children": [{
			"id": "640202",
			"name": "大武口区",
			"parent": "640200",
			"children": null
		}, {
			"id": "640205",
			"name": "惠农区",
			"parent": "640200",
			"children": null
		}, {
			"id": "640221",
			"name": "平罗县",
			"parent": "640200",
			"children": null
		}, {
			"id": "640222",
			"name": "其它区",
			"parent": "640200",
			"children": null
		}]
	}, {
		"id": "640300",
		"name": "吴忠市",
		"parent": "640000",
		"children": [{
			"id": "640302",
			"name": "利通区",
			"parent": "640300",
			"children": null
		}, {
			"id": "640303",
			"name": "红寺堡区",
			"parent": "640300",
			"children": null
		}, {
			"id": "640323",
			"name": "盐池县",
			"parent": "640300",
			"children": null
		}, {
			"id": "640324",
			"name": "同心县",
			"parent": "640300",
			"children": null
		}, {
			"id": "640381",
			"name": "青铜峡市",
			"parent": "640300",
			"children": null
		}, {
			"id": "640382",
			"name": "其它区",
			"parent": "640300",
			"children": null
		}]
	}, {
		"id": "640400",
		"name": "固原市",
		"parent": "640000",
		"children": [{
			"id": "640402",
			"name": "原州区",
			"parent": "640400",
			"children": null
		}, {
			"id": "640422",
			"name": "西吉县",
			"parent": "640400",
			"children": null
		}, {
			"id": "640423",
			"name": "隆德县",
			"parent": "640400",
			"children": null
		}, {
			"id": "640424",
			"name": "泾源县",
			"parent": "640400",
			"children": null
		}, {
			"id": "640425",
			"name": "彭阳县",
			"parent": "640400",
			"children": null
		}, {
			"id": "640426",
			"name": "其它区",
			"parent": "640400",
			"children": null
		}]
	}, {
		"id": "640500",
		"name": "中卫市",
		"parent": "640000",
		"children": [{
			"id": "640502",
			"name": "沙坡头区",
			"parent": "640500",
			"children": null
		}, {
			"id": "640521",
			"name": "中宁县",
			"parent": "640500",
			"children": null
		}, {
			"id": "640522",
			"name": "海原县",
			"parent": "640500",
			"children": null
		}, {
			"id": "640523",
			"name": "其它区",
			"parent": "640500",
			"children": null
		}]
	}]
}, {
	"id": "650000",
	"name": "新疆维吾尔自治区",
	"children": [{
		"id": "650100",
		"name": "乌鲁木齐市",
		"parent": "650000",
		"children": [{
			"id": "650102",
			"name": "天山区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650103",
			"name": "沙依巴克区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650104",
			"name": "新市区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650105",
			"name": "水磨沟区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650106",
			"name": "头屯河区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650107",
			"name": "达坂城区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650108",
			"name": "东山区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650109",
			"name": "米东区",
			"parent": "650100",
			"children": null
		}, {
			"id": "650121",
			"name": "乌鲁木齐县",
			"parent": "650100",
			"children": null
		}, {
			"id": "650122",
			"name": "其它区",
			"parent": "650100",
			"children": null
		}]
	}, {
		"id": "650200",
		"name": "克拉玛依市",
		"parent": "650000",
		"children": [{
			"id": "650202",
			"name": "独山子区",
			"parent": "650200",
			"children": null
		}, {
			"id": "650203",
			"name": "克拉玛依区",
			"parent": "650200",
			"children": null
		}, {
			"id": "650204",
			"name": "白碱滩区",
			"parent": "650200",
			"children": null
		}, {
			"id": "650205",
			"name": "乌尔禾区",
			"parent": "650200",
			"children": null
		}, {
			"id": "650206",
			"name": "其它区",
			"parent": "650200",
			"children": null
		}]
	}, {
		"id": "652100",
		"name": "吐鲁番市",
		"parent": "650000",
		"children": [{
			"id": "652101",
			"name": "高昌区",
			"parent": "652100",
			"children": null
		}, {
			"id": "652122",
			"name": "鄯善县",
			"parent": "652100",
			"children": null
		}, {
			"id": "652123",
			"name": "托克逊县",
			"parent": "652100",
			"children": null
		}, {
			"id": "652124",
			"name": "其它区",
			"parent": "652100",
			"children": null
		}]
	}, {
		"id": "652200",
		"name": "哈密地区",
		"parent": "650000",
		"children": [{
			"id": "652201",
			"name": "哈密市",
			"parent": "652200",
			"children": null
		}, {
			"id": "652222",
			"name": "巴里坤哈萨克自治县",
			"parent": "652200",
			"children": null
		}, {
			"id": "652223",
			"name": "伊吾县",
			"parent": "652200",
			"children": null
		}, {
			"id": "652224",
			"name": "其它区",
			"parent": "652200",
			"children": null
		}]
	}, {
		"id": "652300",
		"name": "昌吉回族自治州",
		"parent": "650000",
		"children": [{
			"id": "652301",
			"name": "昌吉市",
			"parent": "652300",
			"children": null
		}, {
			"id": "652302",
			"name": "阜康市",
			"parent": "652300",
			"children": null
		}, {
			"id": "652303",
			"name": "米泉市",
			"parent": "652300",
			"children": null
		}, {
			"id": "652323",
			"name": "呼图壁县",
			"parent": "652300",
			"children": null
		}, {
			"id": "652324",
			"name": "玛纳斯县",
			"parent": "652300",
			"children": null
		}, {
			"id": "652325",
			"name": "奇台县",
			"parent": "652300",
			"children": null
		}, {
			"id": "652327",
			"name": "吉木萨尔县",
			"parent": "652300",
			"children": null
		}, {
			"id": "652328",
			"name": "木垒哈萨克自治县",
			"parent": "652300",
			"children": null
		}, {
			"id": "652329",
			"name": "其它区",
			"parent": "652300",
			"children": null
		}]
	}, {
		"id": "652700",
		"name": "博尔塔拉蒙古自治州",
		"parent": "650000",
		"children": [{
			"id": "652701",
			"name": "博乐市",
			"parent": "652700",
			"children": null
		}, {
			"id": "652702",
			"name": "阿拉山口市",
			"parent": "652700",
			"children": null
		}, {
			"id": "652722",
			"name": "精河县",
			"parent": "652700",
			"children": null
		}, {
			"id": "652723",
			"name": "温泉县",
			"parent": "652700",
			"children": null
		}, {
			"id": "652724",
			"name": "其它区",
			"parent": "652700",
			"children": null
		}]
	}, {
		"id": "652800",
		"name": "巴音郭楞蒙古自治州",
		"parent": "650000",
		"children": [{
			"id": "652801",
			"name": "库尔勒市",
			"parent": "652800",
			"children": null
		}, {
			"id": "652822",
			"name": "轮台县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652823",
			"name": "尉犁县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652824",
			"name": "若羌县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652825",
			"name": "且末县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652826",
			"name": "焉耆回族自治县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652827",
			"name": "和静县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652828",
			"name": "和硕县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652829",
			"name": "博湖县",
			"parent": "652800",
			"children": null
		}, {
			"id": "652830",
			"name": "其它区",
			"parent": "652800",
			"children": null
		}]
	}, {
		"id": "652900",
		"name": "阿克苏地区",
		"parent": "650000",
		"children": [{
			"id": "652901",
			"name": "阿克苏市",
			"parent": "652900",
			"children": null
		}, {
			"id": "652922",
			"name": "温宿县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652923",
			"name": "库车县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652924",
			"name": "沙雅县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652925",
			"name": "新和县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652926",
			"name": "拜城县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652927",
			"name": "乌什县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652928",
			"name": "阿瓦提县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652929",
			"name": "柯坪县",
			"parent": "652900",
			"children": null
		}, {
			"id": "652930",
			"name": "其它区",
			"parent": "652900",
			"children": null
		}]
	}, {
		"id": "653000",
		"name": "克孜勒苏柯尔克孜自治州",
		"parent": "650000",
		"children": [{
			"id": "653001",
			"name": "阿图什市",
			"parent": "653000",
			"children": null
		}, {
			"id": "653022",
			"name": "阿克陶县",
			"parent": "653000",
			"children": null
		}, {
			"id": "653023",
			"name": "阿合奇县",
			"parent": "653000",
			"children": null
		}, {
			"id": "653024",
			"name": "乌恰县",
			"parent": "653000",
			"children": null
		}, {
			"id": "653025",
			"name": "其它区",
			"parent": "653000",
			"children": null
		}]
	}, {
		"id": "653100",
		"name": "喀什地区",
		"parent": "650000",
		"children": [{
			"id": "653101",
			"name": "喀什市",
			"parent": "653100",
			"children": null
		}, {
			"id": "653121",
			"name": "疏附县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653122",
			"name": "疏勒县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653123",
			"name": "英吉沙县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653124",
			"name": "泽普县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653125",
			"name": "莎车县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653126",
			"name": "叶城县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653127",
			"name": "麦盖提县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653128",
			"name": "岳普湖县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653129",
			"name": "伽师县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653130",
			"name": "巴楚县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653131",
			"name": "塔什库尔干塔吉克自治县",
			"parent": "653100",
			"children": null
		}, {
			"id": "653132",
			"name": "其它区",
			"parent": "653100",
			"children": null
		}]
	}, {
		"id": "653200",
		"name": "和田地区",
		"parent": "650000",
		"children": [{
			"id": "653201",
			"name": "和田市",
			"parent": "653200",
			"children": null
		}, {
			"id": "653221",
			"name": "和田县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653222",
			"name": "墨玉县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653223",
			"name": "皮山县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653224",
			"name": "洛浦县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653225",
			"name": "策勒县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653226",
			"name": "于田县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653227",
			"name": "民丰县",
			"parent": "653200",
			"children": null
		}, {
			"id": "653228",
			"name": "其它区",
			"parent": "653200",
			"children": null
		}]
	}, {
		"id": "654000",
		"name": "伊犁哈萨克自治州",
		"parent": "650000",
		"children": [{
			"id": "654002",
			"name": "伊宁市",
			"parent": "654000",
			"children": null
		}, {
			"id": "654003",
			"name": "奎屯市",
			"parent": "654000",
			"children": null
		}, {
			"id": "654004",
			"name": "霍尔果斯市",
			"parent": "654000",
			"children": null
		}, {
			"id": "654021",
			"name": "伊宁县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654022",
			"name": "察布查尔锡伯自治县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654023",
			"name": "霍城县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654024",
			"name": "巩留县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654025",
			"name": "新源县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654026",
			"name": "昭苏县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654027",
			"name": "特克斯县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654028",
			"name": "尼勒克县",
			"parent": "654000",
			"children": null
		}, {
			"id": "654029",
			"name": "其它区",
			"parent": "654000",
			"children": null
		}]
	}, {
		"id": "654200",
		"name": "塔城地区",
		"parent": "650000",
		"children": [{
			"id": "654201",
			"name": "塔城市",
			"parent": "654200",
			"children": null
		}, {
			"id": "654202",
			"name": "乌苏市",
			"parent": "654200",
			"children": null
		}, {
			"id": "654221",
			"name": "额敏县",
			"parent": "654200",
			"children": null
		}, {
			"id": "654223",
			"name": "沙湾县",
			"parent": "654200",
			"children": null
		}, {
			"id": "654224",
			"name": "托里县",
			"parent": "654200",
			"children": null
		}, {
			"id": "654225",
			"name": "裕民县",
			"parent": "654200",
			"children": null
		}, {
			"id": "654226",
			"name": "和布克赛尔蒙古自治县",
			"parent": "654200",
			"children": null
		}, {
			"id": "654227",
			"name": "其它区",
			"parent": "654200",
			"children": null
		}]
	}, {
		"id": "654300",
		"name": "阿勒泰地区",
		"parent": "650000",
		"children": [{
			"id": "654301",
			"name": "阿勒泰市",
			"parent": "654300",
			"children": null
		}, {
			"id": "654321",
			"name": "布尔津县",
			"parent": "654300",
			"children": null
		}, {
			"id": "654322",
			"name": "富蕴县",
			"parent": "654300",
			"children": null
		}, {
			"id": "654323",
			"name": "福海县",
			"parent": "654300",
			"children": null
		}, {
			"id": "654324",
			"name": "哈巴河县",
			"parent": "654300",
			"children": null
		}, {
			"id": "654325",
			"name": "青河县",
			"parent": "654300",
			"children": null
		}, {
			"id": "654326",
			"name": "吉木乃县",
			"parent": "654300",
			"children": null
		}, {
			"id": "654327",
			"name": "其它区",
			"parent": "654300",
			"children": null
		}]
	}, {
		"id": "659000",
		"name": "可克达拉市",
		"parent": "650000",
		"children": [{
			"id": "659007",
			"name": "双河市",
			"parent": "659000",
			"children": null
		}, {
			"id": "659008",
			"name": "可克达拉市",
			"parent": "659000",
			"children": null
		}]
	}, {
		"id": "659001",
		"name": "石河子市",
		"parent": "650000",
		"children": null
	}, {
		"id": "659002",
		"name": "阿拉尔市",
		"parent": "650000",
		"children": null
	}, {
		"id": "659003",
		"name": "图木舒克市",
		"parent": "650000",
		"children": null
	}, {
		"id": "659004",
		"name": "五家渠市",
		"parent": "650000",
		"children": null
	}]
}]
